<template>
  <div class="empty">
    <div class="container">
      <div class="row">
        <div class="col-lg-11 col-sm-11 col-10">
          <!-- <nav aria-label="breadcrumb">
            <ol class="breadcrumb px-0">
              <li class="breadcrumb-item" style="cursor: pointer">
                <a v-on:click="$router.push('/executive')">Home</a>
              </li>
              <li
                style="cursor: pointer"
                class="breadcrumb-item"
                aria-current="page"
                v-on:click="$router.push('/executive')"
              >
                Search Executive
              </li>
              <li
                style="cursor: pointer"
                class="breadcrumb-item active"
                aria-current="page"
              >
                {{ detail.executive_name }}
              </li>
            </ol>
          </nav> -->
        </div>
        <div class="col-lg-1 col-sm-1 col-2 text-right">
          <span
            ><input
              type="checkbox"
              v-on:click="addToFavorite(detail)"
              :checked="detail.is_fav == 1"
          /></span>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-sm-4">
          <div class="row h-100">
            <div class="col-lg-3 col-sm-3 col-2 pr-2">
              <a>
                <img
                  v-if="detail.executive_logo"
                  :src="
                    detail.executive_logo
                      ? detail.executive_logo
                      : '/img/user-pic.png'
                  "
                  alt=""
                  class="round2"
                />
                <img
                  v-if="detail.gender == 'male' && !detail.executive_logo"
                  :src="
                    detail.executive_logo
                      ? detail.executive_logo
                      : '/img/male.png'
                  "
                  alt=""
                  class="round2"
                />
                <img
                  v-if="detail.gender == 'female' && !detail.executive_logo"
                  :src="
                    detail.executive_logo
                      ? detail.executive_logo
                      : '/img/female.png'
                  "
                  alt=""
                  class="round2"
                />
              </a>
            </div>
            <div class="col-lg-9 col-sm-9 col-9 px-2">
              <div class="client-name">
                {{ detail.executive_name }}
              </div>
              <div
                style="
                  cursor: pointer;
                  font-weight: bold;
                  font-size: 10px;
                  width: 281px;
                "
                v-on:click="
                  $router.push('/company-detail/' + detail.new_company_id)
                "
              >
                <img
                  :src="
                    detail.new_company
                      ? detail.new_company.company_logo
                      : '/img/user-pic.png'
                  "
                  alt=""
                  style="width: 20px; height: 20px; object-fit: contain"
                />&nbsp;
                {{ detail.new_company ? detail.new_company.company_name : "-" }}
              </div>
              <div class="designation" style="display: block !important">
                {{
                  $helperService.isStringOnlyAlphabet(detail.new_job_title_id)
                    ? detail.new_job_title_id
                    : detail.new_job_title
                    ? detail.new_job_title.title
                    : ""
                }}
              </div>
            </div>
            <!-- :href="
                  'https://api.whatsapp.com/send?phone=' +
                  (detail.country != null && detail.country.phone_code
                    ? detail.country.phone_code + detail.executive_phone
                    : '')
                " -->
            <div class="mt-3" style="margin-left: 66px">
              <a
                class="mr-1 ml-1"
                style="margin-left: -12px"
                :title="detail.country ? detail.country.country_name : ''"
                ><country-flag
                  size="normal"
                  :country="detail.country ? detail.country.country_code : '-'"
              /></a>
              <a
                target="__blank"
                v-show="detail.executive_phone"
                :title="detail.dial_code + detail.executive_phone"
                style="cursor: pointer"
                class="mr-1"
                ><img
                  src="/img/icons/phone.png"
                  height="25"
                  width="25"
                  alt="Linkedin"
              /></a>
              <!-- :href="'mailto:' + detail.executive_email_work" -->

              <a
                v-show="detail.executive_email_work"
                :title="detail.executive_email_work"
                v-on:click="showEmailPopup(detail)"
                class="mr-1 ml-1"
                ><img src="/img/icons/mail.png" height="25" width="25"
              /></a>

              <a
                v-show="detail.linkedin_url"
                v-on:click="openLink(detail.linkedin_url)"
                :title="detail.linkedin_url"
                style="cursor: pointer"
                ><img
                  style="border-radius: 15px"
                  src="/img/linkedin-color.png"
                  height="25"
                  width="25"
                  alt="Linkedin"
              /></a>
              <a
                v-show="detail.twitter_url"
                v-on:click="openLink(detail.twitter_url)"
                style="cursor: pointer"
                :title="detail.twitter_url"
                class="mr-1 ml-1"
                ><img
                  src="/img/twitter.png"
                  height="25"
                  width="25"
                  alt="Twitter"
              /></a>
              <a
                v-show="detail.facebook_url"
                v-on:click="openLink(detail.facebook_url)"
                :title="detail.facebook_url"
                style="cursor: pointer"
                class="mr-1 ml-1"
                ><img
                  src="/img/facebook.png"
                  height="25"
                  width="25"
                  alt="Linkedin"
              /></a>
            </div>
          </div>
        </div>
        <div
          class="col-lg-6 col-sm-4 text-center my-auto"
          v-if="detail.report_to"
        >
          <span class="reports-to">Reports To</span>
        </div>
        <div class="col-lg-3 col-sm-4" v-if="detail.report_to">
          <div class="row">
            <div
              class="col-lg-3 col-sm-3 col-2 pr-2"
              :style="detail.report_to_name_id != 0 ? 'cursor:pointer' : ''"
              v-on:click="
                detail.report_to_name_id != 0
                  ? $router.push(
                      '/executive-detail/' + detail.report_to_name_id
                    )
                  : ''
              "
            >
              <img
                v-if="
                  detail.report_to &&
                  detail.report_to.executive_logo &&
                  detail.report_to.executive_logo != ''
                "
                :src="
                  detail.report_to
                    ? detail.report_to.executive_logo
                    : '/img/user-pic.png'
                "
                alt=""
                class="round2"
              />
              <img
                v-if="
                  detail.report_to &&
                  detail.report_to.gender == 'male' &&
                  !detail.report_to.executive_logo
                "
                src="/img/male.png"
                alt=""
                class="round2"
              />
              <img
                v-if="
                  detail.report_to &&
                  detail.report_to.gender == 'female' &&
                  !detail.report_to.executive_logo
                "
                src="/img/female.png"
                alt=""
                class="round2"
              />
            </div>
            <div class="col-lg-9 col-sm-9 col-9 px-2">
              <div
                class="client-name"
                :style="detail.report_to_name_id != 0 ? 'cursor:pointer' : ''"
                v-on:click="
                  detail.report_to_name_id != 0
                    ? $router.push(
                        '/executive-detail/' + detail.report_to_name_id
                      )
                    : ''
                "
              >
                {{ detail.report_to ? detail.report_to.executive_name : "-" }}
              </div>
              <div
                style="
                  cursor: pointer;
                  font-weight: bold;
                  font-size: 10px;
                  width: 281px;
                "
                v-on:click="
                  $router.push('/company-detail/' + detail.new_company_id)
                "
              >
                <img
                  :src="
                    detail.report_to &&
                    detail.report_to.new_company &&
                    detail.report_to.new_company.company_logo
                      ? detail.report_to.new_company.company_logo
                      : '/img/user-pic.png'
                  "
                  alt=""
                  style="width: 20px; height: 20px; object-fit: contain"
                />
                {{
                  detail.report_to &&
                  detail.report_to.new_company &&
                  detail.report_to.new_company.company_name
                    ? detail.report_to.new_company.company_name
                    : "-"
                }}
              </div>
              <div class="designation" style="display: block !important">
                {{
                  detail.report_to && detail.report_to.new_job_title_id
                    ? $helperService.isStringOnlyAlphabet(
                        detail.report_to.new_job_title_id
                      )
                      ? detail.report_to.new_job_title_id
                      : detail.report_to.new_job_title.title
                    : ""
                }}
              </div>
            </div>
            <div class="mt-3" style="margin-left: 66px">
              <a
                class="mr-1 ml-1"
                style="margin-left: -12px"
                :title="
                  detail.report_to.country
                    ? detail.report_to.country.country_name
                    : ''
                "
              >
                <country-flag
                  size="normal"
                  :country="
                    detail.report_to && detail.report_to.country
                      ? detail.report_to.country.country_code
                      : '-'
                  "
              /></a>
              <!-- :href="
                  'https://api.whatsapp.com/send?phone=' +
                  (detail.report_to &&
                  detail.report_to.country &&
                  detail.report_to.country.phone_code
                    ? detail.report_to.country.phone_code +
                      detail.report_to.executive_phone
                    : '')
                " -->
              <a
                target="__blank"
                v-show="detail.report_to && detail.report_to.executive_phone"
                :title="
                  detail.report_to.dial_code + detail.report_to.executive_phone
                "
                style="cursor: pointer"
                class="mr-1"
                ><img
                  src="/img/icons/phone.png"
                  height="25"
                  width="25"
                  alt="Linkedin"
              /></a>
              <a
                v-show="
                  detail.report_to && detail.report_to.executive_email_work
                "
                class="mr-1"
                :href="'mailto:' + detail.report_to.executive_email_work"
                :title="detail.report_to.executive_email_work"
                style="cursor: pointer"
                ><img src="/img/icons/mail.png" height="25" width="25"
              /></a>
              <a
                class="mr-1 ml-1"
                v-show="detail.report_to && detail.report_to.linkedin_url"
                style="cursor: pointer"
                :title="detail.report_to ? detail.report_to.linkedin_url : '-'"
                v-on:click="
                  openLink(
                    detail.report_to ? detail.report_to.linkedin_url : '-'
                  )
                "
                ><img
                  src="/img/linkedin-color.png"
                  style="border-radius: 15px"
                  height="25"
                  width="25"
              /></a>
              <a
                v-show="detail.report_to && detail.report_to.twitter_url"
                style="cursor: pointer"
                :title="detail.report_to ? detail.report_to.twitter_url : '-'"
                v-on:click="
                  openLink(
                    detail.report_to ? detail.report_to.twitter_url : '-'
                  )
                "
                class="mr-1 ml-1"
                ><img src="/img/twitter.png" height="25" width="25"
              /></a>

              <a
                v-show="detail.report_to && detail.report_to.facebook_url"
                v-on:click="openLink(detail.facebook_url)"
                :title="detail.report_to ? detail.report_to.facebook_url : '-'"
                style="cursor: pointer"
                class="mr-1 ml-1"
                ><img
                  src="/img/facebook.png"
                  height="25"
                  width="25"
                  alt="Linkedin"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="full-rectangle">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-sm-8">
            <div class="gery-box" v-if="detail.executive_biography">
              <div class="title clearfix pb-2">Biography</div>
              <p
                class="calibri"
                v-if="detail.executive_biography.length <= 200"
              >
                <!-- {{ detail.executive_biography.replaceAll('/n','<br />') }} -->
                <span
                  v-html="detail.executive_biography.replaceAll('\n', '<br>')"
                ></span>
              </p>
              <br />
              <p class="calibri" v-if="detail.executive_biography.length > 200">
                <!-- {{ formattedBody }} -->
                <span v-html="formattedBody"></span>
                <br />
                <a
                  class="link cursor-pointer"
                  v-on:click="showingFullText = !showingFullText"
                >
                  {{ showingFullText ? "Read less" : "Read more" }}
                </a>
              </p>
            </div>
            <div
              class="gery-box"
              v-if="detail.education && detail.education.education_name"
            >
              <div class="title clearfix pb-2">Education</div>
              <div class="row pb-2">
                <div class="col-lg-4 col-sm-4 my-auto sub-title">Degree</div>
                <div class="col-lg-4 col-sm-4 my-auto sub-title">
                  University
                </div>
                <div class="col-lg-4 col-sm-4 my-auto sub-title">
                  Specialzation In
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-lg-4 col-sm-2 my-auto">
                  {{ detail.education ? detail.education.education_name : "" }}
                </div>
                <div class="col-lg-4 col-sm-4 my-auto">
                  <img
                    style="cursor: pointer"
                    :title="
                      detail.university && detail.university.name
                        ? detail.university.name
                        : ''
                    "
                    :src="
                      detail.university && detail.university.university_logo
                        ? detail.university.university_logo
                        : '/img/university.jpg'
                    "
                    alt=""
                    :height="
                      detail.university && detail.university.university_logo
                        ? '60'
                        : '60'
                    "
                    :width="
                      detail.university && detail.university.university_logo
                        ? '60'
                        : '60'
                    "
                    class="img-fluid"
                  />
                  <!-- {{ detail.university ? detail.university.name : "" }} -->
                </div>
                <div class="col-lg-4 col-sm-4 my-auto">
                  {{ detail.specialzation }}
                </div>
              </div>
            </div>
            <div
              class="gery-box mt-3"
              v-show="pastCompanyList && pastCompanyList.length > 0"
            >
              <div class="title clearfix pb-2">Past Companies</div>

              <div class="row">
                <div
                  style="width: 70px; margin-left: 4px"
                  v-for="(data, index) in pastCompanyList"
                  :key="index"
                >
                  <a v-on:click="$router.push('/company-detail/' + data.id)"
                    ><img
                      :title="data.company_name ? data.company_name : ''"
                      :src="
                        data.company_logo
                          ? data.company_logo
                          : '/img/user-pic.png'
                      "
                      height="100"
                      width="100"
                      class="md-size"
                      style="border-radius: 30px"
                  /></a>
                </div>
              </div>
            </div>
            <div
              class="gery-box mt-3"
              v-show="reportingList && reportingList.length > 0"
            >
              <div class="title clearfix pb-2">
                People Reporting To
                {{
                  detail.executive_name
                    ? $helperService.getFirstName(detail.executive_name)
                    : ""
                }}
              </div>

              <div class="row">
                <div
                  style="width: 70px; margin-left: 4px"
                  v-for="(data, index) in reportingList"
                  :key="index"
                >
                  <!-- <a v-on:click="$router.push('/executive-detail/' + data.id)"
                    ><img
                      :title="data.executive_name ? data.executive_name : ''"
                      :src="
                        data.executive_logo
                          ? data.executive_logo
                          : '/img/user-pic.png'
                      "
                      height="100"
                      width="100"
                      class="md-size"
                      style="border-radius: 30px"
                  /></a> -->

                  <a v-on:click="$router.push('/executive-detail/' + data.id)">
                    <img
                      v-if="data.executive_logo"
                      :title="data.executive_name ? data.executive_name : ''"
                      :src="
                        data.executive_logo
                          ? data.executive_logo
                          : '/img/user-pic.png'
                      "
                      alt=""
                      height="100"
                      width="100"
                      class="md-size"
                      style="border-radius: 30px"
                    />
                    <img
                      v-if="data.gender == 'male' && !data.executive_logo"
                      :title="data.executive_name ? data.executive_name : ''"
                      :src="
                        data.executive_logo
                          ? data.executive_logo
                          : '/img/male.png'
                      "
                      alt=""
                      height="100"
                      width="100"
                      style="border-radius: 30px"
                      class="md-size"
                    />
                    <img
                      v-if="data.gender == 'female' && !data.executive_logo"
                      :title="data.executive_name ? data.executive_name : ''"
                      :src="
                        data.executive_logo
                          ? data.executive_logo
                          : '/img/female.png'
                      "
                      alt=""
                      height="100"
                      width="100"
                      class="md-size"
                      style="border-radius: 30px"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div
              class="gery-box"
              v-show="
                detail.interting_links && detail.interting_links.length > 0
              "
            >
              <div class="title clearfix pb-2">Interesting Articles</div>
              <!-- style="overflow-y: scroll; height: auto" -->
              <div>
                <div
                  class="row pb-2"
                  v-for="(data, index) in detail.interting_links"
                  :key="index"
                >
                  <div
                    v-if="data.is_company_logo == 0"
                    class="col-lg-3 col-sm-3 my-auto"
                    style="cursor: pointer"
                    v-on:click="openLink(data.url)"
                  >
                    <img
                      :src="
                        data.publication && data.publication.publication_logo
                          ? data.publication.publication_logo
                          : '/img/new.png'
                      "
                      alt=""
                      height="100"
                      width="100"
                      class="md-size"
                    />
                  </div>
                  <div
                    v-if="data.is_company_logo == 1"
                    class="col-lg-3 col-sm-3 my-auto"
                    style="cursor: pointer"
                    v-on:click="openLink(data.url)"
                  >
                    <img
                      :src="
                        detail.new_company
                          ? detail.new_company.company_logo
                          : '/img/user-pic.png'
                      "
                      alt=""
                      height="100"
                      width="100"
                      class="md-size"
                    />
                  </div>
                  <div class="col-lg-9 col-sm-9 my-auto calibri">
                    {{ data.headline }}
                  </div>
                </div>
              </div>
            </div>
            <div class="gery-box" v-show="newsList && newsList.length > 0">
              <div class="title clearfix pb-2">Featured News</div>
              <div v-for="(data, index) in newsList" :key="index">
                <div class="row pb-2" v-if="index <= 4">
                  <div
                    v-if="data.is_company_logo == 1"
                    class="col-lg-3 col-sm-3 my-auto"
                    style="cursor: pointer"
                    v-on:click="openLink(data.news_link)"
                  >
                    <img
                      :title="
                        data.company && data.company.company_name
                          ? data.company.company_name
                          : ''
                      "
                      :src="
                        data.company && data.company.company_logo
                          ? data.company.company_logo
                          : '/img/new.png'
                      "
                      alt=""
                      height="100"
                      width="100"
                      class="md-size"
                    />
                  </div>
                  <div
                    v-if="data.is_company_logo == 0"
                    class="col-lg-3 col-sm-3 my-auto"
                    style="cursor: pointer"
                    v-on:click="openLink(data.news_link)"
                  >
                    <img
                      :title="
                        data.publication && data.publication.name
                          ? data.publication.name
                          : ''
                      "
                      :src="
                        data.publication && data.publication.publication_logo
                          ? data.publication.publication_logo
                          : ''
                      "
                      alt=""
                      height="100"
                      width="100"
                      class="md-size"
                    />
                  </div>
                  <div class="col-lg-9 col-sm-9 my-auto calibri">
                    {{ data.news_title }}
                  </div>
                </div>
              </div>
              <div
                class="col-12 title clearfix pb-2"
                style="display: flex; justify-content: end"
              >
                <a
                  v-if="newsList.length > 5"
                  style="cursor: pointer"
                  class="pull-right font16"
                  v-on:click="showMoreNews()"
                  >View More</a
                >
              </div>
            </div>
            <div
              class="gery-box mt-5"
              v-show="
                detail.deals_transaction && detail.deals_transaction.length > 0
              "
            >
              <div class="title clearfix pb-2">Deals & Acquisitions</div>
              <div
                v-for="(data, index) in detail.deals_transaction"
                :key="index"
              >
                <div class="row pb-2" v-if="index <= 4">
                  <div
                    v-if="data.is_company_logo == 0"
                    v-on:click="openLink(data.news_url)"
                    style="cursor: pointer"
                    class="col-lg-3 col-sm-3 my-auto"
                  >
                    <img
                      :title="
                        data.publication && data.publication.name
                          ? data.publication.name
                          : ''
                      "
                      :src="
                        data.publication && data.publication.publication_logo
                          ? data.publication.publication_logo
                          : ''
                      "
                      alt=""
                      height="100"
                      width="100"
                      class="md-size"
                    />
                  </div>
                  <div
                    v-if="data.is_company_logo == 1"
                    v-on:click="openLink(data.news_url)"
                    style="cursor: pointer"
                    class="col-lg-3 col-sm-3 my-auto"
                  >
                    <img
                      :title="
                        data.primary_company &&
                        data.primary_company.company_name
                          ? data.primary_company.company_name
                          : ''
                      "
                      :src="
                        data.primary_company &&
                        data.primary_company.company_logo
                          ? data.primary_company.company_logo
                          : '/img/user-pic.png'
                      "
                      alt=""
                      height="100"
                      width="100"
                      class="md-size"
                    />
                  </div>
                  <div class="col-lg-9 col-sm-9 my-auto calibri">
                    {{ data.headline }}
                  </div>
                  <hr />
                </div>
              </div>
              <div
                class="col-12 title clearfix pb-2"
                style="display: flex; justify-content: end"
              >
                <a
                  v-if="detail.deals_transaction.length > 5"
                  style="cursor: pointer"
                  class="pull-right font16"
                  v-on:click="showDealsAndTransaction()"
                  >View More</a
                >
              </div>
            </div>
            <div
              class="gery-box"
              v-show="
                detail.executive_clusters &&
                detail.executive_clusters.length > 0
              "
            >
              <div class="title clearfix pb-2">Awards & Recognition ></div>
              <div
                v-for="(data, index) in detail.executive_clusters"
                :key="index"
              >
                <div class="row pb-2" v-if="index <= 4">
                  <div
                    class="col-lg-3 col-sm-3 my-auto"
                    style="cursor: pointer"
                    v-on:click="openLink(data.source_url)"
                  >
                    <img
                      :title="
                        data.publication && data.publication.name
                          ? data.publication.name
                          : ''
                      "
                      :src="
                        data.publication && data.publication.publication_logo
                          ? data.publication.publication_logo
                          : '/img/new.png'
                      "
                      alt=""
                      height="100"
                      width="100"
                      class="md-size"
                    />
                  </div>
                  <div class="col-lg-9 col-sm-9 my-auto calibri">
                    {{ data.name }}
                  </div>
                </div>
              </div>
              <div
                class="col-12 title clearfix pb-2"
                style="display: flex; justify-content: end"
              >
                <a
                  v-if="detail.executive_clusters.length > 5"
                  style="cursor: pointer"
                  class="pull-right font16"
                  v-on:click="showClusterList()"
                  >View More</a
                >
              </div>
            </div>

            <div
              class="gery-box"
              v-show="
                detail.conferences_event && detail.conferences_event.length > 0
              "
            >
              <div class="title clearfix pb-2">Conferences & Event</div>
              <div
                class="row pb-2"
                v-for="(data, index) in detail.conferences_event"
                :key="index"
              >
                <div
                  v-if="data.is_company_logo == 0"
                  class="col-lg-3 col-sm-3 my-auto"
                  style="cursor: pointer"
                  v-on:click="openLink(data.url)"
                >
                  <img
                    :title="
                      data.publication && data.publication.name
                        ? data.publication.name
                        : ''
                    "
                    :src="
                      data.publication && data.publication.publication_logo
                        ? data.publication.publication_logo
                        : '/img/new.png'
                    "
                    alt=""
                    height="100"
                    width="100"
                    class="md-size"
                  />
                </div>
                <div
                  v-if="data.is_company_logo == 1"
                  class="col-lg-3 col-sm-3 my-auto"
                  style="cursor: pointer"
                  v-on:click="openLink(data.url)"
                >
                  <img
                    :src="
                      detail.new_company
                        ? detail.new_company.company_logo
                        : '/img/user-pic.png'
                    "
                    alt=""
                    height="100"
                    width="100"
                    class="md-size"
                  />
                </div>
                <div class="col-lg-9 col-sm-9 my-auto calibri">
                  {{ data.headline }}
                </div>
              </div>
            </div>
            <div
              class="gery-box"
              style="background: #fff; border: 0"
              v-show="detail.videos && detail.videos.length > 0"
            >
              <div class="row gery-box">
                <div class="col-12 title clearfix">Videos</div>
                <div class="row">
                  <div
                    style="width: 200px"
                    class="mt-3 mr-2 ml-2"
                    v-for="(data, index) in youtubeVideo"
                    :key="index"
                  >
                    <YouTube
                      v-if="data.video_url.includes('youtube')"
                      :id="'youtube_' + index"
                      width="200"
                      height="112"
                      :src="data.video_url"
                    />
                    <video v-else controls>
                      <source
                        :src="data.video_url"
                        type="video/mp4"
                        width="200"
                        height="100"
                      />
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-4">
            <div class="gery-box mb-4" style="width: 408px">
              <div class="row">
                <div
                  v-if="detail.age_range"
                  class="col-lg-4 col-4 pb-3 calibri-bold"
                >
                  <b>Age Range</b>
                </div>
                <div
                  v-if="detail.age_range"
                  class="col-lg-6 col-6 pb-3 calibri"
                  style="font-size: 13px !important"
                >
                  {{ detail.age_range ? detail.age_range.age_range_name : "" }}
                </div>
                <div
                  v-if="detail.gender"
                  class="col-lg-4 col-4 pb-3 calibri-bold"
                >
                  <b>Gender</b>
                </div>
                <div v-if="detail.gender" class="col-lg-6 col-6 pb-3 calibri">
                  {{
                    detail.gender
                      ? detail.gender.charAt(0).toUpperCase() +
                        detail.gender.slice(1)
                      : ""
                  }}
                </div>
                <div
                  v-if="detail.new_location"
                  class="col-lg-4 col-4 pb-3 calibri-bold"
                >
                  <b>Location</b>
                </div>
                <div
                  v-if="detail.new_location"
                  class="col-lg-6 col-6 pb-3 calibri"
                >
                  {{
                    detail.new_location
                      ? detail.new_location.city_name + ", "
                      : ""
                  }}
                  {{ detail.country ? detail.country.country_name : "-" }}
                </div>

                <div
                  v-if="detail.new_industry"
                  class="col-lg-4 col-4 pb-3 calibri-bold"
                >
                  <b>Industry</b>
                </div>
                <div
                  v-if="detail.new_industry"
                  class="col-lg-6 col-6 pb-3 calibri"
                >
                  {{
                    detail.new_industry
                      ? detail.new_industry.industry_name
                      : "-"
                  }}
                </div>

                <div
                  v-if="detail.new_function"
                  class="col-lg-4 col-4 pb-3 calibri-bold"
                >
                  <b>Function</b>
                </div>
                <div
                  v-if="detail.new_function"
                  class="col-lg-6 col-6 pb-3 calibri"
                >
                  {{
                    detail.new_function
                      ? detail.new_function.job_function_name
                      : "-"
                  }}
                </div>
                <div
                  v-if="detail.curr_month || detail.curr_year"
                  class="col-lg-4 col-4 pb-3 calibri-bold"
                >
                  <b>Role Since </b>
                </div>
                <div
                  v-if="detail.curr_month || detail.curr_year"
                  class="col-lg-6 col-6 pb-3 calibri"
                >
                  {{ detail.curr_month ? detail.curr_month : "" }}
                  {{ detail.curr_year ? detail.curr_year : "" }}
                </div>
                <!-- <div v-if="!detail.curr_month && !detail.curr_year">-</div> -->
                <div
                  v-if="detail.current_responsibility"
                  class="col-lg-12 col-12 pb-3 calibri-bold"
                >
                  <b>Current Responsibility : </b>
                </div>
                <div
                  v-if="detail.current_responsibility"
                  class="col-lg-12 col-12 pb-3 calibri"
                  style="font-size: 13px !important"
                >
                  {{
                    detail.current_responsibility
                      ? detail.current_responsibility
                      : "-"
                  }}
                </div>

                <!-- <div class="col-lg-6 col-6 pb-3"><b>Email</b></div>
                <div class="col-lg-6 col-6 pb-3">
                  {{ detail.executive_email_personal }}
                </div>
                <div class="col-lg-6 col-6"><b>Phone</b></div>
                <div class="col-lg-6 col-6">{{ detail.executive_phone }}</div> -->
              </div>
            </div>
            <div
              class="gery-box mb-4"
              style="width: 408px"
              v-show="detail.is_board_member == 1"
            >
              <div class="row">
                <div
                  class="col-lg-6 col-6 pb-3"
                  v-show="detail.is_board_member == 1"
                >
                  <b>Board Membership</b>
                </div>
                <div
                  class="col-lg-2 col-2 pb-3"
                  v-show="detail.is_board_member == 1"
                >
                  <img src="/img/correct.png" alt="" height="30" width="30" />
                </div>
              </div>
            </div>
            <div
              class="gery-box mb-4"
              style="width: 408px"
              v-show="detail.is_invester == 1"
            >
              <div class="row">
                <div class="col-lg-6 col-6 pb-3">
                  <b>Investor</b>
                </div>
                <div class="col-lg-2 col-2 pb-3">
                  <img src="/img/correct.png" alt="" height="30" width="30" />
                </div>
              </div>
            </div>
            <div
              class="gery-box mb-4"
              style="width: 408px"
              v-if="investedCompanyList && investedCompanyList.length"
            >
              <div>
                <div class="custom-title clearfix pb-2">
                  Investments & Stakeholding
                </div>
                <div class="row my-3">
                  <div
                    class="col-lg-3 col-sm-3 col-3"
                    v-for="(data, index) in investedCompanyList"
                    :key="index"
                  >
                    <a v-on:click="data.invested_company_id ? $router.push('/company-detail/' + data.invested_company_id) : null"
                      ><img
                        :title="data.company_name ? data.company_name : ''"
                        :src="
                          data.company_logo
                            ? data.company_logo
                            : '/img/user-pic.png'
                        "
                        height="100"
                        width="100"
                        class="md-size"
                        style="cursor: pointer; border-radius: 30px"
                    /></a>
                  </div>
                </div>
              </div>
              <!-- <ErrorComponent @retry="getList(0)" ref="errorComponent" /> -->
            </div>
            <div
              class="gery-box mb-4"
              style="width: 408px"
              v-if="similarExecutive && similarExecutive.length"
            >
              <div>
                <div class="custom-title clearfix pb-2">Similar Executive</div>
                <div class="row my-3">
                  <div
                    class="col-lg-2 col-sm-2 col-2 mt-2"
                    v-for="(data, index) in similarExecutive"
                    :key="index"
                  >
                    <a v-if="index < 12" v-on:click="getDetails(data.id)">
                      <img
                        style="cursor: pointer"
                        v-if="data.executive_logo || !data.gender"
                        :title="data.executive_name ? data.executive_name : ''"
                        :src="
                          data.executive_logo
                            ? data.executive_logo
                            : '/img/user-pic.png'
                        "
                        alt=""
                        class="round"
                      />
                      <img
                        style="cursor: pointer"
                        v-if="data.gender == 'male' && !data.executive_logo"
                        :title="data.executive_name ? data.executive_name : ''"
                        :src="
                          data.executive_logo
                            ? data.executive_logo
                            : '/img/male.png'
                        "
                        alt=""
                        class="round"
                      />
                      <img
                        style="cursor: pointer"
                        v-if="data.gender == 'female' && !data.executive_logo"
                        :title="data.executive_name ? data.executive_name : ''"
                        :src="
                          data.executive_logo
                            ? data.executive_logo
                            : '/img/female.png'
                        "
                        alt=""
                        class="round"
                      />
                    </a>
                  </div>
                </div>
                <div class="custom-title clearfix pb-2">
                  <!-- Similar Executive -->
                  <a
                    v-if="similarExecutive.length > 12"
                    style="cursor: pointer"
                    class="pull-right font16"
                    v-on:click="showDetails()"
                    >View More</a
                  >
                </div>
              </div>
              <!-- <ErrorComponent @retry="getList(0)" ref="errorComponent" /> -->
            </div>
            <div
              class="gery-box mt-3"
              style="width: 408px"
              v-if="detail.commentry && detail.commentry.length > 0"
            >
              <div class="title clearfix">Recent Commentary</div>
              <div v-for="(data, index) in detail.commentry" :key="index">
                <div class="row pb-2 mt-3" v-if="index <= 4">
                  <div
                    class="col-lg-3 col-sm-3 my-auto"
                    style="cursor: pointer"
                    v-on:click="showCommnetryDetails('COMMENTRY')"
                  >
                    <img
                      :title="
                        data.executive && data.executive.executive_name
                          ? data.executive.executive_name
                          : ''
                      "
                      :src="
                        data.executive && data.executive.executive_logo
                          ? data.executive.executive_logo
                          : '/img/new.png'
                      "
                      height="100"
                      width="100"
                      class="md-size"
                    />
                  </div>
                  <!-- <div
                    v-if="data.is_company_logo == 1"
                    class="col-lg-3 col-sm-3 my-auto"
                    style="cursor: pointer"
                    v-on:click="openLink(data.source)"
                  >
                    <img
                      :title="
                        data.company && data.company.company_name
                          ? data.company.company_name
                          : ''
                      "
                      :src="
                        data.company && data.company.company_logo
                          ? data.company.company_logo
                          : '/img/new.png'
                      "
                      height="100"
                      width="100"
                      class="md-size"
                    />
                  </div>
                  <div
                    v-if="data.is_company_logo == 0"
                    class="col-lg-3 col-sm-3 my-auto"
                    style="cursor: pointer"
                    v-on:click="openLink(data.source)"
                  >
                    <img
                      :title="
                        data.publication && data.publication.name
                          ? data.publication.name
                          : ''
                      "
                      :src="
                        data.publication && data.publication.publication_logo
                          ? data.publication.publication_logo
                          : ''
                      "
                      height="100"
                      width="100"
                      class="md-size"
                    />
                  </div> -->

                  <div class="col-lg-9 col-sm-9 my-auto calibri">
                    {{ $helperService.getText(data.comment, 100) }}
                  </div>

                  <hr />
                </div>
              </div>
              <div class="title clearfix">
                <a
                  v-if="detail.commentry.length > 5"
                  style="cursor: pointer"
                  class="pull-right font16"
                  v-on:click="showCommnetryDetails('COMMENTRY')"
                  >View More</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ErrorComponent @retry="getDetail(0)" ref="errorComponent" />
    <EmailModel ref="emailModel" />
  </div>
</template>
<script>
import CountryFlag from "vue-country-flag-next";
import ErrorComponent from "@/components/ErrorComponent";
import EmailModel from "@/components/EmailModel";
import YouTube from "vue3-youtube";
import Chart from "chart.js/auto";
export default {
  name: "Executive Detail",
  components: {
    CountryFlag,
    EmailModel,
    ErrorComponent,
    YouTube,
  },
  watch: {
    "$route.fullPath"() {
      this.id = this.$route.params.id;
      this.getExecutiveNewList();
      this.getPastCompanyList();
      this.investedCompanyList = [];
      this.reportingList = [];
      this.getInvestedCompanyList();
      this.getReportingToList();
      this.getCommentryMoveByExecutive();
      this.getDetail();
    },
  },
  data() {
    return {
      id: this.$route.params.id,
      detail: {
        videos: [],
        executive_clusters: [],
        deals_transaction: [],
        country: { phone_code: "" },
        report_to: {
          country: { phone_code: "" },
        },
      },
      youtubeVideo: [],
      pastCompanyList: [],
      reportingList: [],
      investedCompanyList: [],
      commentryMovesPerDay: [],
      newsList: [],
      similarExecutive: [],
      loader: false,
      showingFullText: false,
      job_function_id_new: "",
      industry_id_new: "",
      seniority_id: "",
      company_type: "",
      country_id: "",
    };
  },
  mounted() {
    this.getExecutiveNewList();
    this.getPastCompanyList();
    this.getReportingToList();
    this.getInvestedCompanyList();
    this.getCommentryMoveByExecutive();
    if (this.id) {
      this.getDetail();
    }
  },
  computed: {
    formattedBody() {
      if (this.showingFullText) {
        return this.detail.executive_biography.replaceAll("\n", "<br>");
      }

      return `${this.detail.executive_biography.slice(0, 200).trim()}...`;
    },
  },
  methods: {
    showEmailPopup(data) {
      this.$refs.emailModel.showModal(data);
    },
    getCommentryMoveByExecutive() {
      this.$api
        .getAPI({
          _action: "/commentry/executive/moves/topic/" + this.id,
        })
        .then((res) => {
          this.commentryMovesPerDay = res;
          this.getGraphCommentryMoveByExecutive();
        })
        .catch(() => {});
    },
    getGraphCommentryMoveByExecutive() {
      this.labelsArr = [];
      this.revanueArr = [];

      for (var i = 0; i < this.commentryMovesPerDay.length; i++) {
        var topic_name = this.$helperService.getText2(
          this.commentryMovesPerDay[i].topic_name,
          20
        );
        this.labelsArr.push(topic_name);
        var revanue = this.commentryMovesPerDay[i].count;
        this.revanueArr.push(revanue);
      }
      if (this.sales) {
        this.sales.destroy();
      }
      const ctx = document.getElementById("bar-chart5").getContext("2d");
      this.myChart = new Chart(ctx, {
        type: "pie",
        data: {
          labels: this.labelsArr,
          datasets: [
            {
              data: this.revanueArr,
              backgroundColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
              ],
              borderColor: "#87CEEB",
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
            },
          },
          scales: {
            y: {
              display: false,
            },
            x: {
              display: false,
            },
          },
        },
      });
    },
    getList() {
      this.similarExecutive = [];
      var obj = {
        limit: this.limit ? this.limit : "",
        job_function_id_new: this.job_function_id_new,
        industry_id_new: this.industry_id_new,
        seniority_id: this.seniority_id,
        company_type: this.company_type,
        country_id: this.country_id,
      };
      this.$api
        .getAPI({
          _action: "/executive/pagination/list",
          _body: obj,
        })
        .then((res) => {
          this.similarExecutive = res.list;
          for (var i in this.similarExecutive) {
            if (this.similarExecutive[i].id == this.id) {
              this.similarExecutive.splice(i, 1);
            }
          }
        })
        .catch(() => {});
    },
    showDetails() {
      this.$router.push(
        "/similiar-executive/" + this.id + "/" + this.detail.new_company_id
      );
    },
    showCommnetryDetails() {
      this.$router.push("/similar-commentaries/" + this.detail.new_company_id);
    },
    showMoreNews() {
      this.$router.push(
        "/executive-similar-news/" + this.id + "/" + "EXECUTIVE"
      );
    },
    showClusterList() {
      this.$router.push("/listing");
    },
    showDealsAndTransaction() {
      this.$router.push("/similar-deals-transactions/" + this.id);
    },
    getDetails(id) {
      this.$router.push("/executive-detail/" + id);
    },
    addToFavorite(data) {
      var obj = {};
      obj.executive_id = data.id;
      this.$api
        .putAPI({
          _action: "/add/executive/fav",
          _body: obj,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          return (this.detail.is_fav = this.detail.is_fav == 1 ? 0 : 1);
          // this.getList(0);data.is_fav
          // this.$router.push("/executive");
        })
        .catch(() => {});
    },
    openLink(url) {
      window.open(url);
    },
    getDetail() {
      if (this.id) {
        this.$refs.errorComponent.updateFormLoader(true);
        this.$api
          .getAPI({
            _action: "/executive/detail/" + this.id,
          })
          .then((res) => {
            this.detail = res;

            if (res.new_company_id) {
              this.job_function_id_new = res.job_function_id_new;
              this.industry_id_new = res.industry_id_new;
              this.seniority_id = res.seniority_id;
              this.country_id = res.country_id;
              if (res.new_company) {
                this.company_type = res.new_company.company_type;
              }
            }
            this.getList();

            var index = 0;
            var $this = this;
            for (var k in $this.detail.videos) {
              if (!$this.detail.videos[k].video_url) {
                $this.detail.videos.splice(k, 1);
              }
            }
            for (var i in this.detail.videos) {
              setTimeout(() => {
                $this.youtubeVideo.push($this.detail.videos[index++]);
              }, 700 * i + 1);
            }
            this.loader = false;
            if (this.$refs.errorComponent) {
              // this.$refs.errorComponent.setData(res,undefined);
              this.$refs.errorComponent.updateFormLoader(false);
            }
          })
          .catch(() => {
            this.loader = false;
            if (this.$refs.errorComponent) {
              // this.$refs.errorComponent.setData(0, undefined, "");
              this.$refs.errorComponent.updateFormLoader(false);
            }
          });
      }
    },
    getExecutiveNewList() {
      this.newsList = [];
      var obj = {};
      obj.limit = 6;
      if (this.id) {
        this.$api
          .getAPI({
            _action: "/executive/news/pagination/list/" + this.id,
            _body: obj,
          })
          .then((res) => {
            this.newsList = res.list;
          })
          .catch(() => {});
      }
    },
    getPastCompanyList() {
      this.pastCompanyList = [];
      var obj = {};
      obj.limit = 6;
      if (this.id) {
        this.$api
          .getAPI({
            _action: "/past/company/list/" + this.id,
            _body: obj,
          })
          .then((res) => {
            this.pastCompanyList = res;
          })
          .catch(() => {});
      }
    },
    getReportingToList() {
      this.reportingList = [];
      var obj = {};
      if (this.id) {
        this.$api
          .getAPI({
            _action: "/reporting-to/" + this.id,
            _body: obj,
          })
          .then((res) => {
            this.reportingList = res;
          })
          .catch(() => {});
      }
    },
    getInvestedCompanyList() {
      this.investedCompanyList = [];
      if (this.id) {
        this.$api
          .getAPI({
            _action: "/executive/invested/company/list/" + this.id,
          })
          .then((res) => {
            this.investedCompanyList = res.list;
          })
          .catch(() => {});
      }
    },
  },
};
</script>

<style>
.normal-flag[data-v-60be6971] {
  margin: 0em -0.5em -1em -0em !important;
  margin-top: 0em !important;
  margin-right: -0.5em !important;
  margin-bottom: -1em !important;
  margin-left: 0em !important;
  transform: scale(0.5) !important;
  -ms-transform: scale(0.5) !important;
  -webkit-transform: scale(0.5) !important;
  -moz-transform: scale(0.5) !important;
}
</style>
