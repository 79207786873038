<template>
  <div>
    <section class="ftco-section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6 text-center mb-5">
            <a href="#"><img src="/img/logo.png" alt="" /></a>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-7 col-lg-5">
            <div class="login-wrap p-4 pt-4">
              <!--<div class="icon d-flex align-items-center justify-content-center">
                <img src="assets/img/avatar-4.jpg" alt="" style="border-radius: 100px; width: 100px; height: 100px;" />
              </div> -->
              <h3 class="title-h3 text-center pt-0" style="font-size: 28px">
                Login
              </h3>
              <Form @Submit="login" class="login-form">
                <div class="form-group mr-3">
                  <i class="fa fa-envelope mr-1" aria-hidden="true"></i>
                  <label>Email</label>
                  <Field
                    type="text"
                    class="form-control"
                    name="Username"
                    placeholder="Email"
                    v-model="details.username"
                    rules="required:email|email"
                    :validateOnInput="true"
                  />
                  <ErrorMessage name="Username" class="text-danger" />
                </div>
                <div class="form-group mr-3">
                  <i class="fa fa-lock mr-1" aria-hidden="true"></i>
                  <label>Password</label>
                  <Field
                    type="password"
                    class="form-control"
                    name="_password"
                    placeholder="Password"
                    v-model="details.password"
                    rules="required:password"
                    :validateOnInput="true"
                  />
                  <ErrorMessage name="_password" class="text-danger" />
                </div>
                <div class="form-group mt-5 mr-3">
                  <button
                    class="form-control btn-primary rounded submit px-3"
                    id="login-button"
                  >
                    Sign In
                  </button>
                </div>
                <div class="row">
                  <div class="col-lg-6 col-sm-6 col-6">
                    <label class="checkbox-wrap checkbox-primary">
                      <input type="checkbox" checked />
                      <span class="checkmark"></span>
                      Remember Me
                    </label>
                  </div>
                  <div class="col-lg-6 col-sm-6 col-6 text-md-right">
                    <a
                      v-on:click="$router.push('/forgot-password')"
                      data-toggle="modal"
                      style="color: #de4a38; cursor: pointer"
                      >Forgot Password</a
                    >
                  </div>
                  <div class="col-lg-12 col-sm-12 text-center mt-4">
                    Don't have an account?
                    <a
                      v-on:click="showRequestDemoModel()"
                      style="color: #de4a38; cursor: pointer"
                      >Request Demo</a
                    >
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Forgot Password -->
    <TextModal ref="textModal">
      <div class="modal-header pt-0">
        <h5 class="modal-title">Forgot Password</h5>
        <button
          type="button"
          class="close"
          v-on:click="$refs.textModal.closeModal"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="">
          <input
            class="form-control"
            type="text"
            placeholder="Email"
            id="email"
            name="email"
          />
        </div>
      </div>

      <div class="col-12 modal-footer" style="justify-content: center">
        <button class="btn btn-primary">Submit</button>
      </div>
    </TextModal>
    <TextModal ref="otpModalNew">
      <div
        class="modal-header pt-0 text-center"
        style="justify-content: center; display: block ruby"
      >
        <h5 class="modal-title">Verfiy You Email</h5>
        <button class="close" v-on:click="$refs.otpModalNew.closeModal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="col-12 row">
        <div class="col-md-12 col-lg-12">
          <div class="login-wrap p-4 pt-4">
            <div class="posAbs mt-3">
              <!-- <router-link to="/forgot-password">
              <a style="color: #000"><i class="fa fa-arrow-left"></i></a>
            </router-link> -->
            </div>
            <div class="form-body">
              <div class="mt-3 col-md-12 col-sm-12 text-center">
                <b>Please Enter the OTP sent On </b>
                <div>
                  <div class="pt-1 text-center" style="color: #de4a38">
                    <a>{{ this.requestDemoObj.email }}</a>
                  </div>
                </div>
                <div class="text-center my-4">
                  <img
                    src="/img/ENTER_OTP.png"
                    alt=""
                    width="100"
                    height="100"
                  />
                </div>
                <Form @submit="verifyOtp()">
                  <div class="text-center mb-4 digit-group">
                    <Field
                      id="codeBoxneww1"
                      style="color: black"
                      type="text"
                      name="otp1"
                      class="otp-control"
                      maxlength="1"
                      rules="required"
                      v-on:keyup="onKeyUpEvent(1, $event)"
                      v-on:focus="onFocusEvent(1)"
                    />
                    <Field
                      id="codeBoxneww2"
                      type="text"
                      style="color: black"
                      name="otp2"
                      maxlength="1"
                      rules="required"
                      v-on:keyup="onKeyUpEvent(2, $event)"
                      v-on:focus="onFocusEvent(2)"
                    />
                    <Field
                      id="codeBoxneww3"
                      style="color: black"
                      type="text"
                      name="otp3"
                      maxlength="1"
                      rules="required"
                      v-on:keyup="onKeyUpEvent(3, $event)"
                      v-on:focus="onFocusEvent(3)"
                    />
                    <Field
                      id="codeBoxneww4"
                      type="text"
                      style="color: black"
                      name="otp4"
                      class="otp-control"
                      maxlength="1"
                      rules="required:otp"
                      v-on:keyup="onKeyUpEvent(4, $event)"
                      v-on:focus="onFocusEvent(4)"
                    />
                  </div>
                  <div class="text-center">
                    <ErrorMessage name="otp4" class="text-danger" />
                  </div>
                  <div class="mt-2 col-md-6 col-sm-6 mx-auto">
                    <button class="submit-btn" id="verify-otp-button">
                      Submit
                    </button>
                  </div>
                  <div class="col-mg-12 col-sm-12 text-center">
                    <p class="mb-0 mt-3">
                      <a
                        style="cursor: pointer; color: #de4a38"
                        id="resend-button"
                        v-on:click="resend()"
                        >Resend OTP</a
                      >
                    </p>
                    <hr />
                    <!-- <p class="mb-0 mt-3">
                    <a href="#termsconditionsModal" data-toggle="modal"
                      ><input type="checkbox" class="" id="" /> Accept Terms and
                      Conditions
                    </a>
                  </p> -->
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TextModal>
    <Footer />
  </div>
  <TextModal ref="textModal">
    <div
      class="modal-header pt-0 text-center"
      style="
        justify-content: center;
        display: block ruby;
        background-color: black;
        margin-top: -21px;
      "
    >
      <h5 class="modal-title">
        <a class="navbar-brand"
          ><img src="/img/logo.svg" alt="ExecLibrary" width="100" height="51"
        /></a>
      </h5>
      <button
        type="button"
        class="close"
        style="color: white !important"
        v-on:click="$refs.textModal.closeModal"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <Form ref="requestDemo" @submit="save">
      <div class="col-lg-12 col-sm-12 row">
        <div class="col-lg-6 form-group mb-3 mt-3">
          <label>First Name</label>
          <Field
            type="text"
            name="first_name"
            class="form-control"
            placeholder="First Name"
            v-model="requestDemoObj.first_name"
            rules="required:first name"
            :validateOnInput="true"
          />
          <ErrorMessage name="first_name" class="text-danger" />
        </div>
        <div class="col-lg-6 form-group mb-3 mt-3">
          <label>Last Name</label>
          <Field
            type="text"
            name="last_name"
            class="form-control"
            placeholder="Last Name"
            v-model="requestDemoObj.last_name"
            rules="required:last name"
            :validateOnInput="true"
          />
          <ErrorMessage name="last_name" class="text-danger" />
        </div>

        <div class="col-lg-6 form-group mb-3">
          <label>Email</label>
          <Field
            type="text"
            name="email"
            class="form-control"
            placeholder="Email"
            v-model="requestDemoObj.email"
            rules="required:email|email"
            :validateOnInput="true"
          />
          <ErrorMessage name="email" class="text-danger" />
        </div>
        <div class="col-lg-6 form-group mb-3">
          <label>Contact Number</label>
          <Field
            type="text"
            name="contact"
            class="form-control"
            placeholder="Contact"
            v-on:keypress="$helperService.allowOnlyNumericValue($event)"
            v-model="requestDemoObj.contact"
            rules="required:contact"
            :validateOnInput="true"
          />
          <ErrorMessage name="contact" class="text-danger" />
        </div>
        <div class="col-lg-12 form-group mb-3">
          <label>Company Name</label>
          <Field
            type="text"
            name="company_name"
            class="form-control"
            placeholder="Company Name"
            v-model="requestDemoObj.company_name"
            rules="required:company name"
            :validateOnInput="true"
          />
          <ErrorMessage name="company_name" class="text-danger" />
        </div>
        <div class="col-lg-12 form-group mb-3">
          <label>Note</label>
          <textarea
            type="textarea"
            name="Note"
            class="form-control"
            placeholder="Note"
            v-model="requestDemoObj.note"
          />
          <!-- <ErrorMessage name="last_name" class="text-danger" /> -->
        </div>
      </div>
      <div class="col-lg-12 modal-footer pb-0" style="justify-content: center">
        <button
          class="form-control btn-primary rounded submit px-3 mt-1"
          id="request-button"
          style="width: 45%; border-radius: 5px"
        >
          Request Demo
        </button>
        <!-- <button
          type="button"
          class="fil-btn"
          id="cancel-button"
          v-on:click="$refs.textModal.closeModal"
          style="width: 20%; border-radius: 5px"
        >
          Cancel
        </button> -->
      </div>
    </Form>
  </TextModal>
</template>
<script>
import TextModal from "@/components/TextModal";
import { Form, Field, ErrorMessage } from "vee-validate";
import Footer from "@/components/Footer";
export default {
  name: "Login",
  components: {
    TextModal,
    Form,
    Footer,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      details: {
        email: "",
        password: "",
      },
      requestDemoObj: {},
    };
  },
  mounted() {},
  methods: {
    verifyOtp() {
      var obj = {};
      obj.email = localStorage.getItem("email");
      obj.id = localStorage.getItem("id");
      obj.otp =
        document.getElementById("codeBoxneww1").value +
        "" +
        document.getElementById("codeBoxneww2").value +
        "" +
        document.getElementById("codeBoxneww3").value +
        "" +
        document.getElementById("codeBoxneww4").value;
      this.$api
        .putAPI({
          _action: "/verify-request-email",
          _body: obj,
          _buttonId: "verify-otp-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.otpModalNew.closeModal();
        })
        .catch(() => {});
    },
    resend() {
      var obj = {};
      obj.email = localStorage.getItem("email");
      obj.id = localStorage.getItem("id");
      this.$api
        .postAPI({
          _action: "/resend-request-demo-otp",
          _body: obj,
          _buttonId: "resend-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
        })
        .catch(() => {});
    },
    getCodeBoxElement(index) {
      return document.getElementById("codeBoxneww" + index);
    },
    onKeyUpEvent(index, event) {
      const eventCode = event.which || event.keyCode;
      if (this.getCodeBoxElement(index).value.length === 1) {
        if (index !== 4) {
          this.getCodeBoxElement(index + 1).focus();
        } else {
          this.getCodeBoxElement(index).blur();
        }
      }
      if (eventCode === 8 && index !== 1) {
        this.getCodeBoxElement(index - 1).focus();
      }
    },
    onFocusEvent(index) {
      for (var item = 1; item < index; item++) {
        const currentElement = this.getCodeBoxElement(item);
        if (!currentElement.value) {
          currentElement.focus();
          break;
        }
      }
    },
    showRequestDemoModel() {
      this.$refs.requestDemo.resetForm();
      this.requestDemoObj.note = "";
      this.$refs.textModal.showModal();
    },
    save() {
      this.$api
        .postAPI({
          _action: "/request-demo",
          _body: this.requestDemoObj,
          _buttonId: "request-button",
        })
        .then((res) => {
          localStorage.setItem("email", this.requestDemoObj.email);
          localStorage.setItem("id", res.id);
          this.$refs.textModal.closeModal();
          this.$refs.otpModalNew.showModal();
        })
        .catch(() => {});
    },
    showForgotModel() {
      this.$refs.textModal.showModal();
    },
    login() {
      this.$api
        .postAPI({
          _action: "/login",
          _body: this.details,
          _buttonId: "login-button",
        })
        .then((res) => {
          if (res && res.access_token) {
            localStorage.setItem("token", res.access_token);
            this.getLoginUserDetail();
          }
        })
        .catch(() => {});
    },
    getLoginUserDetail() {
      this.$api
        .getAPI({
          _action: "/me",
        })
        .then((res) => {
          if (res) {
            this.$router.push("/executive");
          }
        })
        .catch(() => {
          localStorage.removeItem("token");
        });
    },
  },
};
</script>
