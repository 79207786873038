<template>
  <div>
    <div class="gery-box">
      <div v-if="!loader && res && res.count">
        <div class="custom-title clearfix">
          {{ title }}
        </div>
        <div class="row">
          <div
            class="col-lg-3 col-sm-3 col-3"
            v-for="(data, index) in res.list"
            :key="index"
          >
            <a v-if="index < 8" v-on:click="detail(data.id)"
              ><img
                :title="getTitle(data)"
                :src="getLogo(data)"
                class="img-fluid imgs-circle"
            /></a>
          </div>
        </div>
        <div class="custom-title clearfix">
          <a
            v-if="res.list.length > 8"
            style="cursor: pointer"
            class="pull-right font16"
            v-on:click="redirectTo()"
            >View More</a
          >
        </div>
      </div>
      <ErrorComponent @retry="getList(0)" ref="errorComponent" />
    </div>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
export default {
  name: "ELGrid",
  components: {
    ErrorComponent,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    limit: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },

  watch: {
    id(newVal) {
      if (newVal) {
        this.getList();
      }
    },
  },

  data() {
    return {
      res: {},
      loader: false,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    detail(id) {
      this.$emit("detail", id);
    },
    redirectTo() {
      this.$emit("show-details");
    },
    getList() {
      this.res = [];
      this.$refs.errorComponent?.updateListLoader(true);
      var obj = {
        limit: this.limit ? this.limit : "",
      };
      var action = "";
      if (this.type == "SIMILAR") {
        obj.company_id = this.id;
        action = "/similar/company/list";
      } else if (this.type == "PAST" || this.type == "CURRENT") {
        if (this.type == "PAST") {
          action = "/past/executive/pagination/list";
          obj.prev_company_id = this.id;
        } else {
          action = "/executive/pagination/list";
          obj.new_company_id = this.id;
        }
      }
      this.$api
        .getAPI({
          _action: action,
          _body: obj,
        })
        .then((res) => {
          this.res = res;
          this.loader = false;
          this.$refs.errorComponent?.updateListLoader(false);
        })
        .catch(() => {
          this.loader = false;
          this.$refs.errorComponent?.updateListLoader(false);
        });
    },
    getLogo(obj) {
      var logo = "/img/user-pic.png";
      if (this.type == "SIMILAR") {
        if (obj.company_logo) {
          logo = obj.company_logo;
        }
      } else {
        if (obj.executive_logo) {
          logo = obj.executive_logo;
        } else {
          if (obj.gender) {
            if (obj.gender == "male") {
              logo = "/img/male.png";
            } else if (obj.gender == "female") {
              logo = "/img/female.png";
            }
          }
        }
      }
      return logo;
    },
    getTitle(obj) {
      var logo = "";
      if (this.type == "SIMILAR") {
        if (obj.company_name) {
          logo = obj.company_name;
        }
      } else {
        if (obj.executive_name) {
          logo = obj.executive_name;
        }
      }
      return logo;
    },
  },
};
</script>
