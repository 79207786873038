import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import api from './services/WebService'
import Notifications from '@kyvg/vue3-notification'
import helperService from './services/HelperService';
import shareService from './services/ShareService'
import "./services/ValidationRules";
import global from './services/global'
import YouTube from 'vue3-youtube'




const app = createApp(App);
app.use(router)
app.use(Notifications)
app.mount('#app')
app.use(YouTube)


app.config.globalProperties.$helperService = helperService;
app.config.globalProperties.$shareService = shareService;
app.config.globalProperties.$api = api;
app.config.globalProperties.$global = global;