<template>
  <main role="main">
    <div id="main-wraper">
      <Header />
      <div class="page-wrapper">
        <div class="container mt-md-5 mt-4">
          <div class="row">
            <div class="col-lg-9 col-sm-9 static-page" style="margin: 0 auto">
              <div class="mb-4 text-center">
                <!-- <a
                  href="javascript:void(0)"
                  class="font-22 float-start text-dark"
                   @click="$router.go(-1)"
                  ><i class="bi bi-arrow-left"></i
                ></a> -->
                <h2 class="font-22 font-medium">{{ detail.title }}</h2>
              </div>

              <!-- <div v-html="detail.description"></div> -->
              <h2 class="title-h2 text-center">Pricing</h2>
              <p class="text-center" style="margin-bottom: 0">
                We understand that there is no one size that fits all. We will
                be happy to discuss your information needs and offer a solution
                and a fee that best fits to your budget. Kindly fill in your
                details and book a demo and one of our amazing account manager
                will get in touch within 24 hours:
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="contact-bg empty50">
        <div class="container">
          <div class="row h-100">
            <div class="col-lg-6 col-sm-6 my-auto">
              <h3>Contact Us</h3>
              <p class="queries">
                Do you have any queries? You can visit our office during
                business hours OR send us a query.
              </p>
              <!-- <div class="pb-4">
              <img src="assets/img/clock.png" alt="" />&nbsp;
              <span style="color: #fff">Mon – Fri 09:00 AM – 09:00 PM</span>
            </div>
            <div class="pb-4">
              <img src="assets/img/location.png" alt="" />&nbsp;
              <span style="color: #fff"
                >591 Lakewood Rd<br />Four Oaks, North Carolina(NC), 27524</span
              >
            </div>
            <div class="pb-4">
              <img src="assets/img/cell.png" alt="" />&nbsp;
              <a href="tel:(123) 123-1234" style="color: #fff"
                >(123) 123-1234</a
              >
            </div> -->
              <!-- <div class="pb-4">
              <img src="img/messagew.png" alt="" />&nbsp; -->
              <!-- <a href="mailto:support@execlibrary.com" style="color: #fff"
                >support@execlibrary.com</a> -->
              <!-- <a href="mailto:myplan@execlibrary.com" style="color: #fff"
                >myplan@execlibrary.com</a>
            </div> -->
            </div>
            <div class="col-lg-6 col-sm-6">
              <div class="contact-form">
                <h4>Contact Us</h4>
                <Form @submit="save()">
                  <div class="form-group">
                    <label>Full Name</label>
                    <Field
                      type="text"
                      name="Full_Name"
                      class="form-control"
                      placeholder="Full Name"
                      v-model="detail.name"
                    />
                  </div>
                  <div class="form-group">
                    <label>Email</label>
                    <Field
                      type="email"
                      name="name_email"
                      class="form-control"
                      placeholder="Email"
                      v-model="detail.email"
                      rules="required:email|email"
                      :validateOnInput="true"
                    />
                    <ErrorMessage name="name_email" class="text-danger" />
                  </div>
                  <div class="form-group">
                    <label>Subject</label>
                    <Field
                      type="text"
                      name="Subject"
                      class="form-control"
                      placeholder="Subject"
                      v-model="detail.subject"
                    />
                  </div>
                  <div class="form-group">
                    <label>Message</label>
                    <textarea
                      type="text"
                      class="form-control"
                      placeholder="Message"
                      v-model="detail.message"
                    >
                    </textarea>
                  </div>
                  <div>
                    <button id="save-button" value="Submit" class="submit-btn">
                      Submit
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </main>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "PrivacyPolicy",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      detail: {},
      mobile: this.$route.query.mobile,
    };
  },
  mounted() {
    // this.getDetail();
  },
  methods: {
    getDetail() {
      this.$api
        .getAPI({
          _action: "/cms/privacy",
        })
        .then((res) => {
          this.detail = res;
        })
        .catch(() => {});
    },
  },
};
</script>
<style>
footer .light-blue-bg {
  margin-top: 0;
}
</style>
