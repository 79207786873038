<template>
  <div>
    <router-view />
  </div>
  <notifications position="top right" classes="vue-notification" style="margin-top: 50px !important;z-index: 9999999999999 !important;" />
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>