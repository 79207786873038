<template>
  <div>
    <section role="main" class="empty">
      <div class="container">
        <div class="row icon-tabs">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <router-link to="/executive" class="nav-link"
                ><img src="/img/icons/executives.png" alt="Executives" />
                Executives</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/company" class="nav-link active"
                ><img src="/img/icons/companies.png" alt="Companies" />
                Companies</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/news" class="nav-link"
                ><img src="/img/icons/deals.png" alt="News" /> News</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/deals-transactions" class="nav-link"
                ><img src="/img/icons/deals.png" alt="Deals & Transactions" />
                Deals & Transactions</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/listing" class="nav-link"
                ><img src="/img/book.png" style="height: 23px" />
                Listing</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/commentaries" class="nav-link"
                ><img src="/img/icons/commentaries.png" alt="Commentaries" />
                Commentaries</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/interesting-bites" class="nav-link"
                ><img
                  src="/img/icons/interesting.png"
                  alt="Interesting Bites"
                />
                Interesting Bites</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/open-positons-new" class="nav-link"
                ><img src="/img/icons/position.png" alt="Open Position" /> Open
                Positons</router-link
              >
            </li>
            <li class="">
              <a
                class="nav-link"
                style="
                  font-weight: bold !important;
                  margin-top: 8px;
                  font-size: 13px;
                  color: #1f3342;
                "
              >
                {{ count }}</a
              >
            </li>
          </ul>
          <div class="tab-content mb-3">
            <div>
              <!--  -->
              <div class="container px-0">
                <div class="row">
                  <div class="col-lg-12 col-sm-12">
                    <!-- <form class="form-search form-inline"> -->
                    <input
                      type="text"
                      class="search-query"
                      style="border: 0px none; width: 100%"
                      v-on:keyup.enter="getList(0)"
                      placeholder="Search"
                      v-model="searchObj.keyword"
                    />
                    <!-- </form> -->
                  </div>
                </div>
                <hr class="mt-0 mb-2" />
              </div>
              <div class="row">
                <div class="col-lg-2 col-sm-4 col-6">
                  <label class="label">Company Industry</label>
                  <Multiselect
                    mode="single"
                    trackBy="industry_name"
                    label="industry_name"
                    valueProp="id"
                    v-model="searchObj.industry_id"
                    :options="industryList"
                    searchable="true"
                    placeholder="Select"
                    @change="closesub($event)"
                    @input="onIndustryChange"
                    @search-change="searchIndustryName($event)"
                  />
                </div>
                <div v-if="isShowSubsector" class="col-lg-2 col-sm-4 col-6">
                  <label class="label">Subsector</label>

                  <Multiselect
                    mode="single"
                    trackBy="industry_name"
                    label="industry_name"
                    valueProp="id"
                    v-model="searchObj.subsector_id"
                    :options="subsectorList"
                    searchable="true"
                    placeholder="Select"
                  />
                </div>
                <div class="col-lg-2 col-sm-4 col-6">
                  <label class="label">Company Location</label>
                  <Multiselect
                    mode="single"
                    trackBy="country_name"
                    label="country_name"
                    valueProp="id"
                    v-model="searchObj.country_id"
                    :options="cityList"
                    searchable="true"
                    placeholder="Select"
                    @search-change="searchCityName($event)"
                  />
                </div>
                <div class="col-lg-2 col-sm-4 col-6">
                  <label class="label">Company Size</label>
                  <Multiselect
                    mode="single"
                    trackBy="value"
                    label="value"
                    valueProp="id"
                    v-model="searchObj.company_size"
                    :options="company_sizes"
                    searchable="true"
                    placeholder="Select"
                    @search-change="searchCompanySize($event)"
                  />
                </div>
                <div class="col-lg-2 col-sm-4 col-6">
                  <label class="label"
                    >Company Type {{ searchObj.company_type }}</label
                  >
                  <Multiselect
                    mode="single"
                    trackBy="value"
                    label="value"
                    valueProp="id"
                    v-model="searchObj.company_type"
                    :options="company_types"
                    searchable="true"
                    placeholder="Select"
                  />
                </div>
                <div class="col-lg-2 col-sm-4 col-6" v-show="!isShowSubsector">
                  <label class="label">Deal Stage</label>
                  <Multiselect
                    mode="single"
                    trackBy="name"
                    label="name"
                    valueProp="id"
                    v-model="searchObj.deal_stage_id"
                    :options="dealStageList"
                    searchable="true"
                    placeholder="Select"
                    @search-change="searchDealStage($event)"
                  />
                </div>
                <div class="col-lg-2 col-sm-4 col-6">
                  <!-- <label></label> -->
                  <button
                    v-on:click="getList(0)"
                    type="button"
                    style="margin-top: 23px; width: 50%"
                    class="apply-btn mr-1"
                  >
                    Apply
                  </button>
                  <button
                    v-on:click="resetFilter()"
                    type="button"
                    style="margin-top: 23px; width: 45%"
                    class="apply-btn"
                  >
                    Reset
                  </button>
                  <!-- <div class="text-center">
                    <a
                      style="cursor: pointer"
                      v-on:click="getAdvanceSearch()"
                      class="advance-search"
                      >{{
                        is_show ? "Hide Advance Search" : "Advance Search"
                      }}</a
                    >
                  </div> -->
                </div>
              </div>
              <div v-show="isShowSubsector" class="row advasearch-data mt-1">
                <div class="col-lg-2 col-sm-4 col-6">
                  <label class="label">Deal Stage</label>
                  <Multiselect
                    mode="single"
                    trackBy="name"
                    label="name"
                    valueProp="id"
                    v-model="searchObj.deal_stage_id"
                    :options="dealStageList"
                    searchable="true"
                    placeholder="Select"
                    @search-change="searchDealStage($event)"
                  />
                </div>
              </div>

              <hr />
              <div class="col-12 row mt-4">
                <div
                  class="col-lg-4 col-sm-4"
                  v-for="(data, index) in list"
                  :key="index"
                >
                  <div class="rectangle-box">
                    <div class="row" style="height: 115px">
                      <div class="col-lg-3 col-sm-3 col-1 pr-2 text-center">
                        <a>
                          <img
                            v-if="data.company_logo"
                            :src="
                              data.company_logo
                                ? data.company_logo
                                : '/img/user-pic.png'
                            "
                            alt=""
                            class="round"
                          />
                          <img
                            v-if="!data.company_logo"
                            :src="
                              data.company_logo
                                ? data.company_logo
                                : '/img/company.png'
                            "
                            alt=""
                            class="round"
                          />
                        </a>
                        <a
                          class="mr-1"
                          style="margin-left: -3px"
                          :title="data.country ? data.country.country_name : ''"
                        >
                          <country-flag
                            :country="
                              data.country ? data.country.country_code : 'US'
                            "
                          />
                        </a>
                      </div>
                      <div class="col-lg-7 col-sm-7 col-9 px-2">
                        <a
                          style="cursor: pointer"
                          v-on:click="goToView('/company-detail/' + data.id)"
                        >
                          <div
                            style="display: initial !important"
                            :title="data.company_name ? data.company_name : '-'"
                            class="client-name"
                          >
                            {{ data.company_name ? data.company_name : "-" }}
                          </div>
                          <div
                            class="address"
                            style="display: initial !important"
                          >
                            <div
                              :title="
                                data.industry ? data.industry.industry_name : ''
                              "
                            >
                              {{
                                data.industry
                                  ? $helperService.getText(
                                      data.industry.industry_name,
                                      20
                                    )
                                  : ""
                              }}
                            </div>

                            <div>
                              {{
                                data.city
                                  ? $helperService.getText(
                                      data.city.city_name,
                                      30
                                    )
                                  : ""
                              }}
                              {{
                                data.country
                                  ? $helperService.getText(
                                      data.country.country_name,
                                      30
                                    )
                                  : ""
                              }}
                            </div>
                            <div>
                              {{
                                data.company_type
                                  ? data.company_type + " Company"
                                  : "-"
                              }}
                            </div>
                            <div>
                              Employee Size:
                              {{ data.company_size ? data.company_size : "-" }}
                            </div>
                          </div>
                        </a>
                      </div>
                      <div class="col-lg-2 col-sm-2 col-2 px-2 text-center">
                        <!-- <span v-if="data.is_fav == 0"
                          ><img
                            v-on:click="addToFavorite(data)"
                            src="/img/favorite.png"
                            width="18"
                            height="18"
                            alt="Favorite"
                            style="opacity: 0.5"
                        /></span>
                        <span v-if="data.is_fav == 1"
                          ><img
                            v-on:click="addToFavorite(data)"
                            src="/img/favorite-fill.png"
                            width="18"
                            height="18"
                            alt="Favorite Fill"
                        /></span> -->
                        <input
                          type="checkbox"
                          v-on:click="addToFavorite(data)"
                          :checked="data.is_fav == 1"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12 col-sm-12">
                      <span class="update-date"
                        >Last Updated :
                        {{
                          $helperService.getFormatedDate(data.updated_on)
                        }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <!--  -->
            </div>
            <ErrorComponent @retry="getList(0)" ref="errorComponent" />
            <div class="row mx-0 mb-4">
              <div class="col-lg-12 mt-5 text-center">
                <div class="page-bottom-pagination text-right">
                  <Pagination
                    @page-change="pageChange"
                    @items-per-page-change="itemsPerPageChange"
                    ref="listPagination"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
import Multiselect from "@vueform/multiselect";
// import { Field } from "vee-validate";
import CountryFlag from "vue-country-flag-next";
export default {
  name: "Executive",
  components: {
    ErrorComponent,
    Pagination,
    Multiselect,
    // Field,
    CountryFlag,
  },
  data() {
    return {
      id: this.$route.params.id,
      loader: true,
      isShowSubsector: false,
      count: 0,
      company_types: [
        "Public",
        "Private",
        "Education",
        "Government",
        "Not-for-Profit",
      ],
      company_sizes: [
        "1-10",
        "11-50",
        "51-200",
        "201-500",
        "501-1000",
        "1001-5000",
        "5001-10000",
        "10000+",
      ],
      searchObj: {
        type: "",
        keyword: "",
        industry_id: "",
        company_size: "",
        company_type: "",
        country_id: "",
        deal_stage_id: "",
        subsector_id: "",
      },
      offset: -1,
      list: [],
      cityList: [],
      industryList: [],
      industryKeyword: "",
      cityKeyword: "",
      companySizeKeyword: "",
      companyTypeKeyword: "",
      dealStageList: [],
      dealStageKeyword: "",
    };
  },
  mounted() {
    if (this.id) {
      this.$shareService.getMapValueAndRemove("company_page_filter");
      this.getCompamyDetail(this.id);
    } else {
      this.getList(0);
    }
    this.industrylist();
    this.CityList();
    this.getDealStageList();
  },
  methods: {
    resetFilter() {
      this.searchObj = {};
      this.searchObj.type = "";
      this.searchObj.keyword = "";
      this.searchObj.industry_id = "";
      this.searchObj.company_size = "";
      this.searchObj.company_type = "";
      this.searchObj.country_id = "";
      this.searchObj.deal_stage_id = "";
      this.searchObj.subsector_id = "";
      this.isShowSubsector = false;
      this.getList(0);
    },

    goToView(url) {
      this.$shareService.setMapValue("company_page_filter", this.searchObj);
      this.$router.push(url);
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page) {
      this.$refs.errorComponent.updateListLoader(true);
      this.list = [];

      if (this.offset != page) {
        this.searchObj.offset = page;
      } else {
        this.searchObj.offset = page;
      }

      if (!this.searchObj.industry_id) {
        this.searchObj.industry_id = "";
      }
      if (!this.searchObj.company_size) {
        this.searchObj.company_size = "";
      }
      if (!this.searchObj.country_id) {
        this.searchObj.country_id = "";
      }
      if (!this.searchObj.subsector_id) {
        this.searchObj.subsector_id = "";
      }
      if (!this.searchObj.deal_stage_id) {
        this.searchObj.deal_stage_id = "";
      }
      if (!this.searchObj.company_type) {
        this.searchObj.company_type = "";
      } else {
        // this.searchObj.company_type = this.searchObj.company_type.toLowerCase();
      }
      if (this.id) {
        this.searchObj.id = this.id;
      }
      var filter_obj = this.$shareService.getMapValue("company_page_filter")
        ? this.$shareService.getMapValue("company_page_filter")
        : null;
      if (filter_obj) {
        this.searchObj = filter_obj;
        this.$shareService.getMapValueAndRemove("company_page_filter");
      }
      this.$api
        .getAPI({
          _action: "/company/pagination/list",
          _body: this.searchObj,
        })
        .then((res) => {
          this.count = res.count;
          for (var i in res.list) {
            if (res.list[i].company_type) {
              res.list[i].company_type =
                res.list[i].company_type.charAt(0).toUpperCase() +
                res.list[i].company_type.substring(1).toLowerCase();
            }
          }
          this.loader = false;
          this.list = res.list;
          this.offset = res.next_offset;
          // if (this.id) {
          //   for (var j in this.list) {
          //     if (this.list[j].id == this.id) {
          //       this.list.splice(j, 1);
          //     }
          //   }
          // }
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
                this.$refs.listPagination.setCurrentPage(
                  this.searchObj.offset + 1
                );
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Company are available");
            this.$refs.errorComponent.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
    getCompamyDetail(id) {
      this.$api
        .getAPI({
          _action: "/company/detail/" + id,
        })
        .then((res) => {
          if (res.industry_id) {
            this.searchObj.industry_id = res.industry_id;
          }
          if (res.company_type) {
            this.searchObj.company_type = this.$helperService.getTitleCase(
              res.company_type
            );
          }
          if (res.company_size) {
            this.searchObj.company_size = res.company_size;
          }
          if (res.country_id) {
            this.searchObj.country_id = res.country_id;
          }
          this.getList(0);
        })
        .catch(() => {});
    },
    addToFavorite(data) {
      var obj = {};
      obj.company_id = data.id;
      this.$api
        .putAPI({
          _action: "/add/company/fav",
          _body: obj,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          return (data.is_fav = data.is_fav == 1 ? 0 : 1);
          // this.getList(0);
          // this.$router.push("/company");
        })
        .catch(() => {});
    },
    searchCompanyType(e) {
      this.companyTypeKeyword = e;
      this.industrylist();
    },
    searchCompanySize(e) {
      this.companySizeKeyword = e;
      this.industrylist();
    },
    searchIndustryName(e) {
      this.industryKeyword = e;
      this.industrylist();
    },
    searchDealStage(e) {
      this.dealStageKeyword = e;
      this.getDealStageList();
    },
    searchCityName(e) {
      this.cityKeyword = e;
      this.CityList();
    },
    closesub(e) {
      if (!e) {
        this.searchObj.subsector_id = "";
      }
    },
    onIndustryChange(selectedIndustryId) {
      if (selectedIndustryId) {
        this.subsectorList = [];
        this.isShowSubsector = false;
        this.getSubsectorList(selectedIndustryId);
      } else {
        this.isShowSubsector = false;
      }
    },
    industrylist() {
      this.$api
        .getAPI({
          _action: "/industry/list",
        })
        .then((res) => {
          this.industryList = res;
        })
        .catch(() => {});
    },
    getSubsectorList(id) {
      this.$api
        .getAPI({
          _action: "/subsectors/list/" + id,
        })
        .then((res) => {
          this.subsectorList = res.list;

          // Only show subsector dropdown if there are subsectors(of industry) available
          if (this.subsectorList.length > 0) {
            this.isShowSubsector = true;
          } else {
            this.isShowSubsector = false;
          }
        })
        .catch(() => {
          this.isShowSubsector = false;
        });
    },
    getDealStageList() {
      this.$api
        .getAPI({
          _action: "/deal/stage/list",
        })
        .then((res) => {
          this.dealStageList = res;
        })
        .catch(() => {});
    },
    CityList() {
      var obj = {};
      if (this.cityKeyword) {
        obj.keyword = this.cityKeyword;
      }
      this.$api
        .getAPI({
          _action: "/country/list",
          _body: obj,
        })
        .then((res) => {
          this.cityList = res;
        })
        .catch(() => {});
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: #f0f0f0;
  height: 35px;
  border: 1px solid;
}

.multiselect-tag {
  background: #9cc338 !important;
}
</style>
