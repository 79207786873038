<template>
  <main role="main">
    <div id="main-wraper">
      <Header  />
      <div class="page-wrapper">
        <div class="container mt-md-5 mt-4">
          <div class="row">
            <div class="col-lg-12 col-sm-12 static-page mb-3">
                <div class="mb-4 text-center">
                <!-- <a
                  href="javascript:void(0)"
                  class="font-22 float-start text-dark"
                   @click="$router.go(-1)"
                  ><i class="bi bi-arrow-left"></i
                ></a> -->
                <h2 class="font-22 font-medium">{{ detail.title }}</h2>
              </div>
              
              <!-- <div v-html="detail.description"></div> -->
              <h2 class="title-h2 text-center">Team</h2>
               <div class="row mt-3">
                <div class="col-lg-3 col-sm-3">
                  <img src="/img/anubhav_mishra.png" alt="Profile" class="img-fluid" />
                </div>
                <div class="col-lg-9 col-sm-9">
                   <h3> Anubhav Mishra</h3>
                   <div class="mb-3"><em>Founder</em></div>
                   <p>Anubhav is the idea behind ExecLibrary. After 20+ years of experience in research desks of executive search firms and financial services companies, he felt the need of a platform that can create quick, short and compelling executive and company profiles for decision makers. He leads the product and go-to-market strategy for ExecLibrary. Anubhav founded AM Research in 2012, A business and corporate information company that offers research assistance to small and large corporations across the globe. He sets the client strategy, business development and P&L management for AM Research & ExecLibrary. </p>
               </div>
                  <div class="col-lg-12 col-sm-12">
                    <p>He is a thorough knowledge management professional specializing in research and analytic projects for all major industry sectors (Technology, Financial Services, Human Resources, Life Sciences, Oil & Energy, Consumer Retail, Automotive, Marketing & Advertising). He is an experienced associate within the executive search industry and has worked in the research domain for one of the top five retained executive search firms in the globe. Anubhav researches a lot on the start-up funding environment by connecting with founders of innovative and high growth technology start-ups as well as venture capitalists and angel investors. Anubhav also remains active in the executive search space and tracks executive moves and human resources trends in almost all geographies. He is a resourceful professional in sourcing passive candidates, identifying market trends and other factors affecting the recruitment of top level executives. Trusting his experience in the outsourcing and off-shoring industry, start-up founders, boutique executive search firms and investors also consult him for their research, analytics and data needs.</p>
                    <p>Anubhav holds a Bachelor's Degree in Business Administration and a Post Graduate Degree in Advertising & Mass Communication.</p>
                   <p>Anubhav has been featured as Top 10 Emerging Consultants by the Consultant Review magazine.</p>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-lg-4 col-sm-4">
                    <div class="research-team" style="background: #cabdbd;">
                    <div class="sm-titles" style="justify-content: center;display: flex;">Research Team</div>
                    <div>There is a solid team of 100+ researchers that use a blend of both human efforts and high end technology to produce actionable insights for our customers.</div>
                   </div>
                  </div>
                  <div class="col-lg-4 col-sm-4">
                    <div class="product-team" style="background: #cabdbd;">
                    <div class="sm-titles" style="justify-content: center;display: flex;">Product & Engineering Team</div>
                    <div>The ExecLibrary product and engineering teams are a bunch of high energy go getters that keep working hard to design, innovat e and develop information solutions for industry leaders and key decision makers.</div>
                   </div>
                  </div>
                  <div class="col-lg-4 col-sm-4">
                    <div class="account-mange" style="background: #cabdbd;">
                    <div class="sm-titles" style="justify-content: center;display: flex;">Account Management & Business Development</div>
                    <div>ExecLibrary is supported with an experienced team of Account Managers that ensure high quality customer relationship and client satisfaction.</div>
                   </div>
                  </div>
                </div>
            </div>
        </div>
      </div>
      </div>
      <Footer  />
    </div>
  </main>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
    name : "PrivacyPolicy",
    components:{
        Header,
        Footer
    },
    data() {
        return{
            detail:{},
             mobile: this.$route.query.mobile
        }
    },
    mounted() {
        // this.getDetail();
    },
    methods:{
        getDetail() {
            this.$api
             .getAPI({
          _action: "/cms/privacy",
        })
        .then((res) => {
          this.detail = res;
        })
        .catch(() => {});
        }
    }

}
</script>