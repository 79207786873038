<template>
  <div class="wrapper">
    <Header />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "Main",
  components: {
    Header,
    Footer,
  },
  methods: {},
};
</script>