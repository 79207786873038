<template>
  <div>
    <section class="ftco-section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6 text-center mb-5">
            <a href="#"><img src="/img/logo.png" alt="" /></a>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-7 col-lg-5">
            <div class="login-wrap p-4 pt-4">
              <div class="posAbs mt-3">
                <router-link to="/forgot-password">
                  <a style="color: #000"><i class="fa fa-arrow-left"></i></a>
                </router-link>
              </div>
              <div class="form-body">
                <div class="mt-3 col-md-12 col-sm-12 text-center">
                  <b>Please Enter the OTP sent On</b>
                  <div>
                    <div class="pt-1 text-center" style="color: #DE4A38;">
                      <a>{{ email }}</a>
                    </div>
                  </div>
                  <div class="text-center my-4">
                    <img
                      src="/img/ENTER_OTP.png"
                      alt=""
                      width="100"
                      height="100"
                    />
                  </div>
                  <Form @submit="verifyOtp()">
                    <div class="text-center mb-4 digit-group">
                      <Field
                        id="codeBox1"
                        style="color: black"
                        type="text"
                        name="otp1"
                        class="otp-control"
                        maxlength="1"
                        rules="required"
                        v-on:keyup="onKeyUpEvent(1, $event)"
                        v-on:focus="onFocusEvent(1)"
                      />
                      <Field
                        id="codeBox2"
                        type="text"
                        style="color: black"
                        name="otp2"
                        maxlength="1"
                        rules="required"
                        v-on:keyup="onKeyUpEvent(2, $event)"
                        v-on:focus="onFocusEvent(2)"
                      />
                      <Field
                        id="codeBox3"
                        style="color: black"
                        type="text"
                        name="otp3"
                        maxlength="1"
                        rules="required"
                        v-on:keyup="onKeyUpEvent(3, $event)"
                        v-on:focus="onFocusEvent(3)"
                      />
                      <Field
                        id="codeBox4"
                        type="text"
                        style="color: black"
                        name="otp4"
                        class="otp-control"
                        maxlength="1"
                        rules="required:otp"
                        v-on:keyup="onKeyUpEvent(4, $event)"
                        v-on:focus="onFocusEvent(4)"
                      />
                    </div>
                    <div class="text-center">
                      <ErrorMessage name="otp4" class="text-danger" />
                    </div>
                    <div class="mt-2 col-md-6 col-sm-6 mx-auto">
                      <button class="submit-btn" id="verify-otp-button">
                        Submit
                      </button>
                    </div>
                    <div class="col-mg-12 col-sm-12 text-center">
                      <p class="mb-0 mt-3">
                        <a
                          style="cursor: pointer; color: #de4a38"
                          id="resend-button"
                          v-on:click="resend()"
                          >Resend OTP</a
                        >
                      </p>
                      <hr />
                      <p class="mb-0 mt-3">
                        <a href="#termsconditionsModal" data-toggle="modal"
                          ><input type="checkbox" class="" id="" /> Accept Terms
                          and Conditions
                        </a>
                      </p>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "OTP",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      email: localStorage.getItem("email"),
    };
  },
  mounted() {},
  methods: {
    getCodeBoxElement(index) {
      return document.getElementById("codeBox" + index);
    },
    onKeyUpEvent(index, event) {
      const eventCode = event.which || event.keyCode;
      if (this.getCodeBoxElement(index).value.length === 1) {
        if (index !== 4) {
          this.getCodeBoxElement(index + 1).focus();
        } else {
          this.getCodeBoxElement(index).blur();
        }
      }
      if (eventCode === 8 && index !== 1) {
        this.getCodeBoxElement(index - 1).focus();
      }
    },
    onFocusEvent(index) {
      for (var item = 1; item < index; item++) {
        const currentElement = this.getCodeBoxElement(item);
        if (!currentElement.value) {
          currentElement.focus();
          break;
        }
      }
    },
    verifyOtp() {
      var obj = {};
      obj.email = localStorage.getItem("email");
      obj.otp =
        document.getElementById("codeBox1").value +
        "" +
        document.getElementById("codeBox2").value +
        "" +
        document.getElementById("codeBox3").value +
        "" +
        document.getElementById("codeBox4").value;
      this.$api
        .putAPI({
          _action: "/verify/otp",
          _body: obj,
          _buttonId: "verify-otp-button",
        })
        .then(() => {
          localStorage.setItem("token", obj.otp);
          this.$router.push("/reset-password");
        })
        .catch(() => {});
    },
    resend() {
      var obj = {};
      obj.email = localStorage.getItem("email");
      this.$api
        .postAPI({
          _action: "/resend/otp",
          _body: obj,
          _buttonId: "resend-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
        })
        .catch(() => {});
    },
  },
};
</script>