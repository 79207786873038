<template>
  <div v-show="isShowModal">
    <div class="spx-modal h-100 justify-content-center">
      <div
        class="spx-modal-body mx-auto"
        style="
          max-width: 2000px !important;
          width: 1055px !important;
          height: 560px;
          overflow-y: scroll;
        "
      >
        <div class="modal-header pt-0">
          <h5 class="modal-title">Send Email</h5>
          <button type="button" class="close" v-on:click="closeModal">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container">
            <Form @submit="onClick()" class="email-form">
              <label for="to">To:</label>
              <Field
                type="text"
                name="email"
                class="form-control"
                placeholder="Enter recipient's email address"
                v-model="detail.executive_email_work"
                rules="required:email|email"
                :validateOnInput="true"
              />
              <ErrorMessage name="email" class="text-danger" />
              <label for="cc">CC:</label>

              <div class="tag-input">
                <div
                  v-for="(tag, index) in tags"
                  :key="tag"
                  class="tag-input__tag"
                >
                  <span @click="removeTag(index)">
                    <i class="fa fa-times" aria-hidden="true"></i>
                  </span>
                  {{ tag }}
                </div>
                <Field
                  type="text"
                  name="email-new"
                  placeholder="Enter CC email address"
                  v-model="detail.cc_email"
                  class="tag-input__text form-control"
                  v-on:keydown.enter="addTag($event)"
                  v-on:keydown.delete="removeLastTag($event)"
                />
              </div>
              <label for="subject">Subject:</label>
              <Field
                type="text"
                name="Subject"
                placeholder="Enter Subject"
                v-model="detail.subject"
                class="form-control"
              />
              <label for="message">Message:</label>
              <!-- <Editor
                api-key="no-api-key"
                v-model="detail.message"
                :init="{
                  plugins: 'lists link image table code help wordcount',
                }"
              /> -->
              <!-- <button type="submit">Send</button> -->

              <div class="col-lg-12 col-sm-12 text-center mt-2">
                <button
                  style="width: 100px"
                  id="save-button"
                  class="fill-btn px-4 ml-3"
                >
                  Send
                </button>
                <button
                  style="width: 100px"
                  type="button"
                  v-on:click="closeModal()"
                  class="delete-btn px-4 ml-3"
                  id="cancel-button"
                >
                  Cancel
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
    <div class="spx-modal-background"></div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
// import Editor from "@tinymce/tinymce-vue";
export default {
  name: "EmailModal",
  components: {
    Form,
    Field,
    ErrorMessage,
    // Editor,
  },
  data() {
    return {
      isShowModal: false,
      detail: {},
      tags: [],
    };
  },
  mounted() {},
  methods: {
    showModal(obj) {
      this.detail = obj;
      this.isShowModal = true;
    },
    closeModal() {
      this.isShowModal = false;
    },

    addTag(event) {
      event.preventDefault();
      var val = event.target.value.trim();
      if (val.length > 0) {
        if (
          val &&
          !/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/.test(val)
        ) {
          this.$notify({
            type: "error",
            text: "Please enter valid email",
          });
          return;
        } else {
          this.tags.push(val);
          this.detail.cc_email = "";
        }
      }
    },
    removeTag(index) {
      this.tags.splice(index, 1);
    },
    removeLastTag(event) {
      if (event.target.value.length === 0) {
        this.removeTag(this.tags.length - 1);
      }
    },
    onClick() {
      this.detail.cc_email = this.tags;
      console.log("this.detail", this.detail);
      this.$api
        .postAPI({
          _action: "/send-mail-executive",
          _body: this.detail,
          _buttonId: "save-button",
        })
        .then((res) => {
          if (res) {
            this.$emit("get-list");
            this.closeModal();
            this.$notify({
              type: "success",
              text: res.message,
            });
          }
        })
        .catch(() => {});
    },
  },
};
</script>
<style>
label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
}
button {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.tag-input {
  width: 100%;
  border: 1px solid #eee;
  font-size: 0.9em;
  /* height: 40px; */
  box-sizing: border-box;
  padding: 0 5px;
  min-height: 5px !important;
}

.tag-input__tag {
  height: 27px;
  float: left;
  margin-right: 10px;
  background-color: #eee;
  margin-top: 6px;
  line-height: 27px;
  padding: 0px 10px;
  border-radius: 5px;
}

.tag-input__tag > span {
  cursor: pointer;
  opacity: 0.75;
}

.tag-input__text {
  border: none !important;
  outline: none;
  font-size: 0.9em;
  line-height: 35px;
  background: none;
  width: 30% !important;
}
</style>
