<template>
  <div>
    <section role="main" class="empty">
      <div class="container">
        <div class="row icon-tabs">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <router-link to="/executive" class="nav-link active"
                ><img src="/img/icons/executives.png" alt="Executives" />
                Executives</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/company" class="nav-link"
                ><img src="/img/icons/companies.png" alt="Companies" />
                Companies</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/news" class="nav-link"
                ><img src="/img/icons/deals.png" alt="News" /> News</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/deals-transactions" class="nav-link"
                ><img src="/img/icons/deals.png" alt="Deals & Transactions" />
                Deals & Transactions</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/listing" class="nav-link"
                ><img src="/img/book.png" style="height: 23px" />
                Listing</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/commentaries" class="nav-link"
                ><img src="/img/icons/commentaries.png" alt="Commentaries" />
                Commentaries</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/interesting-bites" class="nav-link"
                ><img
                  src="/img/icons/interesting.png"
                  alt="Interesting Bites"
                />
                Interesting Bites</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/open-positons-new" class="nav-link"
                ><img src="/img/icons/position.png" alt="Open Position" /> Open
                Positons</router-link
              >
            </li>
            <li class="">
              <a
                class="nav-link"
                style="
                  font-weight: bold !important;
                  margin-top: 8px;
                  font-size: 13px;
                  color: #1f3342;
                "
              >
                {{ count }}</a
              >
            </li>
          </ul>
          <div class="tab-content mb-3">
            <div>
              <div class="container px-0">
                <div class="row">
                  <div class="col-lg-12 col-sm-12">
                    <Field
                      type="text"
                      class="search-query"
                      v-on:keyup.enter="getList(0)"
                      placeholder="Search"
                      v-model="searchObj.keyword"
                      style="border: 0px none; width: 100%"
                    />
                  </div>
                </div>
                <hr class="mt-0 mb-2" />
              </div>
              <div class="row">
                <div class="col-lg-2 col-sm-4 col-6">
                  <label class="label">Current Company</label>
                  <Multiselect
                    mode="single"
                    trackBy="company_name"
                    label="company_name"
                    valueProp="id"
                    closeOnSelect="true"
                    placeholder="Select"
                    v-model="searchObj.new_company_id"
                    :options="companyList"
                    searchable="true"
                    @search-change="searchCompanyName($event)"
                  />
                </div>
                <div class="col-lg-2 col-sm-4 col-6">
                  <label class="label">Job Function</label>
                  <Multiselect
                    mode="single"
                    trackBy="job_function_name"
                    label="job_function_name"
                    valueProp="id"
                    v-model="searchObj.job_function_id_new"
                    :options="jobFunctionList"
                    searchable="true"
                    placeholder="Select"
                    @search-change="searchJobFunctionName($event)"
                  />
                </div>
                <div class="col-lg-2 col-sm-4 col-6">
                  <label class="label">Industry</label>

                  <Multiselect
                    mode="single"
                    trackBy="industry_name"
                    label="industry_name"
                    valueProp="id"
                    v-model="searchObj.industry_id_new"
                    :options="industryList"
                    searchable="true"
                    placeholder="Select"
                    @change="closesub($event)"
                    @input="onIndustryChange"
                    @search-change="searchIndustryName($event)"
                  />
                </div>
                <div v-if="isShowSubsector" class="col-lg-2 col-sm-4 col-6">
                  <label class="label">Subsector</label>

                  <Multiselect
                    mode="single"
                    trackBy="industry_name"
                    label="industry_name"
                    valueProp="id"
                    v-model="searchObj.subsector_id"
                    :options="subsectorList"
                    searchable="true"
                    placeholder="Select"
                  />
                </div>
                <div class="col-lg-2 col-sm-4 col-6">
                  <label class="label">Location</label>
                  <Multiselect
                    mode="single"
                    trackBy="country_name"
                    label="country_name"
                    valueProp="id"
                    v-model="searchObj.country_id"
                    :options="cityList"
                    searchable="true"
                    placeholder="Select"
                    @search-change="searchCityName($event)"
                  />
                </div>
                <div class="col-lg-2 col-sm-4 col-6" v-show="!isShowSubsector">
                  <label class="label">Gender</label>
                  <Multiselect
                    mode="single"
                    trackBy="value"
                    label="view"
                    valueProp="value"
                    v-model="searchObj.gender"
                    :options="genders"
                    searchable="true"
                    placeholder="Select"
                    @search-change="searchGenderName($event)"
                  />
                  <!-- <select v-model="searchObj.gender" class="select">
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select> -->
                </div>
                <div class="col-lg-2 col-sm-4 col-6">
                  <!-- <label></label> -->
                  <button
                    v-on:click="getList(0)"
                    type="button"
                    style="margin-top: 23px; width: 50%"
                    class="apply-btn mr-1"
                  >
                    Apply
                  </button>
                  <button
                    v-on:click="resetFilter()"
                    type="button"
                    style="margin-top: 23px; width: 40%"
                    class="apply-btn"
                  >
                    Reset
                  </button>
                  <div class="text-center">
                    <a
                      style="cursor: pointer"
                      v-on:click="getAdvanceSearch()"
                      class="advance-search"
                      >{{
                        is_show ? "Hide Advance Search" : "Advance Search"
                      }}</a
                    >
                  </div>
                </div>
              </div>
              <!-- <br /> -->
              <div v-show="is_show" class="row advasearch-data mt-1">
                <div class="col-lg-2 col-sm-4 col-6" v-show="isShowSubsector">
                  <label class="label">Gender</label>
                  <Multiselect
                    mode="single"
                    trackBy="value"
                    label="view"
                    valueProp="value"
                    v-model="searchObj.gender"
                    :options="genders"
                    searchable="true"
                    placeholder="Select"
                    @search-change="searchGenderName($event)"
                  />
                  <!-- <select v-model="searchObj.gender" class="select">
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select> -->
                </div>
                <div class="col-lg-2 col-sm-4 col-6">
                  <label class="label">Education</label>
                  <Multiselect
                    mode="single"
                    trackBy="education_name"
                    label="education_name"
                    valueProp="id"
                    v-model="searchObj.education_id"
                    :options="educationList"
                    searchable="true"
                    placeholder="Select"
                    @search-change="searchEducationName($event)"
                  />
                </div>
                <div class="col-lg-2 col-sm-4 col-6">
                  <label class="label">University</label>
                  <Multiselect
                    mode="single"
                    trackBy="name"
                    label="name"
                    valueProp="id"
                    v-model="searchObj.education_institutre_name"
                    :options="universityList"
                    searchable="true"
                    placeholder="Select"
                  />
                </div>
                <div class="col-lg-2 col-sm-4 col-6">
                  <label class="label">Age Range</label>
                  <Multiselect
                    mode="single"
                    trackBy="age_range_name"
                    label="age_range_name"
                    valueProp="id"
                    placeholder="Select"
                    v-model="searchObj.age_range_id"
                    :options="ageRangeList"
                    searchable="true"
                    @search-change="searchAgeRangeName($event)"
                  />
                </div>
                <div class="col-lg-2 col-sm-4 col-6">
                  <label class="label">Past Company</label>
                  <Multiselect
                    mode="single"
                    trackBy="company_name"
                    label="company_name"
                    valueProp="id"
                    placeholder="Select"
                    v-model="searchObj.prev_company_id"
                    :options="companyList"
                    searchable="true"
                    @search-change="searchCompanyName($event)"
                  />
                </div>
                <div class="col-lg-2 col-sm-4 col-6">
                  <label class="label">Seniority Level</label>
                  <Multiselect
                    mode="single"
                    trackBy="seniority_name"
                    label="seniority_name"
                    valueProp="id"
                    placeholder="Select"
                    v-model="searchObj.seniority_id"
                    :options="seniorityList"
                    searchable="true"
                    @search-change="searchSeniorityName($event)"
                  />
                </div>
                <div class="col-lg-2 col-sm-4 col-6" :class="isShowSubsector ? 'mt-3' : ''">
                  <label class="label">Region (Current)</label>
                  <Multiselect
                    mode="single"
                    trackBy="region_name"
                    label="region_name"
                    valueProp="id"
                    placeholder="Select"
                    v-model="searchObj.region_id_new"
                    :options="regionList"
                    searchable="true"
                    @search-change="searchRegionName($event)"
                  />
                </div>
                <div class="col-lg-2 col-sm-4 col-6 mt-3" >
                  <label class="label">Region (Past)</label>
                  <Multiselect
                    mode="single"
                    trackBy="region_name"
                    label="region_name"
                    valueProp="id"
                    placeholder="Select"
                    v-model="searchObj.region_id_prev"
                    :options="regionList"
                    searchable="true"
                    @search-change="searchRegionName($event)"
                  />
                </div>
                <div class="col-lg-2 col-sm-4 col-6 mt-3">
                  <label class="label">Company Type</label>
                  <Multiselect
                    mode="single"
                    trackBy="value"
                    label="value"
                    valueProp="id"
                    v-model="searchObj.company_type"
                    :options="company_types"
                    searchable="true"
                    placeholder="Select"
                  />
                </div>
                <div class="col-lg-2 col-sm-4 col-6 mt-3">
                  <label class="label">Company Size</label>
                  <Multiselect
                    mode="single"
                    trackBy="value"
                    label="value"
                    valueProp="id"
                    v-model="searchObj.company_size"
                    :options="company_sizes"
                    searchable="true"
                    placeholder="Select"
                  />
                </div>
                <div class="col-lg-2 col-sm-4 col-6 mt-3">
                  <label class="label">Deal Stage</label>
                  <Multiselect
                    mode="single"
                    trackBy="name"
                    label="name"
                    valueProp="id"
                    v-model="searchObj.deal_stage_id"
                    :options="dealStageList"
                    searchable="true"
                    placeholder="Select"
                  />
                </div>
                <div class="col-lg-2 col-sm-4 col-6 mt-3">
                  <label class="label">Year</label>
                  <Field
                    type="text"
                    name="year"
                    class="form-control"
                    placeholder="year"
                    v-model="searchObj.curr_year"
                    v-slot="{ field }"
                  >
                    <Datepicker
                      autoApply
                      placeholder="year"
                      v-bind="field"
                      v-model="searchObj.curr_year"
                      yearPicker
                    />
                  </Field>
                </div>
              </div>
              <hr />
              <div class="col-12 row mt-4">
                <div
                  class="col-lg-4 col-sm-4"
                  v-for="(data, index) in list"
                  :key="index"
                >
                  <div class="rectangle-box">
                    <div class="row" style="height: 115px">
                      <div class="col-lg-3 col-sm-3 col-1 pr-2 text-center">
                        <a>
                          <img
                            v-if="data.executive_logo || !data.gender"
                            :src="
                              data.executive_logo
                                ? data.executive_logo
                                : '/img/user-pic.png'
                            "
                            alt=""
                            class="round"
                          />
                          <img
                            v-if="data.gender == 'male' && !data.executive_logo"
                            :src="
                              data.executive_logo
                                ? data.executive_logo
                                : '/img/male.png'
                            "
                            alt=""
                            class="round"
                          />
                          <img
                            v-if="
                              data.gender == 'female' && !data.executive_logo
                            "
                            :src="
                              data.executive_logo
                                ? data.executive_logo
                                : '/img/female.png'
                            "
                            alt=""
                            class="round"
                          />
                        </a>
                        <br />
                        <a
                          class=""
                          style="margin-left: -6px"
                          :title="data.country ? data.country.country_name : ''"
                        >
                          <country-flag
                            :country="
                              data.country ? data.country.country_code : '-'
                            "
                          />
                        </a>
                      </div>
                      <div
                        class="col-lg-7 col-sm-7 col-9 px-2"
                        style="max-height: 120px"
                      >
                        <a
                          style="cursor: pointer"
                          v-on:click="goToView('/executive-detail/' + data.id)"
                        >
                          <div
                            style="display: initial !important"
                            :title="
                              data.executive_name ? data.executive_name : '-'
                            "
                            class="client-name"
                          >
                            {{
                              data.executive_name ? data.executive_name : "-"
                            }}
                          </div>
                          <div
                            :title="
                              data.new_company
                                ? data.new_company.company_name
                                : '-'
                            "
                            class="company-name"
                          >
                            <img
                              :src="
                                data.new_company
                                  ? data.new_company.company_logo
                                  : '/img/user-pic.png'
                              "
                              style="border-radius: 9px"
                              alt=""
                              width="18"
                              height="18"
                            />
                            {{
                              data.new_company
                                ? data.new_company.company_name
                                : "-"
                            }}
                          </div>
                          <div
                            :title="
                              $helperService.isStringOnlyAlphabet(
                                data.new_job_title_id
                              )
                                ? data.new_job_title_id
                                : data.new_job_title
                                ? data.new_job_title.title
                                : ''
                            "
                            class="designation"
                          >
                            <span>{{
                              $helperService.isStringOnlyAlphabet(
                                data.new_job_title_id
                              )
                                ? data.new_job_title_id
                                : data.new_job_title
                                ? data.new_job_title.title
                                : "-"
                            }}</span>
                          </div>
                        </a>
                        <div class="mt-2">
                          <a
                            :title="
                              data.country != null && data.country.phone_code
                                ? '+' +
                                  data.country.phone_code +
                                  data.executive_phone
                                : ''
                            "
                            target="__blank"
                            :href="
                              'https://api.whatsapp.com/send?phone=' +
                              (data.country != null && data.country.phone_code
                                ? data.country.phone_code + data.executive_phone
                                : '')
                            "
                            style="cursor: pointer"
                            class="mr-1"
                            ><img
                              src="/img/icons/phone.png"
                              height="25"
                              width="25"
                              alt="Linkedin"
                          /></a>
                          <a
                            :href="'mailto:' + data.executive_email_work"
                            :title="data.executive_email_work"
                            class="mr-1"
                            style="cursor: pointer"
                            ><img
                              src="/img/icons/mail.png"
                              height="25"
                              width="25"
                          /></a>
                          <a
                            v-if="data.linkedin_url"
                            v-on:click="openLink(data.linkedin_url)"
                            :title="data.linkedin_url"
                            style="cursor: pointer"
                            class="mr-1"
                            ><img
                              style="border-radius: 15px"
                              src="/img/linkedin-color.png"
                              height="25"
                              width="25"
                              alt="Linkedin"
                          /></a>
                          <a
                            v-if="data.twitter_url"
                            v-on:click="openLink(data.twitter_url)"
                            style="cursor: pointer"
                            :title="data.twitter_url"
                            ><img
                              src="/img/twitter.png"
                              height="25"
                              width="25"
                              alt="Twitter"
                          /></a>
                        </div>
                      </div>
                      <div class="col-lg-2 col-sm-2 col-2 px-2 text-center">
                        <input
                          type="checkbox"
                          v-on:click="addToFavorite(data)"
                          :checked="data.is_fav == 1"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12 col-sm-12">
                      <span class="update-date"
                        >Last Updated :
                        {{
                          $helperService.getFormatedDate(data.updated_on)
                        }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <ErrorComponent @retry="getList(0)" ref="errorComponent" />
              <div class="row mx-0 mb-4">
                <div class="col-lg-12 mt-5 text-center">
                  <div class="page-bottom-pagination text-right">
                    <Pagination
                      @page-change="pageChange"
                      @items-per-page-change="itemsPerPageChange"
                      ref="listPagination"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import CountryFlag from "vue-country-flag-next";
import ErrorComponent from "@/components/ErrorComponent";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import Pagination from "@/components/Pagination";
import Multiselect from "@vueform/multiselect";
import { Field } from "vee-validate";
export default {
  name: "Executive",
  components: {
    ErrorComponent,
    Pagination,
    Multiselect,
    Field,
    CountryFlag,
    Datepicker,
  },
  data() {
    return {
      id: this.$route.params.id,
      executive_id: this.$route.params.executive_id,
      type: this.$route.params.type,
      count: 0,
      loader: true,
      isShowSubsector: false,
      company_types: [
        "Public",
        "Private",
        "Education",
        "Government",
        "Not-for-Profit",
      ],
      genders: [
        {
          value: "male",
          view: "Male",
        },
        { value: "female", view: "Female" },
      ],
      searchObj: {
        gender: "",
        keyword: "",
        industry_id_new: "",
        company_size: "",
        new_company_id: "",
        education_institutre_name: "",
        prev_company_id: "",
        job_function_id_new: "",
        seniority_id: "",
        company_type: "",
        country_id: "",
        education_id: "",
        age_range_id: "",
        region_id_prev: "",
        region_id_new: "",
        deal_stage_id: "",
        subsector_id: "",
      },
      offset: -1,
      list: [],
      company_sizes: [
        "1-10",
        "11-50",
        "51-200",
        "201-500",
        "501-1000",
        "1001-5000",
        "5001-10000",
        "10000+",
      ],
      dealStageList: [],
      universityList: [],
      cityList: [],
      company_ids: [],
      city_ids: [],
      industryList: [],
      companyList: [],
      jobFunctionList: [],
      educationList: [],
      seniorityList: [],
      ageRangeList: [],
      regionList: [],
      subsectorList: [],
      cityKeyword: "",
      companyKeyword: "",
      jobFunctionKeyword: "",
      educationKeyword: "",
      industryKeyword: "",
      searchGenderKeyword: "",
      ageRangeKeyword: "",
      regionKeyword: "",
      subsectorKeyword: "",
      is_show: false,
    };
  },
  mounted() {
    if (this.id || this.executive_id || this.type) {
      this.$shareService.getMapValueAndRemove("executive_page_filter");
    }
    if (this.id) {
      this.getExecutiveDetail(this.id);
    } else {
      this.getList(0);
      this.companylist();
    }
    this.industrylist();
    this.CityList();
    this.jobFunctionlist();
    this.getEducationlist();
    this.getAgeRangeList();
    this.getRegionlist();
    this.getSenioritylist();
    this.getUniversitylist();
    this.getDealStageList();
  },
  methods: {
    resetFilter() {
      this.searchObj = {};
      this.searchObj.gender = "";
      this.searchObj.education_institutre_name = "";
      this.searchObj.keyword = "";
      this.searchObj.industry_id_new = "";
      this.searchObj.new_company_id = "";
      this.searchObj.prev_company_id = "";
      this.searchObj.job_function_id_new = "";
      this.searchObj.seniority_id = "";
      this.searchObj.company_type = "";
      this.searchObj.city_id = "";
      this.searchObj.education_id = "";
      this.searchObj.company_size = "";
      this.searchObj.age_range_id = "";
      this.searchObj.region_id_new = "";
      this.searchObj.region_id_prev = "";
      this.searchObj.deal_stage_id = "";
      this.searchObj.subsector_id = "";
      this.isShowSubsector = false;
      this.getList(0);
    },
    getAdvanceSearch() {
      this.is_show = !this.is_show;
    },
    addToFavorite(data) {
      var obj = {};
      obj.executive_id = data.id;
      this.$api
        .putAPI({
          _action: "/add/executive/fav",
          _body: obj,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          return (data.is_fav = data.is_fav == 1 ? 0 : 1);
          // this.getList(0);data.is_fav
          // this.$router.push("/executive");
        })
        .catch(() => {});
    },
    openLink(url) {
      window.open(url);
    },
    searchGenderName(e) {
      this.searchGenderKeyword = e;
      this.genders;
    },
    searchJobFunctionName(e) {
      this.jobFunctionKeyword = e;
      this.jobFunctionlist();
    },
    searchIndustryName(e) {
      this.industryKeyword = e;
      this.industrylist();
    },
    onIndustryChange(selectedIndustryId) {
      if (selectedIndustryId) {
        this.subsectorList = [];
        this.isShowSubsector = false;
        this.getSubsectorList(selectedIndustryId);
      }else{
        this.isShowSubsector= false;
      }
      
    },
    searchSeniorityName(e) {
      this.seniorityKeyword = e;
      this.getSenioritylist();
    },
    searchEducationName(e) {
      this.educationKeyword = e;
      this.getEducationlist();
    },
    searchCompanyName(e) {
      this.companyKeyword = e;
      this.companylist();
    },
    searchCityName(e) {
      this.cityKeyword = e;
      this.CityList();
    },
    searchRegionName(e) {
      this.regionKeyword = e;
      this.getRegionlist();
    },
    searchAgeRangeName(e) {
      this.ageRangeKeyword = e;
      this.getAgeRangeList();
    },
    getAgeRangeList() {
      var obj = {};
      if (this.ageRangeKeyword) {
        obj.keyword = this.ageRangeKeyword;
      }
      this.$api
        .getAPI({
          _action: "/age/range/list",
          _body: obj,
        })
        .then((res) => {
          this.ageRangeList = res;
        })
        .catch(() => {});
    },
    getRegionlist() {
      var obj = {};
      if (this.regionKeyword) {
        obj.keyword = this.regionKeyword;
      }
      this.$api
        .getAPI({
          _action: "/region/list",
          _body: obj,
        })
        .then((res) => {
          this.regionList = res;
        })
        .catch(() => {});
    },
    jobFunctionlist() {
      var obj = {};
      if (this.jobFunctionKeyword) {
        obj.keyword = this.jobFunctionKeyword;
      }
      this.$api
        .getAPI({
          _action: "/job/function/list",
        })
        .then((res) => {
          this.jobFunctionList = res;
        })
        .catch(() => {});
    },
    getSenioritylist() {
      var obj = {};
      if (this.seniorityKeyword) {
        obj.keyword = this.seniorityKeyword;
      }
      this.$api
        .getAPI({
          _action: "/seniority/list",
          _body: obj,
        })
        .then((res) => {
          this.seniorityList = res;
        })
        .catch(() => {});
    },
    getEducationlist() {
      var obj = {};
      if (this.educationKeyword) {
        obj.keyword = this.educationKeyword;
      }
      this.$api
        .getAPI({
          _action: "/education/list",
          _body: obj,
        })
        .then((res) => {
          this.educationList = res;
        })
        .catch(() => {});
    },
    getUniversitylist() {
      var obj = {};
      if (this.educationKeyword) {
        obj.keyword = this.educationKeyword;
      }
      this.$api
        .getAPI({
          _action: "/university/list",
          _body: obj,
        })
        .then((res) => {
          this.universityList = res;
        })
        .catch(() => {});
    },
    closesub(e) {
    if(!e){
      this.searchObj.subsector_id = '';
    }
    },
    getDealStageList() {
      var obj = {};
      if (this.dealStageKeyword) {
        obj.keyword = this.dealStageKeyword;
      }
      this.$api
        .getAPI({
          _action: "/deal/stage/list",
          _body: obj,
        })
        .then((res) => {
          this.dealStageList = res;
        })
        .catch(() => {});
    },
    companylist() {
      var obj = {};
      if (this.companyKeyword) {
        obj.keyword = this.companyKeyword;
      }
      if (this.company_ids) {
        obj.company_ids = this.company_ids;
      }
      this.$api
        .getAPI({
          _action: "/company/list",
          _body: obj,
        })
        .then((res) => {
          this.company_ids = [];
          this.companyList = res;
        })
        .catch(() => {});
    },

    industrylist() {
      this.$api
        .getAPI({
          _action: "/industry/list",
        })
        .then((res) => {
          this.industryList = res;
        })
        .catch(() => {});
    },
    getSubsectorList(id) {
      this.$api
        .getAPI({
          _action: "/subsectors/list/" + id,
        })
        .then((res) => {
          this.subsectorList = res.list;

          // Only show subsector dropdown if there are subsectors(of industry) available
          if (this.subsectorList.length > 0) {
            this.isShowSubsector = true;
            this.is_show = true;
          } else {
            this.isShowSubsector = false;
          }
        })
        .catch(() => {
          this.isShowSubsector = false;
        });
    },
    CityList() {
      var obj = {};
      if (this.cityKeyword) {
        obj.keyword = this.cityKeyword;
      }
      if ((this.city_ids, length > 0)) {
        obj.city_ids = this.city_ids;
      }
      this.$api
        .getAPI({
          _action: "/country/list",
          _body: obj,
        })
        .then((res) => {
          this.cityList = res;
        })
        .catch(() => {});
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getExecutiveDetail(id) {
      if (id) {
        if (this.type == "PAST") {
          this.is_show = true;
          this.searchObj.prev_company_id = id;
        }
        if (this.type == "CURRENT") {
          this.is_show = true;
          this.searchObj.new_company_id = id;
        } else if (!this.type) {
          this.$api
            .getAPI({
              _action: "/executive/detail/" + id,
            })
            .then((res) => {
              if (res.new_company_id) {
                this.searchObj.job_function_id_new = res.job_function_id_new;
                this.searchObj.industry_id_new = res.industry_id_new;
                this.searchObj.seniority_id = res.seniority_id;
                this.searchObj.country_id = res.country_id;
                this.is_show = !this.is_show;
                if (res.new_company) {
                  this.searchObj.company_type = res.new_company.company_type;
                }
              }
              this.getList(0);
            })
            .catch(() => {});
        }
        this.company_ids.push(id);
        this.companylist();
        if (this.type) {
          this.getList(0);
        }
      }
    },
    goToView(url) {
      this.searchObj.is_show = this.is_show;
      this.$shareService.setMapValue("executive_page_filter", this.searchObj);
      this.$router.push(url);
    },
    getList(page, type) {
      this.$refs.errorComponent.updateListLoader(true);
      this.list = [];

      if (this.type == "PAST") {
        this.searchObj.prev_company_id = this.id;
      } else if (this.type == "CURRENT") {
        // this.searchObj.new_company_id = this.id;
      }
      if (!this.searchObj.gender) {
        this.searchObj.gender = "";
      }
      if (!this.searchObj.keyword) {
        this.searchObj.keyword = "";
      }
      if (!this.searchObj.company_size) {
        this.searchObj.company_size = "";
      }
      if (!this.searchObj.industry_id_new) {
        this.searchObj.industry_id_new = "";
      }
      if (!this.searchObj.new_company_id) {
        this.searchObj.new_company_id = "";
      }
      if (!this.searchObj.prev_company_id) {
        this.searchObj.prev_company_id = "";
      }
      if (!this.searchObj.job_function_id_new) {
        this.searchObj.job_function_id_new = "";
      }
      if (!this.searchObj.education_institutre_name) {
        this.searchObj.education_institutre_name = "";
      }
      if (!this.searchObj.seniority_id) {
        this.searchObj.seniority_id = "";
      }
      if (!this.searchObj.company_type) {
        this.searchObj.company_type = "";
      }
      if (!this.searchObj.city_id) {
        this.searchObj.city_id = "";
      }
      if (!this.searchObj.education_id) {
        this.searchObj.education_id = "";
      }
      if (!this.searchObj.age_range_id) {
        this.searchObj.age_range_id = "";
      }
      if (!this.searchObj.region_id_prev) {
        this.searchObj.region_id_prev = "";
      }
      if (!this.searchObj.region_id_new) {
        this.searchObj.region_id_new = "";
      }
      if (!this.searchObj.company_type) {
        this.searchObj.company_type = "";
      }
      if (this.id && !this.type) {
        this.searchObj.id = this.id;
        this.searchObj.executive_id = this.id;
      }
      if (this.offset != page) {
        this.searchObj.offset = page;
      } else {
        this.searchObj.offset = page;
      }
      if (type) {
        this.searchObj.list_type = type;
      }
      var filter_obj = this.$shareService.getMapValue("executive_page_filter")
        ? this.$shareService.getMapValue("executive_page_filter")
        : null;
      if (filter_obj) {
        this.searchObj = filter_obj;
        this.is_show = this.searchObj.is_show;
        this.$shareService.getMapValueAndRemove("executive_page_filter");
        if (this.searchObj.new_company_id) {
          this.company_ids.push(this.searchObj.new_company_id);
        }
        if (this.searchObj.prev_company_id) {
          this.company_ids.push(this.searchObj.prev_company_id);
        }
        this.companylist();
      }

      this.$api
        .getAPI({
          _action: "/executive/pagination/list",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.count = res.count;
          this.offset = res.next_offset;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
                this.$refs.listPagination.setCurrentPage(
                  this.searchObj.offset + 1
                );
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(
              res,
              "No executive are available"
            );
            this.$refs.errorComponent.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: #f0f0f0;
  height: 35px;
  border: 1px solid;
}
</style>
