<template>
  <main role="main">
    <div id="main-wraper">
      <Header  />
      <div class="page-wrapper">
        <div class="container mt-md-5 mt-4">
          <div class="row">
            <div class="col-lg-12 col-sm-12 static-page mb-3">
                <div class="mb-4 text-center">
                <!-- <a
                  href="javascript:void(0)"
                  class="font-22 float-start text-dark"
                   @click="$router.go(-1)"
                  ><i class="bi bi-arrow-left"></i
                ></a> -->
                <h2 class="font-22 font-medium">{{ detail.title }}</h2>
              </div>
              
              <!-- <div v-html="detail.description"></div> -->
              <h2 class="title-h2 text-center">Privacy Policy</h2>
              <p>ExecLibrary Privacy Policy - ExecLibrary understands that you care about how information about you is used. This privacy policy explains how we collect information pertaining to businesses and business people ("Business Information") and all other types of information through our website and online services (the "Site & App"); how we maintain, use and share that information; and how you can manage the way information about you is handled.</p>   
              <h4>Where and how does ExecLibrary get the Business Information for its Public Profiles?</h4>
               <p>ExecLibrary creates profiles of business people and companies, which we call "Public Profiles," from different sources. Once we have collected Business Information about a person or company, we combine multiple mentions of the same person or company into a Public Profile. The resulting directory of Public Profiles (the "Directory") is then made available to the users of the Site & App and our customers and strategic partners.</p>
               <p>ExecLibrary obtains the data for its Public Profiles through it's search technology and a team of desk researchers who scan the web and gather publicly-available Information.</p>
               <p>We may also collect personal contact information in this fashion about persons outside of Europe but will only use it for matching (identification purposes) and do not publish or otherwise make it available to others.</p>
               <h4>What business information does ExecLibrary collect?</h4>
               <p>We collect the following Business Information, if available, for each person:</p>
               <p>
               <ul class="list">
                <li>Name</li>
                <li>Email address</li>
                <li>Job title and department</li>
                <li>Executive biography</li>
                <li>Business phone numbers (general, direct and fax)</li>
                <li>Company name</li>
                <li>Postal address of company</li>
                <li>Business related postal address of person</li>
                <li>Corporate website URLs</li>
                <li>Social Networking URLs</li>
               </ul>
              </p>
              <h4>How else does ExecLibrary Collect and Use Information?</h4>
              <p>Visitors to our Site & App may choose to submit their name, email address and/or other information so that they can learn more about our services, register to take part in a ExecLibrary-sponsored event, or participate in a survey, contest or sweepstakes, among other things.</p>
              <p>In order to use certain ExecLibrary products and services, you may be required to register as a user. From time to time, we may use your email address to send you information and keep you informed of products and services in which you might be interested. You will always be provided with an opportunity to opt out of receiving such emails. Your contact information may also be used to reach you regarding issues concerning your use of our Site/App, including changes to this privacy policy.</p>
              <p>ExecLibrary may aggregate collected information about our users in a form that does not allow users to be personally identified for the purpose of understanding our customer base and enhancing the services that we and our strategic partners and customers can provide you.</p>
              <h4>Payment Information</h4>
              <p>Users purchase our online subscription-based services through the PayPal payment platform and ExecLibrary does not store any of your credit card or personal details.</p>
              <p>ExecLibrary will use personal information only in ways that are compatible with the purposes for which it was collected or subsequently authorized by the individual about whom the information pertains. ExecLibrary will take reasonable steps to ensure that personal information is relevant to its intended use, accurate, complete and current.</p>
              <h4>How Do We Keep Your Information Secure?</h4>
              <p>The security of your information is important to us. When you enter sensitive information on our registration forms, we encrypt that information using secure socket layer technology (SSL).</p>
              <p>We follow generally accepted industry standards to protect the information submitted to us, both during transmission and once we receive it. However, no method of Internet transmission or electronic storage is 100% secure. Therefore, while we strive to use commercially acceptable means to protect your information, we cannot guarantee its absolute security.</p>
              <h4>Links to Other Sites</h4>
              <p>This Site contains links to other sites that are not owned or controlled by ExecLibrary. We are not responsible for the privacy practices of such other sites. We encourage when you leave our Site to be aware and to read the privacy statements of each and every website that collects personally identifiable information.</p>
              <h4>Social Media Widgets</h4>
              <p>Our website includes Social Media Features, such as the Share and Like features and Widgets, or interactive mini-programs that run on our site. These features may collect your IP address, which page you are visiting on our site, and may set a cookie to enable the feature to function properly. Social Media Features and Widgets are either hosted by a third party or hosted directly on our Site. Your interactions with these Features are governed by the privacy policy of the company providing it.</p>
              <h4>Information for Users in Europe and the U.S.</h4>
              <p>If you use our Site or App outside of India, you understand that we may collect, process, and store your personal information in India and other countries. The laws in India regarding personal information may be different from the laws of your state or country. Any such transfers will comply with safeguards as required by relevant law.</p>
              <h4>Changes to this Policy</h4>
              <p>ExecLibrary reserves the right to modify this privacy policy from time to time, so please review it regularly. If we make material changes to this policy, we will notify you here, by email, and/or by means of a notice on our homepage prior to the changes becoming effective.</p>
              <h4>Contact Us</h4>
              <p><a href="mailto:privacy@execlibrary.com">privacy@execlibrary.com</a></p>
            </div>
        </div>
      </div>
      </div>
      <Footer  />
    </div>
  </main>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
    name : "PrivacyPolicy",
    components:{
        Header,
        Footer
    },
    data() {
        return{
            detail:{},
             mobile: this.$route.query.mobile
        }
    },
    mounted() {
        // this.getDetail();
    },
    methods:{
        getDetail() {
            this.$api
             .getAPI({
          _action: "/cms/privacy",
        })
        .then((res) => {
          this.detail = res;
        })
        .catch(() => {});
        }
    }

}
</script>