<template>
  <div>
    <section role="main" class="empty">
      <div class="container">
        <div class="row icon-tabs">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <router-link to="/executive" class="nav-link"
                ><img src="/img/icons/executives.png" alt="Executives" />
                Executives</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/company" class="nav-link active"
                ><img src="/img/icons/companies.png" alt="Companies" />
                Companies</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/news" class="nav-link"
                ><img src="/img/icons/deals.png" alt="News" /> News</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/deals-transactions" class="nav-link"
                ><img src="/img/icons/deals.png" alt="Deals & Transactions" />
                Deals & Transactions</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/commentaries" class="nav-link"
                ><img src="/img/icons/commentaries.png" alt="Commentaries" />
                Commentaries</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/interesting-bites" class="nav-link"
                ><img
                  src="/img/icons/interesting.png"
                  alt="Interesting Bites"
                />
                Interesting Bites</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/open-positons" class="nav-link"
                ><img src="/img/icons/position.png" alt="Open Position" /> Open
                Positons</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/listing" class="nav-link"
                ><img src="/img/book.png" style="height: 23px" />
                Listing</router-link
              >
            </li>
            <li class="">
              <a
                class="nav-link"
                style="
                  font-weight: bold !important;
                  margin-top: 8px;
                  font-size: 13px;
                  color: #1f3342;
                "
              >
                {{ count }}</a
              >
            </li>
          </ul>
          <div class="tab-content mb-3">
            <div>
              <div class="col-12 row">
                <div class="col-2">
                  <img
                    :title="
                      detail.executive_logo && detail.publication.name
                        ? detail.publication.name
                        : ''
                    "
                    :src="
                      detail.company_logo ? detail.company_logo : '/img/new.png'
                    "
                    alt=""
                    height="100"
                    width="100"
                  />
                </div>
                <div class="col-10 text-start">
                  <h3>{{ detail.company_name }} - Organization Structure</h3>
                </div>
              </div>
              <hr />
              <div class="col-12 row mt-4">
                <div
                  class="col-4 white-box-view row mr-3"
                  v-for="(data, index) in list"
                  :key="index"
                >
                  <div
                    class="col-4 text-center"
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                    "
                  >
                    <img
                      v-on:click="$router.push('/executive-detail/' + data.id)"
                      :title="data.executive_name ? data.executive_name : ''"
                      :src="
                        data.executive_logo
                          ? data.executive_logo
                          : data.gender == 'male'
                          ? '/img/male.png'
                          : data.gender == 'female'
                          ? '/img/female.png'
                          : '/img/user-pic.png'
                      "
                      alt=""
                      style="cursor: pointer"
                      class="sm-img mt-3"
                    />
                    <span
                      :title="
                        $helperService.isStringOnlyAlphabet(
                          data.new_job_title_id
                        )
                          ? data.new_job_title_id
                          : data.new_job_title
                          ? data.new_job_title.title
                          : ''
                      "
                      style="font-size: 11px; margin-top: 13px; cursor: pointer;width: 110% !important;"
                      >{{
                        $helperService.isStringOnlyAlphabet(
                          data.new_job_title_id
                        )
                          ? data.new_job_title_id
                          : data.new_job_title
                          ? data.new_job_title.title
                          : ""
                      }}</span
                    >
                  </div>
                  <label style="font-size: 11px; margin-top: 13px"
                    >Reports To</label
                  >
                  <div
                    class="col-4 text-center"
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                    "
                  >
                    <img
                      v-on:click="
                        $router.push('/executive-detail/' + data.report_to.id)
                      "
                      :title="
                        data.report_to && data.report_to.executive_name
                          ? data.report_to.executive_name
                          : ''
                      "
                      :src="
                        data.report_to && data.report_to.executive_logo
                          ? data.report_to.executive_logo
                          : data.report_to && data.report_to.gender == 'male'
                          ? '/img/male.png'
                          : data.report_to && data.report_to.gender == 'female'
                          ? '/img/female.png'
                          : '/img/user-pic.png'
                      "
                      alt=""
                      height="100"
                      width="100"
                      style="cursor: pointer"
                      class="sm-img mt-3"
                    />
                    <span
                      :title="
                        data.report_to && data.report_to.new_job_title_id
                          ? $helperService.isStringOnlyAlphabet(
                              data.report_to.new_job_title_id
                            )
                            ? data.report_to.new_job_title_id
                            : data.report_to.new_job_title.title
                          : ''
                      "
                     style="font-size: 11px; margin-top: 13px; cursor: pointer;width: 110% !important;"
                      >{{
                        data.report_to && data.report_to.new_job_title_id
                          ? $helperService.isStringOnlyAlphabet(
                              data.report_to.new_job_title_id
                            )
                            ? data.report_to.new_job_title_id
                            : data.report_to.new_job_title.title
                          : ""
                      }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <ErrorComponent @retry="getList(0)" ref="errorComponent" />
            <div class="row mx-0 mb-4">
              <div class="col-lg-12 mt-5 text-center">
                <div class="page-bottom-pagination text-right">
                  <Pagination
                    @page-change="pageChange"
                    @items-per-page-change="itemsPerPageChange"
                    ref="listPagination"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
export default {
  name: "Executive",
  components: {
    ErrorComponent,
    Pagination,
  },
  data() {
    return {
      id: this.$route.params.id,
      count:0,
      loader: true,
      offset: -1,
      searchObj: {},
      detail: {
        type: "",
      },
      list: [],
    };
  },
  mounted() {
    if (this.id) {
      this.getdetail(this.id);
    }
  },
  methods: {
    getdetail(id) {
      this.$api
        .getAPI({
          _action: "/company/detail/" + id,
        })
        .then((res) => {
          this.detail = res;
          this.getList(0);
        })
        .catch(() => {});
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page) {
      this.$refs.errorComponent.updateListLoader(true);
      this.list = [];
      this.searchObj.offset = page;
      if (this.detail.type) {
        this.searchObj.type = this.detail.type;
      }
      // this.searchObj.limit = 1;
      this.$api
        .getAPI({
          _action: "/company/organization/structure/pagination/list/" + this.id,
          _body: this.searchObj,
        })
        .then((res) => {
          this.list = res.list;
          this.offset = res.next_offset;
          this.count = res.count;
          this.loader = false;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No Data Found");
            this.$refs.errorComponent.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
  },
};
</script>