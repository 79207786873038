<template>
  <div>
    <section class="ftco-section">
      <div class="container">
        <div class="row justify-content-center"></div>
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="login-wrap p-4 pt-4">
              <h3 class="title-h3 text-center pt-0" style="font-size: 28px">
                My Profile
              </h3>
              <Form @submit="save()" class="login-form">
                <div class="col-12 row">
                  <div
                    class="col-12 mt-3 mb-3 row"
                    style="text-align: center; display: block ruby"
                  >
                    <ImagePicker
                      :src="detail.profile_photo"
                      :modelValue="detail.profile_photo"
                      ref="ImagePicker"
                    />
                  </div>
                  <div class="col-6 form-group mb-3">
                    <Field
                      type="text"
                      name="first_name"
                      class="form-control"
                      placeholder="First Name"
                      v-model="detail.first_name"
                      rules="required:first name"
                      :validateOnInput="true"
                    />
                    <ErrorMessage name="first_name" class="text-danger" />
                  </div>
                  <div class="col-6 form-group mb-3">
                    <Field
                      type="text"
                      name="last_name"
                      class="form-control"
                      placeholder="Last Name"
                      v-model="detail.last_name"
                      rules="required:last name"
                      :validateOnInput="true"
                    />
                    <ErrorMessage name="last_name" class="text-danger" />
                  </div>
                  <div class="col-6 form-group mb-3">
                    <Field
                      type="text"
                      name="email"
                      class="form-control"
                      placeholder="Email"
                      v-model="detail.email"
                      rules="required:email|email"
                      :validateOnInput="true"
                    />
                    <ErrorMessage name="email" class="text-danger" />
                  </div>
                  <div class="col-6 form-group mb-3">
                    <Field
                      type="text"
                      name="contact"
                      class="form-control"
                      placeholder="Contact"
                      v-model="detail.contact"
                      rules="required:contact"
                      :validateOnInput="true"
                    />
                    <ErrorMessage name="contact" class="text-danger" />
                  </div>
                </div>
                <div
                  class="col-12 text-center"
                  style="align-content: center; display: block ruby"
                >
                  <div class="col-2">
                    <button
                      type="submit"
                      id="save-button"
                      class="form-control btn-primary rounded submit px-3"
                    >
                      Update &nbsp;<i class="fa fa-sign-in"></i>
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ImagePicker from "@/components/ImagePicker.vue";
export default {
  name: "Signup",
  components: {
    Form,
    Field,
    ErrorMessage,
    ImagePicker,
  },
  data() {
    return {
      detail: {},
    };
  },
  mounted() {
    this.getLoginUserDetail();
  },
  methods: {
    save() {
      this.$api
        .putAPI({
          _action: "/customer",
          _body: this.detail,
          _buttonId: "save-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          if (!this.$refs.ImagePicker.file) {
            // this.$router.go(-1);
          } else {
            this.uploadImage(this.detail.id);
          }
        })
        .catch(() => {});
    },
    uploadImage(id) {
      this.$api
        .uploadImageAPI({
          _action: "/customer/photo",
          _key: "image",
          _file: this.$refs.ImagePicker.file,
          _body: {
            id: id,
          },
        })
        .then(() => {
            document.getElementById("profile").src = this.$refs.ImagePicker.image.src;
          // this.$router.go(-1);
        });
    },
    getLoginUserDetail() {
      this.$api
        .getAPI({
          _action: "/me",
        })
        .then((res) => {
          if (res) {
            this.detail = res;
          }
        })
        .catch(() => {});
    },
  },
};
</script>