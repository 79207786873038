<template>
  <main role="main">
    <div id="main-wraper">
      <Header />
      <div class="page-wrapper">
        <div class="container mt-md-5 mt-4">
          <div class="row">
            <div class="col-lg-12 col-sm-12 static-page mb-3">
              <div class="mb-4 text-center">
                <!-- <a
                  href="javascript:void(0)"
                  class="font-22 float-start text-dark"
                  @click="$router.go(-1)"
                  ><i class="bi bi-arrow-left"></i
                ></a> -->
                <h2 class="font-22 font-medium">{{ detail.title }}</h2>
              </div>
              <h2 class="title-h2 text-center">ExecLibrary Terms of Service</h2>
              <p>These ExecLibrary Terms of Service (“Terms”) is mutually agreed legally binding agreement between you and ExecLibrary Private Limited (“Our” “Us” and “We”).</p>
              <p>These Terms governs your access and use of Our Platform which shall mean and include the website (<a href="https://execlibrary.com/">www.execlibrary.com</a>), mobile applications, generated reports (including export downloads and API usage), queries answered personally by Our team over email, phone or any other medium and any other service features, integrations, tools and resources, allied or additional services including all data contained therein (hereinafter referred to individually or collectively as “Platform”).</p>
              <p>Our Privacy Policy covers how we collect and use your information. Our Privacy Policy is a part of these Terms and is available here.</p>
              <p>BY USING THE PLATFORM, YOU AGREE TO THESE TERMS AND OUR PRIVACY POLICY. IF YOU DO NOT AGREE, PLEASE DO NOT USE THE PLATFORM.</p>
              <p>If you access or use the Platform on behalf of a company, an association/group or any other legal entity (“Organization”), then you must have an authority/permission to accept and legally bind the Organization to these Terms.</p>
              <p>You represent that your accepting these Terms does not violate any applicable law or the terms and conditions of any existing agreements, understandings, representations, arrangement, etc., whether written or oral, entered by you with any third party.</p>
              <p>We reserve your right of access and use of the Platform.</p>
              <h4>Your account</h4>
              <p>To use the Platform you have to register with Us.</p>
              <p>Your account is only for use by you and you are responsible for your conduct. You will always use a strong password to protect your account and keep it confidential. You will not share your account access on the Platform with others or let anybody impersonate you using your access credentials. You take responsibility that your account will not be used in anyway which contradicts any applicable law or the Terms mentioned herein. We have a right but not an obligation to review your conduct to ensure compliance with these Terms. If there is a misuse of your account; you will immediately report to Us.</p>
              <p>We may take necessary steps including but not limited to terminating your access to the Platform if we believe that you are in breach of the Terms mentioned herein or your failure to comply with any applicable law.</p>
              <h4>Your responsibilities</h4>
              <p>Your responsibilities while using the Platform will be as follows –</p>
              <p>You will not copy, reproduce, modify or disassemble the Platform, in whole or in part, to create any derivative work, or in any way use any part of the Platform, except as expressly permitted herein.</p>
              <p>You will not commercially exploit the Platform, in any manner, that shall constitute a business loss to Us or generate any third-party revenue.</p>
              <p>You will not permit unauthorized access to the Platform and you shall notify Us in the event you become aware of any actual or potential unauthorized access to the Platform or any attempt to disrupt the integrity of the Platform.</p>
              <p>You will not use the Platform for any illegal, immoral, offensive purpose or for any other purpose that may infringe or violate any applicable laws and regulations.</p>
              <p>You will not interfere, disrupt or hamper with the proper functioning of the Platform.</p>
              <p>Your usage of data from the Platform shall be fair and reasonable and such that it shall not be in a form where a third party can use it as a substitute for purchasing Our services.</p>
              <p>You will not sell, publish, refurbish, transmit, exhibit, communicate or disclose the data in the Platform, in any form or by any means to any person/third party.</p>
              <h4>Link to other sites</h4>
              <p>The Platform may contain links to other sites and may be governed by respective terms of use not covered herein. Accessing or using these sites shall be at your discretion and We shall bear no liability whatsoever.</p>
              <h4>Use of Web Crawlers and Automated methods</h4>
              <p>Any use or attempt to use any web crawlers, robot, spider, computer code or any other device, program and methodology to continuously and automatically search, scrape, extract, deep-link, index and/or disrupt the working of the Platform and/or download large amount of data is not permitted unless expressly permitted through API’s by Us.</p>
              <h4>Platform “As Is” and Disclaimer</h4>
              <p>The Platform is provided on an “As Is” basis without warranties of any kind. We make no representation or warranty, either expresses or implied, regarding the adequacy, completeness, usefulness and accuracy of the Platform. You shall exercise your discretion while using the Platform or the content therein.</p>
              <p>Nothing contained in the Platform shall constitute or should be construed as a solicitation, offer or recommendation by Us.</p>
              <p>We accept no liability for any errors or omissions, with respect to any information provided to you whether on behalf of itself or third parties.</p>
              <p>The Platform is for educational and information purposes only and not intended to provide any financial, investment or legal advice. You shall seek the help of a professional in pursuing such advice. Any article, publication, statement, report, information, data or content available on the Platform or on any link to the Platform will not be sought as an advice or opinion from Us.</p>
              <h4>Our proprietary rights</h4>
              <p>We reserve all rights, title and interest in and to the Platform, including all related intellectual property rights.</p>
              <p>These Terms grants no implied license, right or interest in or to any copyright, patent, trade secret, trademark, URL, domain, invention or other intellectual property right of Ours.</p>
              <p>You shall not remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Platform.</p>
              <p>You agree to abide by all applicable intellectual property laws, as well as any additional restrictions contained in the Platform.</p>
              <p>All present and future rights in and to any trade secrets, copyright, trademark or any other proprietary material contained in the Platform shall continue to remain the sole property of Ours, even after the discontinuation of your account for any reason whatsoever.</p>
              <h4>NO BUYING OR SELLING SECURITIES</h4>
              <p>THE PLATFORM IS NOT FOR BUYING OR SELLING ANY SECURITIES. DO NOT ENGAGE IN SUCH ACTIVITIES USING THE PLATFORM.YOU WILL INDEMNIFY US AND HOLD US HARMLESS FROM ANY AND ALL CLAIMS AND LIABILITY IF YOU ENGAGE OR USE ANY PART OF THE PLATFORM FOR SUCH ACTIVITIES.</p>
              <h4>Termination and Survival</h4>
              <p>These Terms will apply to you so long as you use the Platform.</p>
              <p>These Terms shall continue, unless terminated by you via written notice or email. You will inform Us in writing if you wish to terminate these Terms.</p>
              <p>Following the termination of these Terms you will pay Us any outstanding amount or fee owed by you.</p>
              <p>We may terminate this Agreement by giving 15 (fifteen) days prior written notice, or earlier if the termination is due to breach of any Terms or applicable law; or required by law to do so</p>
              <p>The termination of these Terms shall not relieve you of any liability that you may have incurred or may incur in relation to use of the Platform provided prior to such termination.</p>
              <p>On termination of these Terms, We may delete any content or other materials relating to your use of the Platform and We will have no liability to you or any third party for doing so.</p>
              <p>All provisions of these Terms including but not limited to (Your responsibilities), (Platform ‘As Is’), (Our proprietary rights), (Payment Terms) and (Miscellaneous clauses) that, either expressly or impliedly, contain obligations that extend beyond termination of these Terms shall survive the termination of these Terms</p>
              <h4>Payment Terms</h4>
              <p>If you subscribe to our paid services You shall pay Us all applicable Fees as has been communicated to you, using Our selected payment method within 5 (Five) days of receipt of Our invoice.</p>
              <p>The Fees communicated to you is exclusive of any applicable taxes. All applicable taxes shall be borne by you. You will withhold any applicable taxes if required by law to do so and shall provide us with an official tax certificate or any other relevant document.</p>
              <p>Any change in fee structure shall be informed to you in advance.</p>
              <p>You will ensure that</p>
              <p>All billing and contact information provided is true and correct;</p>
              <p>No applicable law shall be violated in making the payment; and</p>
              <p>No payment shall be made through third party accounts.</p>
              <p>Subscription fee is non-refundable other than in case where wrong payments have been done or mandated by law. If We terminate the agreement, you will be refunded on pro-rata basis.</p>
              <p>Any refunds to be made will be made through the same medium as originally received from.</p>
              <p>Non-compliance of these Payment Terms shall lead to the suspension of your account with Us.</p>
              <h4>Confidentiality</h4>
              <p>The parties acknowledge that they shall receive information belonging to the other party that can be reasonably construed to be confidential and privileged information not intended to be disclosed to any third parties or other party, unless it needs to be disclosed for the fulfillment of these Terms. The parties agree to use reasonable standards of care to keep such information confidential. Nothing contained herein shall affect the ability of the parties to make disclosures mandated by applicable laws.</p>
              <h4>Indirect Damages</h4>
              <p>In addition, under no circumstances will We be liable under these Terms for any incidental, exemplary, special, consequential, indirect or punitive damages, including but not limited to, damages for loss of revenue, Goodwill or any anticipated profits/losses.</p>
              <h4>Miscellaneous</h4>
              <p>Waiver - No forbearance, inaction or delay by Us in exercising any right under these Terms shall be construed as a waiver or acquiescence of that right.</p>
              <p><b>Severability -</b> If any provision of these Terms is found invalid or unenforceable to any extent, the remaining terms of these Terms shall not be affected thereby and remain in full force and effect to the maximum extent permitted by law.</p>
              <p><b>Modifications -</b> These Terms may be revised from time to time by Us in order to improve the Platform or in conformity with regulatory requirements. By continuing to use Our Platform after we have revised these Terms, you agree to be bound by the revised Terms.</p>
              <p><b>Availability -</b> We shall not be held responsible for non-availability of the Platform during periodic maintenance operations or any unplanned suspension of access to the Platform that may occur due to any reason beyond Our control.</p>
              <p>Use of Our Trademark and Logo - The trademarks, logos and service marks displayed on the Platform are Our property. You are not permitted to use the trademarks, logos and service marks without Our prior written consent or of the respective third party.</p>
              <p><b>Force Majeure -</b> Neither parties will be liable for any inadequate performance of their obligations under these Terms, for reasons beyond their control (i.e., War, Government legislations, accidents, floods, natural disasters etc.)</p>
              <p><b>Governing Law & Jurisdiction -</b> These Terms shall be governed by and construed in accordance with the laws of India and the courts of Udaipur, Rajasthan shall have exclusive jurisdiction to any disputes, claims and actions, whether contractual or non-contractual, arising out of or in connection to these Terms</p>
            </div>
          </div>
        </div>
      </div>
      <Footer  />
    </div>
  </main>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "TermsAndConditions",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      detail: {},
      mobile: this.$route.query.mobile,
    };
  },
  mounted() {
    // this.getDetail();
  },
  methods: {
    getDetail() {
      this.$api
        .getAPI({
          _action: "/cms/terms",
        })
        .then((res) => {
          this.detail = res;
        })
        .catch(() => {});
    },
  },
};
</script>