<template>
  <main role="main">
    <div id="main-wraper">
      <Header  />
      <div class="page-wrapper">
        <div class="container mt-md-5 mt-4">
          <div class="row">
            <div class="col-lg-12 col-sm-12 static-page mb-3">
                <div class="mb-4 text-center">
                <!-- <a
                  href="javascript:void(0)"
                  class="font-22 float-start text-dark"
                   @click="$router.go(-1)"
                  ><i class="bi bi-arrow-left"></i
                ></a> -->
                <h2 class="font-22 font-medium">{{ detail.title }}</h2>
              </div>
              
              <!-- <div v-html="detail.description"></div> -->
              <h2 class="title-h2 text-center">General Data Protection Regulation (GDPR)</h2>
              <p>The EU General Data Protection Regulation (GDPR) aims to protect and empower all EU citizen's data privacy and provides a framework for processing Personal Information and requires additional steps from organizations to ensure that the rights related to privacy of the applicable data subjects are respected and enforced.</p>
              <p>We at ExecLibrary welcome and fully commit ourselves in complying with requirements of the GDPR and have made changes to our product, services and policies accordingly. Additionally, we will continue to monitor the evolvements of the industry best practices and keep incorporating as applicable. ExecLibrary being a global product may not necessarily differentiate between other geographies and EU and we are sure our customers would also appreciate our uniformity of the services about being in compliance.</p>
              <h4>Regarding your Personal Information</h4>
              <p>If in case you may want to reach out to us for any matter related to your Personal Information, you may please drop us an email at <a href="mailto:privacy@execlibrary.com">privacy@execlibrary.com</a> and we shall ensure that your requests and preferences are honored under the GDPR.</p>
              <p>Since we research publicly announced executive moves and profile interesting executives and companies around the globe; any Personal Information that may be processed is primarily because of you being associated with those companies and is processed on attributes with respect to your involvement with that company. So, if you are a founder, an investor, a board member or a key person in any of those companies, your information is a material aspect of your company and your publicly available information may be processed to that extent.</p>
              <p>The information processed is on lawful basis and only if there is a legitimate interest for processing of such information by us or by our customers, without compromising on any of your rights or interest.</p>
              <p>If we are required under an applicable law to disclose your information, we shall do so.</p>
              <p>As long your association with a company is relevant in a meaningful way your information might be with us and may be made available to our subscribers / potential subscriber.</p>
              <p>For your information - we do not serve advertisements to our customers, nor we have any advertisement revenue directly or indirectly.</p>
              <p>Still we strongly suggest that you please let us know what you think about how we use your Personal Information in case you come across an instance of your Personal Information being processed. You can do so by dropping us an email at <a href="mailto:privacy@execlibrary.com">privacy@execlibrary.com</a>. Please note that we will not entertain any request from a third party for your Personal Information on your behalf.</p>
              <p>For your convenience, we have listed down the rights available to you under GDPR.</p>
              <ol>
              <li>1. Right to Access - You can request us to get a copy of your personal information at Execlibrary, without any charge we will provide you the details and relevant information around it.</li>
              <li>2. Right to amend / correct - If in case you want to us to update or change any information which may not be correct, please let us know. We may update, append or completely remove such information in a way that deems fit to us.</li>	
              <li>3. Right for erasure or restriction of processing - If in case you want us to delete your personal information being processed at ExecLibrary or want us to stop processing that information due to the information (i) as per you is not correct (ii) is not lawfully processed (iii) or if you think it is not required by us. Please let us know and we shall delete / stop processing your Personal Information as per your request.</li>
              <li>4. Data portability - If you need we will provide your Personal Information, available with us in a commonly used machine-readable format.</li>
              <li>5. Notification in case of breach - If in case, despite of our best efforts, we suffer a data breach which in our opinion may put your Personal Information at risk, we shall notify you and relevant supervisory authority within 72 hours of us becoming aware of such breach.</li>
              </ol>
              <p>Since we gather information available through various publicly available sources through the world wide web, in some cases it may happen that the information requested for removal is unintentionally re-captured. If this happens, please let us know to enable us to take appropriate steps.</p>
              <p>We suggest you also go through our <a href="privacy">Privacy Policy</a> for more information.</p>
              <p>For any additional queries. Please write to us at: <a href="mailto:privacy@execlibrary.com">privacy@execlibrary.com</a></p>
            </div>
        </div>
      </div>
      </div>
      <Footer  />
    </div>
  </main>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
    name : "PrivacyPolicy",
    components:{
        Header,
        Footer
    },
    data() {
        return{
            detail:{},
             mobile: this.$route.query.mobile
        }
    },
    mounted() {
        // this.getDetail();
    },
    methods:{
        getDetail() {
            this.$api
             .getAPI({
          _action: "/cms/privacy",
        })
        .then((res) => {
          this.detail = res;
        })
        .catch(() => {});
        }
    }

}
</script>