import { createWebHistory, createRouter } from "vue-router";
import Login from "@/views/Onboarding/login.vue";
import Signup from "@/views/Onboarding/signup.vue";
import ForgotPassword from "@/views/Onboarding/forgot-password.vue";
import OTP from "@/views/Onboarding/otp.vue";
import ResetPassword from "@/views/Onboarding/reset-password.vue";
import AboutUs from "@/views/Index/about-us.vue";
import Dashboard from "@/views/Dashboard/dashboard.vue";
import Executive from "@/views/Executive/executive.vue";
import ExecutiveDetail from "@/views/Executive/executive-detail.vue";
import Company from "@/views/Company/company.vue";
import CompanyDetail from "@/views/Company/company-detail.vue";
import Viewstructure from "@/views/Company/view-structure.vue";
import News from "@/views/News/news.vue";
import Commentaries from "@/views/Commentaries/commentaries.vue";
import DealsTransaction from "@/views/Deals-Transactions/deals-transaction.vue";
import InterestingBites from "@/views/Interesting-Bites/interesting-bites.vue";
import Listing from "@/views/Listing/listing.vue";
import ViewListing from "@/views/Listing/view-listing.vue";
import Terms from "@/views/Terms-Condition/terms.vue";
import OpenPositions from "@/views/Open-Positions/open-positions.vue";
import NewOpenPositions from "@/views/Open-Positions/open-positions-new.vue";
import Terms2 from "@/views/Static-pages/termsAndConditions.vue";
import Favourites from "@/views/Favourites/favourites.vue";
import MyProfile from "@/views/My-Profile/my-profile.vue";
import Privacy from "@/views/Static-pages/privacyPolicy.vue";
import Gdpr from "@/views/Static-pages/GDPR.vue";
import Teams from "@/views/Static-pages/teams.vue";
import Pricing from "@/views/Static-pages/pricing.vue";
import MainView from "@/views/main.vue";

const routes = [{
        path: "",
        name: "Dashboard",
        component: Dashboard,
    },
    {
        path: "/",
        redirect: Dashboard,
    },
    {
        path: "/dashboard",
        redirect: Dashboard,
    },
    {
        path: "/gdpr",
        name: "GDPR",
        component: Gdpr,
    },
    {
        path: "/teams",
        name: "Teams",
        component: Teams,
    },
    {
        path: "/pricing",
        name: "Pricing",
        component: Pricing,
    },
    {
        path: "/about",
        name: "About Us",
        component: AboutUs,
    },
    {
        path: "/contact",
        name: "Contact Us",
        component: AboutUs,
    },
    {
        path: "/price",
        name: "Price",
        component: AboutUs,
    },
    {
        path: "/terms",
        name: "Terms",
        component: Terms2,
    },
    {
        path: "/privacy",
        name: "privacy",
        component: Privacy,
    },
    {
        path: "/signup",
        name: "Signup",
        component: Signup,
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
    },
    {
        path: "/otp",
        name: "OTP",
        component: OTP,
    },
    {
        path: "/reset-password",
        name: "ResetPassword",
        component: ResetPassword,
    },
    {
        path: "/terms/conditions",
        name: "Terms And Conditions",
        component: Terms,
    },
    {
        path: "",
        name: "Main",
        component: MainView,
        meta: {
            requiresAuth: true,
        },
        children: [
            //============== Executive ================//
            {
                path: "/executive",
                name: "Executive",
                component: Executive,
            },
            {
                path: "/company-executive/:type/:id",
                name: "Company Executive",
                component: Executive,
            },
            {
                path: "/similiar-executive/:id/:executive_id",
                name: "Similiar Executive",
                component: Executive,
            },
            {
                path: "/executive-detail/:id",
                name: "Executive Detail",
                component: ExecutiveDetail,
            },
            //============== Comapny ================//
            {
                path: "/company",
                name: "company",
                component: Company,
            },
            {
                path: "/similar-company/:id",
                name: "Similar company",
                component: Company,
            },
            {
                path: "/company-detail/:id",
                name: "Company Detail",
                component: CompanyDetail,
            },
            {
                path: "/view-structure/:id",
                name: "View structure",
                component: Viewstructure,
            },
            //============== News ================//
            {
                path: "/news",
                name: "News",
                component: News,
            },
            {
                path: "/similar-news/:id",
                name: "Similar News",
                component: News,
            },
            {
                path: "/executive-similar-news/:id/:type",
                name: "Executive Similar News",
                component: News,
            },
            //============== Favourites ================//
            {
                path: "/favourites",
                name: "Favourites",
                component: Favourites,
            },
            //============== MyProfile ================//
            {
                path: "/profile",
                name: "My-Profile",
                component: MyProfile,
            },
            //============== Commentaries ================//
            {
                path: "/commentaries",
                name: "Commentaries",
                component: Commentaries,
            },
            {
                path: "/similar-commentaries/:id",
                name: "Similar commentaries",
                component: Commentaries,
            },
            //============== Deals & Transaction ================//
            {
                path: "/deals-transactions",
                name: "Deals & Transaction",
                component: DealsTransaction,
            },
            {
                path: "/similar-deals-transactions/:id",
                name: "Similar Deals & Transaction",
                component: DealsTransaction,
            },
            //============== Interesting Bites ================//
            {
                path: "/similar-interesting-bites/:id",
                name: "similar-interesting-bites",
                component: InterestingBites,
            },
            {
                path: "/interesting-bites",
                name: "Interesting Bites",
                component: InterestingBites,
            },
            //============== Listing ================//
            {
                path: "/listing",
                name: "Listing",
                component: Listing,
            },
            {
                path: "/view-listing/:id",
                name: "View Listing",
                component: ViewListing,
            },
            //============== Open Positions================//
            {
                path: "/open-positons",
                name: "Open Positions",
                component: OpenPositions,
            },
            {
                path: "/open-positons-new",
                name: " New Open Positions",
                component: NewOpenPositions,
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (localStorage.getItem("token") == null) {
            next({
                path: "/dashboard",
                params: { nextUrl: to.fullPath },
            });
        } else {
            next();
        }
    } else {
        if (to.path === "/login") {
            if (localStorage.getItem("token") != null) {
                next({
                    path: "/executive",
                    params: { nextUrl: to.fullPath },
                });
            } else {
                next();
            }
        } else {
            next();
        }
    }
});

export default router;