<template>
  <div>
    <Header />
    <section role="main" class="empty">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-sm-3">
            <div class="sm-box text-center">
              <h5 class="calibri-bold">Executive Profile</h5>
              <div class="value calibri-bold">{{ detail.executive }}</div>
              <div class="month mt-2 calibri">
                <!-- {{ detail.executive_per_month }}+ Executive Profiles/Month -->
                *10K+ Executive Profiles/Month
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-3">
            <div class="sm-box text-center">
              <h5 class="calibri-bold">Executive Moves</h5>
              <div class="value calibri-bold">{{ detail.moves }}</div>
              <div class="month mt-2 calibri">
                <!-- {{ detail.moves_per_month }}+ Moves Tracked/Month -->
                *5K+ Moves Tracked/Month
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-3">
            <div class="sm-box text-center">
              <h5 class="calibri-bold">Company Profile</h5>
              <div class="value calibri-bold">{{ detail.comapny }}</div>
              <div class="month mt-2 calibri">
                <!-- {{ detail.comapny_per_month }}+ Company Profile/Month -->
                *2K+ Company Profile/Month
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-3">
            <div class="sm-box text-center">
              <h5 class="calibri-bold">Deals</h5>
              <div class="value calibri-bold">{{ detail.deals }}</div>
              <div class="month mt-2 calibri">
                <!-- {{ detail.deals_per_month }}+ Deals/Month -->
                *2K+ Deals/Month
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-3 col-sm-3">
            <div class="sm-box" style="height: 284px">
              <div class="graph-title calibri-bold" style="font-weight: bold">
                Executive Moves (YoY)
              </div>
              <canvas id="bar-chart" width="450" height="450" class="mt-3 ml-3">
              </canvas>
            </div>
          </div>
          <div class="col-lg-3 col-sm-3">
            <div class="sm-box" style="height: 284px">
              <div class="graph-title calibri-bold" style="font-weight: bold">
                Executive Moves / Day
              </div>
              <canvas
                id="bar-chart5"
                width="450"
                height="450"
                class="mt-3 ml-3"
              >
              </canvas>
            </div>
          </div>
          <div class="col-lg-3 col-sm-3">
            <div class="sm-box" style="height: 284px">
              <div class="graph-title calibri-bold" style="font-weight: bold">
                Executive Moves / Industry
              </div>
              <canvas
                id="bar-chart3"
                width="450"
                height="450"
                class="mt-3 ml-3"
              >
              </canvas>
            </div>
          </div>
          <div class="col-lg-3 col-sm-3">
            <div class="sm-box" style="height: 284px">
              <div class="graph-title calibri-bold" style="font-weight: bold">
                Company Profile
              </div>
              <canvas
                id="bar-chart4"
                width="450"
                height="400"
                class="mt-3 ml-3"
              >
              </canvas>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-4 col-sm-4">
            <div class="sm-box px-0" style="height: 350px">
              <div class="mid-title calibri-bold">Recent Appointments</div>
              <hr />
              <div
                class="d-flex px-2"
                style="height: 50px"
                v-for="(data, index) in recent_appoinment"
                :key="index"
              >
                  <div class="text-center pb-1 mb-3" v-if="index < 4">
                    <img
                      :src="
                        data.company_logo
                          ? data.company_logo
                          : '/img/company.png'
                      "
                      alt=""
                      style="
                        width: 50px;
                        height: 50px;
                        object-fit: contain;
                        object-position: 0 0;
                      "
                    />
                  </div>
                  <div class="sm-title pl-2 calibri" style="width: 420px" v-if="index < 4">
                    {{ $helperService.getText(data.news_title, 100) }}
                  </div>
                  <hr />
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-4">
            <div class="sm-box px-0" style="height: 350px">
              <div class="mid-title calibri-bold">Interesting Bites</div>
              <hr />
              <div
                class="d-flex px-2"
                style="height: 50px"
                v-for="(data, index) in intresting_bites"
                :key="index"
              >
                <div class="text-center pb-1 mb-3">
                  <img
                    :src="
                      data.company && data.company.company_logo
                        ? data.company.company_logo
                        : '/img/company.png'
                    "
                    alt=""
                    style="
                      width: 50px;
                      height: 50px;
                      object-fit: contain;
                      object-position: 0 0;
                    "
                  />
                </div>
                <div class="sm-title pl-2 calibri" style="width: 420px">
                  {{ $helperService.getText(data.bites, 100) }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-4">
            <div class="sm-box px-0" style="height: 350px">
              <div class="mid-title calibri-bold">Recent Commentary</div>
              <hr />
              <div
                class="d-flex px-2"
                style="height: 50px"
                v-for="(data, index) in commentary"
                :key="index"
              >
                <div class="text-center pb-3 mb-3">
                  <img
                    :title="
                      data.executive && data.executive.executive_name
                        ? data.executive.executive_name
                        : ''
                    "
                    :src="
                      data.executive && data.executive.executive_logo
                        ? data.executive.executive_logo
                        : data.executive && data.executive.gender == 'male'
                        ? '/img/male.png'
                        : data.executive && data.executive.gender == 'female'
                        ? '/img/female.png'
                        : '/img/user-pic.png'
                    "
                    alt=""
                    style="
                      width: 50px;
                      height: 50px;
                      object-fit: contain;
                      object-position: 0 0;
                    "
                  />
                </div>
                <div class="sm-title pl-2 calibri" style="width: 420px">
                  {{ $helperService.getText(data.comment, 100) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <p>&nbsp;</p>
    <ErrorComponent @retry="getDetail(0)" ref="errorComponent" />
    <Footer />
  </div>
</template>
<script>
import Chart from "chart.js/auto";
import Header from "@/components/Header";
import ErrorComponent from "@/components/ErrorComponent";
import Footer from "@/components/Footer";
export default {
  name: "Dashboard",
  components: {
    Header,
    ErrorComponent,
    Footer,
    // Form,
    // Field,
    // Multiselect,
  },
  data() {
    return {
      loader: false,
      myChart: undefined,
      detail: {},
      recent_appoinment: [],
      intresting_bites: [],
      commentary: [],
      ExecutiveMoves: [],
      ExecutiveMovesPerDay: [],
      ExecutivePerIndustry: [],
      CompanyProfilePerIndustry: [],
    };
  },
  mounted() {
    this.getDetail();
    this.getRecentAppointment();
    this.getIntrestingBites();
    this.getCommentary();
    this.getExecutiveMoveByYear();
    this.getExecutiveMoveByDay();
    this.getExecutiveMoveByIndustry();
    this.getCompanyProfilePerIndustry();
  },
  methods: {
    getExecutiveMoveByDay() {
      this.$api
        .getAPI({
          _action: "/executive/moves/day",
        })
        .then((res) => {
          this.ExecutiveMovesPerDay = res;
          this.getGraphExecutiveMoveByDay();
        })
        .catch(() => {});
    },
    getGraphExecutiveMoveByDay() {
      this.labelsArr = [];
      this.revanueArr = [];

      for (var i = 0; i < this.ExecutiveMovesPerDay.length; i++) {
        var month_name = this.$helperService.getText2(
          this.ExecutiveMovesPerDay[i].title.toUpperCase()
        );
        this.labelsArr.push(month_name);
        var revanue = this.ExecutiveMovesPerDay[i].count;
        this.revanueArr.push(revanue);
      }
      if (this.sales) {
        this.sales.destroy();
      }
      const ctx = document.getElementById("bar-chart5").getContext("2d");
      // var t = this;
      this.myChart = new Chart(ctx, {
        type: "line",
        data: {
          labels: this.labelsArr,
          datasets: [
            {
              data: this.revanueArr,
              backgroundColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
              ],
              borderColor: "#87CEEB",
              borderWidth: 1,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              grid: {
                display: true,
              },
              ticks: {
                display: false,
                // callback: function (label) {
                //   return t.$helperService.abbreviateNumber(label);
                // },
              },
            },
            x: {
              display: false,
            },
          },
        },
      });
    },

    getExecutiveMoveByIndustry() {
      this.$api
        .getAPI({
          _action: "/executive/moves/industry",
        })
        .then((res) => {
          this.ExecutivePerIndustry = res;
          this.getGraphExecutiveMoveByIndustry();
        })
        .catch(() => {});
    },
    getGraphExecutiveMoveByIndustry() {
      this.labelsArr = [];
      this.revanueArr = [];

      for (var i = 0; i < this.ExecutivePerIndustry.length; i++) {
        var industry_name = this.ExecutivePerIndustry[i].industry_name;
        this.labelsArr.push(industry_name);
        var revanue = this.ExecutivePerIndustry[i].count;
        this.revanueArr.push(revanue);
      }
      if (this.sales) {
        this.sales.destroy();
      }
      const ctx = document.getElementById("bar-chart3").getContext("2d");
      // var t = this;
      this.myChart = new Chart(ctx, {
        type: "pie",
        data: {
          labels: this.labelsArr,
          datasets: [
            {
              data: this.revanueArr,
              backgroundColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
                "rgba(72, 245, 66)",
                "rgba(52, 235, 156)",
                "rgba(235, 52, 235)",
                "rgba(220, 235, 52)",
              ],
              borderColor: "#87CEEB",
              borderWidth: 1,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              display: false,
            },
            x: {
              display: false,
            },
          },
        },
      });
    },
    getExecutiveMoveByYear() {
      this.$api
        .getAPI({
          _action: "/executive/moves/year",
        })
        .then((res) => {
          this.ExecutiveMoves = res;
          this.getGraphExecutiveMoveByYear();
        })
        .catch(() => {});
    },
    getGraphExecutiveMoveByYear() {
      this.labelsArr = [];
      this.revanueArr = [];

      for (var i = 0; i < this.ExecutiveMoves.length; i++) {
        var month_name =
          this.$helperService.getText2(
            this.ExecutiveMoves[i].month.toUpperCase(),
            3
          ) +
          "-" +
          this.ExecutiveMoves[i].year;
        this.labelsArr.push(month_name);
        var revanue = this.ExecutiveMoves[i].count;
        this.revanueArr.push(revanue);
      }
      if (this.sales) {
        this.sales.destroy();
      }
      const ctx = document.getElementById("bar-chart").getContext("2d");
      // var t = this;
      this.myChart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: this.labelsArr,
          datasets: [
            {
              data: this.revanueArr,
              backgroundColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
              ],
              borderColor: "#87CEEB",
              borderWidth: 1,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              grid: {
                display: true,
              },
              ticks: {
                // callback: function (label) {
                //   return t.$helperService.abbreviateNumber(label);
                // },
                display: false,
              },
            },
            x: {
              display: false,
            },
            // x: {
            //   grid: {
            //     display: false,
            //   },
            // },
          },
        },
      });
    },
    getCompanyProfilePerIndustry() {
      this.$refs.errorComponent.updateFormLoader(true);
      this.$api
        .getAPI({
          _action: "/company/profile/industry",
        })
        .then((res) => {
          this.CompanyProfilePerIndustry = res;
          this.getGraphCompanyProfilePerIndustry();
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.updateFormLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.updateFormLoader(false);
          }
        });
    },
    getGraphCompanyProfilePerIndustry() {
      this.labelsArr = [];
      this.revanueArr = [];

      for (var i = 0; i < this.CompanyProfilePerIndustry.length; i++) {
        var industry_name = this.CompanyProfilePerIndustry[i].industry_name;
        this.labelsArr.push(industry_name);
        var revanue = this.CompanyProfilePerIndustry[i].count;
        this.revanueArr.push(revanue);
      }
      if (this.sales) {
        this.sales.destroy();
      }
      const ctx = document.getElementById("bar-chart4").getContext("2d");
      this.myChart = new Chart(ctx, {
        type: "pie",
        data: {
          labels: this.labelsArr,
          datasets: [
            {
              data: this.revanueArr,
              backgroundColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
                "rgba(72, 245, 66)",
                "rgba(235, 52, 235)",
                "rgba(52, 235, 156)",
                "rgba(220, 235, 52)",
              ],
              borderColor: "#87CEEB",
              borderWidth: 1,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              display: false,
            },
            x: {
              display: false,
            },
          },
        },
      });
    },
    getDetail() {
      this.$api
        .getAPI({
          _action: "/dashboard",
        })
        .then((res) => {
          this.detail = res;
        })
        .catch(() => {});
    },
    getRecentAppointment() {
      this.$api
        .getAPI({
          _action: "/news/pagination/list",
        })
        .then((res) => {
          this.recent_appoinment = res.list;
        })
        .catch(() => {});
    },
    getIntrestingBites() {
      this.$api
        .getAPI({
          _action: "/intersting/bites",
        })
        .then((res) => {
          this.intresting_bites = res;
        })
        .catch(() => {});
    },
    getCommentary() {
      this.$api
        .getAPI({
          _action: "/commentary",
        })
        .then((res) => {
          this.commentary = res;
        })
        .catch(() => {});
    },
  },
};
</script>
