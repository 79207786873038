<template>
  <div>
    <section class="ftco-section">
      <div class="container">
        <div class="row justify-content-center"></div>
        <div class="row justify-content-center">
          <div class="login-wrap col-12">
            <div class="p-4 pt-4 row col-12">
              <h3
                class="col-7 title-h3 text-end pt-0"
                style="font-size: 28px; justify-content: end; display: flex"
              >
                My Executive
              </h3>
              <div
                class="col-5"
                style="align-items: center; display: flex; justify-content: end"
              >
                <h6>
                  Requested Executive :
                  {{
                    Number(total_executive_view) -
                    Number(executive_profile_left)
                  }}
                  / {{ Number(total_executive_view) }}
                </h6>
              </div>
              <div class="row mt-4" style="width: 100%">
                <div
                  class="col-lg-3 col-sm-6"
                  v-for="(data, index) in list"
                  :key="index"
                >
                  <div
                    style="cursor: pointer"
                    :style="
                      data.isChecked ? 'background-color: powderblue;' : ''
                    "
                    class="rectangle-box"
                    v-on:click="selectExecutive(index)"
                  >
                    <div class="row">
                      <div class="col-lg-3 col-sm-3 col-2 pr-2 text-center">
                        <a>
                          <img
                            v-if="data.executive_logo || !data.gender"
                            :src="
                              data.executive_logo
                                ? data.executive_logo
                                : '/img/user-pic.png'
                            "
                            alt=""
                            class="round"
                          />
                          <img
                            v-if="data.gender == 'male' && !data.executive_logo"
                            :src="
                              data.executive_logo
                                ? data.executive_logo
                                : '/img/male.png'
                            "
                            alt=""
                            class="round"
                          />
                          <img
                            v-if="
                              data.gender == 'female' && !data.executive_logo
                            "
                            :src="
                              data.executive_logo
                                ? data.executive_logo
                                : '/img/female.png'
                            "
                            alt=""
                            class="round"
                          />
                        </a>
                        <a class="" style="margin-left: -6px">
                          <country-flag
                            :country="
                              data.country ? data.country.country_code : '-'
                            "
                          />
                        </a>
                      </div>
                      <div class="col-lg-7 col-sm-7 col-8 px-2">
                        <a>
                          <div
                            v-on:click="
                              $router.push('/executive-detail/' + data.id)
                            "
                            :title="
                              data.executive_name ? data.executive_name : '-'
                            "
                            class="client-name"
                          >
                            {{
                              data.executive_name ? data.executive_name : "-"
                            }}
                          </div>
                          <div
                            :title="
                              data.new_company
                                ? data.new_company.company_name
                                : '-'
                            "
                            class="company-name"
                          >
                            <img
                              :src="
                                data.new_company
                                  ? data.new_company.company_logo
                                  : '/img/user-pic.png'
                              "
                              style="border-radius: 9px"
                              alt=""
                              width="18"
                              height="18"
                            />
                            {{
                              data.new_company
                                ? data.new_company.company_name
                                : "-"
                            }}
                          </div>
                          <div
                            :title="
                              $helperService.isStringOnlyAlphabet(
                                data.new_job_title_id
                              )
                                ? data.new_job_title_id
                                : data.new_job_title
                                ? data.new_job_title.title
                                : ''
                            "
                            class="designation"
                          >
                            <span>{{
                              $helperService.isStringOnlyAlphabet(
                                data.new_job_title_id
                              )
                                ? data.new_job_title_id
                                : data.new_job_title
                                ? data.new_job_title.title
                                : "-"
                            }}</span>
                          </div>
                        </a>
                        <div class="mt-2">
                          <a
                            :title="
                              data.country != null && data.country.phone_code
                                ? '+' +
                                  data.country.phone_code +
                                  data.executive_phone
                                : ''
                            "
                            target="__blank"
                            :href="
                              'https://api.whatsapp.com/send?phone=' +
                              (data.country != null && data.country.phone_code
                                ? data.country.phone_code + data.executive_phone
                                : '')
                            "
                            style="cursor: pointer"
                            class="mr-1"
                            ><img
                              src="/img/icons/phone.png"
                              height="25"
                              width="25"
                              alt="Linkedin"
                          /></a>
                          <a
                            :href="'mailto:' + data.executive_email_personal"
                            :title="data.executive_email_personal"
                            class="mr-1"
                            style="cursor: pointer"
                            ><img
                              src="/img/icons/mail.png"
                              height="25"
                              width="25"
                          /></a>
                          <a
                            v-on:click="openLink(data.linkedin_url)"
                            :title="data.linkedin_url"
                            style="cursor: pointer"
                            class="mr-1"
                            ><img
                              style="border-radius: 15px"
                              src="/img/linkedin-color.png"
                              height="25"
                              width="25"
                              alt="Linkedin"
                          /></a>
                          <a
                            v-on:click="openLink(data.twitter_url)"
                            style="cursor: pointer"
                            :title="data.twitter_url"
                            ><img
                              src="/img/twitter.png"
                              height="25"
                              width="25"
                              alt="Twitter"
                          /></a>
                        </div>
                      </div>
                      <div class="col-lg-2 col-sm-2 col-2 px-2 text-center">
                        <!-- <span v-if="data.is_fav == 0"
                          ><img
                            v-on:click="addToFavorite(data)"
                            src="/img/favorite.png"
                            width="18"
                            height="18"
                            alt="Favorite"
                            style="opacity: 0.5"
                        /></span>
                        <span v-if="data.is_fav == 1"
                          ><img
                            v-on:click="addToFavorite(data)"
                            src="/img/favorite-fill.png"
                            width="18"
                            height="18"
                            alt="Favorite Fill"
                        /></span> -->
                        <input
                          type="checkbox"
                          v-on:click="addExecutiveToFavorite(data)"
                          :checked="data.is_fav == 1"
                        />
                      </div>
                      <div class="col-lg-12 col-sm-12">
                        <span class="update-date"
                          >Last Updated :
                          {{
                            data.updated_on
                              ? $helperService.getFormatedDate(data.updated_on)
                              : ""
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ErrorComponent
              @retry="getFavoriteExecutiveList(0)"
              ref="executiveErrorComponent"
            />
            <div class="footer-btn-div" v-if="!loader">
              <!-- <div class="col-4"> -->
              <!-- <div class="" style="margin-right: 15px">
                <button
                  class="form-control btn-primary rounded submit px-3"
                  v-on:click="showCustomMailTemplete()"
                >
                  Create Template
                </button>
              </div> -->
              <div class="" style="margin-right: 15px">
                <button
                  v-on:click="openLinkurl()"
                  class="form-control btn-primary rounded submit px-3"
                >
                  Send Inmail
                </button>
              </div>
              <!-- <div class="" style="margin-right: 15px">
                <button
                  class="form-control btn-primary rounded submit px-3"
                  v-on:click="showSendMailTemplete()"
                >
                  Send Email
                </button> -->
              <!-- </div> -->
              <div class="" style="margin-right: 15px">
                <button
                  type="submit"
                  id="selectExecutive-button"
                  v-on:click="sendExecutiveFullprofileMail(index)"
                  class="form-control btn-primary rounded submit px-3"
                >
                  Create Full Profile
                </button>
              </div>

              <div class="tooltip-custom font-weight-bold">
                <i
                  class="fa fa-question-circle"
                  aria-hidden="true"
                  style="font-size: 30px"
                ></i>
                <span class="tooltiptext-custom ml-2">
                  <div class="font-weight-bold font-italic">
                    <a style="border-bottom: 2px solid"
                      >What Do I Receive ( within 24 hrs)
                    </a>
                  </div>
                  <div class="text-left">- Executive Name & Job Title</div>
                  <div class="text-left">- Executive Biography</div>

                  <div class="text-left">
                    - Contact Information (Email/ Phone)
                  </div>
                  <div class="text-left">- Current Company & Work History</div>
                  <div class="text-left">- Education</div>
                  <div class="text-left">- Reporting To (If available)</div>
                  <div class="text-left">- Awards & Recognitions</div>
                  <div class="text-left">- Featured News</div>
                  <div class="text-left">- Interviews & Videos</div>
                </span>
              </div>
              <!-- </div> -->
            </div>
            <div class="row mx-0 mb-4">
              <div class="col-lg-12 mt-5 text-center">
                <div class="page-bottom-pagination text-right">
                  <Pagination
                    @page-change="pageChange"
                    @items-per-page-change="itemsPerPageChange"
                    ref="ExecutiveListPagination"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="login-wrap col-12 mt-4">
            <div class="p-4 pt-4 row">
              <h3
                class="col-7 title-h3 text-end pt-0"
                style="font-size: 28px; justify-content: end; display: flex"
              >
                My Companies
              </h3>
              <div
                class="col-5"
                style="align-items: center; display: flex; justify-content: end"
              >
                <h6>
                  Requested Companies :
                  {{
                    Number(total_company_view) - Number(company_profile_left)
                  }}
                  / {{ Number(total_company_view) }}
                </h6>
              </div>

              <div class="row mt-4" style="width: 100%">
                <div
                  class="col-lg-3 col-sm-6"
                  v-for="(data, index) in favoriteCompanylist"
                  :key="index"
                >
                  <div
                    style="cursor: pointer"
                    class="rectangle-box"
                    :style="
                      data.isChecked ? 'background-color: powderblue;' : ''
                    "
                    v-on:click="selectCompany(index)"
                  >
                    <div class="row">
                      <div class="col-lg-3 col-sm-3 col-2 pr-2 text-center">
                        <a href="#">
                          <img
                            v-if="data.company_logo"
                            :src="
                              data.company_logo
                                ? data.company_logo
                                : '/img/user-pic.png'
                            "
                            alt=""
                            class="round"
                          />
                          <img
                            v-if="!data.company_logo"
                            :src="
                              data.company_logo
                                ? data.company_logo
                                : '/img/company.png'
                            "
                            alt=""
                            class="round"
                          />
                        </a>
                        <a class="mr-1" style="margin-left: -3px">
                          <country-flag
                            :country="
                              data.country ? data.country.country_code : 'US'
                            "
                          />
                        </a>
                      </div>
                      <div class="col-lg-7 col-sm-7 col-7 px-2">
                        <a>
                          <div
                            v-on:click="
                              $router.push('/company-detail/' + data.id)
                            "
                            :title="data.company_name ? data.company_name : '-'"
                            class="client-name eclips"
                          >
                            {{ data.company_name ? data.company_name : "-" }}
                          </div>
                          <div class="address">
                            {{
                              data.industry
                                ? $helperService.getText(
                                    data.industry.industry_name,
                                    20
                                  )
                                : ""
                            }}<br />
                            {{
                              data.city
                                ? $helperService.getText(
                                    data.city.city_name,
                                    10
                                  ) + ","
                                : ""
                            }}
                            {{
                              data.country
                                ? $helperService.getText(
                                    data.country.country_name,
                                    10
                                  )
                                : ""
                            }}<br />
                            {{
                              data.company_type
                                ? data.company_type + " Company"
                                : "-"
                            }}
                            Employee Size:
                            {{
                              data.company_size
                                ? $helperService.getText(data.company_size, 5)
                                : "-"
                            }}
                          </div>
                        </a>
                      </div>
                      <div class="col-lg-2 col-sm-2 col-2 px-2 text-center">
                        <!-- <span v-if="data.is_fav == 0"
                          ><img
                            v-on:click="addToFavorite(data)"
                            src="/img/favorite.png"
                            width="18"
                            height="18"
                            alt="Favorite"
                            style="opacity: 0.5"
                        /></span>
                        <span v-if="data.is_fav == 1"
                          ><img
                            v-on:click="addToFavorite(data)"
                            src="/img/favorite-fill.png"
                            width="18"
                            height="18"
                            alt="Favorite Fill"
                        /></span> -->
                        <input
                          type="checkbox"
                          v-on:click="addToFavorite(data)"
                          :checked="data.is_fav == 1"
                        />
                      </div>
                      <div class="col-lg-12 col-sm-12">
                        <span class="update-date"
                          >Last Updated :
                          {{
                            $helperService.getFormatedDate(data.updated_on)
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ErrorComponent @retry="getList(0)" ref="errorComponent" />
            <div class="footer-btn-div" v-if="!loader">
              <!-- <div class="col-4"> -->

              <div class="" style="margin-right: 15px">
                <button
                  type="submit"
                  id="favoriteCompanylist-button"
                  v-on:click="sendCompanyFullprofileMail(index)"
                  class="form-control btn-primary rounded submit px-3"
                >
                  Create Full Profile
                </button>
              </div>

              <div class="tooltip-custom font-weight-bold">
                <i
                  class="fa fa-question-circle"
                  aria-hidden="true"
                  style="font-size: 30px"
                ></i>
                <span class="tooltiptext-custom ml-2">
                  <div class="font-weight-bold font-italic">
                    <a style="border-bottom: 2px solid"
                      >What Do I Receive (within 48 hrs)
                    </a>
                  </div>
                  <div class="text-left">- Company Description</div>
                  <div class="text-left">- Management Team Page</div>
                  <div
                    class="font-weight-bold font-italic"
                    style="margin-left: 13px"
                  >
                    <a style="border-bottom: 2px solid">
                      Last One Year Data Covering
                    </a>
                  </div>
                  <div class="text-left">- All Executive Moves</div>
                  <div class="text-left">
                    - Any Key Developments/ Partnerships/ Product
                  </div>
                  <div class="text-left" style="margin-left: 13px">
                    Launch/ Expansion Plans/ Sales & Revenue Figures
                  </div>
                  <div class="text-left">- Deals/ M&A/ Transactions</div>
                  <div class="text-left">- Interesting Bites</div>
                </span>
              </div>
            </div>
            <div class="row mx-0 mb-4">
              <div class="col-lg-12 mt-5 text-center">
                <div class="page-bottom-pagination text-right">
                  <Pagination
                    @page-change="pageChange2"
                    @items-per-page-change="itemsPerPageChange2"
                    ref="listPagination"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <LargeTextModal ref="largeTextModal">
      <div class="modal-header pt-0">
        <h5 class="modal-title">Mail Template</h5>
        <button
          type="button"
          class="close"
          v-on:click="$refs.largeTextModal.closeModal"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Form ref="mailTemplate" @submit="save()">
        <div class="col-lg-12 col-sm-12">
          <div class="col-12 form-group">
            <label>Template Name <span class="text-danger">*</span></label>

            <Field
              type="text"
              name="name"
              class="form-control"
              placeholder="Template Name"
              v-model="detail.name"
              rules="required: template"
              :validateOnInput="true"
            />
            <ErrorMessage name="name" class="text-danger" />
          </div>
          <div class="col-12 form-group">
            <label>Subject <span class="text-danger">*</span></label>

            <Field
              type="text"
              name="subject"
              class="form-control"
              placeholder="Subject"
              v-model="detail.subject"
              rules="required: subject"
              :validateOnInput="true"
            />
            <ErrorMessage name="subject" class="text-danger" />
          </div>
          <div class="col-12 form-group">
            <label>Body <span class="text-danger">*</span></label>
            <!-- <Field
              type="text"
              name="body"
              rules="required: body"
              :validateOnInput="true"
              v-model="detail.body"
              v-slot="{ field }"
            > -->
              <!-- <Editor
                api-key="no-api-key"
                v-model="detail.body"
                v-bind="field"
                :init="{
                  plugins: 'lists link image table code help wordcount',
                  setup: attribute,
                  toolbar:
                    'undo redo | blocks | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | Attributes',
                }"
              /> -->
            <!-- </Field> -->
            <ErrorMessage name="body" class="text-danger" />
          </div>
        </div>
        <!-- <div class="col-lg-12 col-sm-12">
        <div class="form-group">
          <label>New Password</label>
          <span class="text-danger">*</span>
          <Field
            type="password"
            name="name_password"
            class="form-control"
            placeholder="New Password"
            v-model="new_password"
            rules="required:password|minLength:6"
            :validateOnInput="true"
          />
          <ErrorMessage name="name_password" class="text-danger" />
        </div>
      </div> -->
        <div class="col-lg-12 col-sm-12">
          <!-- <div class="form-group">
            <label>Confirm Password</label>
            <span class="text-danger">*</span>
            <Field
              type="password"
              placeholder="Confirm Password"
              name="name_ccpassword"
              class="form-control"
              rules="required:confirm password|confirmed:@_password"
              :validateOnInput="true"
            />
            <ErrorMessage name="name_ccpassword" class="text-danger" />
          </div> -->
        </div>
        <div class="modal-footer pb-0">
          <button
            class="fill-btn"
            id="save-button"
            style="width: 20%; border-radius: 5px"
          >
            Save
          </button>
          <button
            type="button"
            class="fil-btn"
            id="cancel-button"
            v-on:click="$refs.largeTextModal.closeModal"
            style="width: 20%; border-radius: 5px"
          >
            Cancel
          </button>
        </div>
      </Form>
    </LargeTextModal>
    <LargeTextModal ref="textModal">
      <div class="modal-header pt-0">
        <h5 class="modal-title">Send Mail</h5>
        <button
          type="button"
          class="close"
          v-on:click="$refs.textModal.closeModal"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Form ref="new" @submit="sendMail()">
        <div class="col-lg-12 col-sm-12">
          <div class="col-3 form-group">
            <label>Template<span class="text-danger">*</span></label>

            <Field
              as="select"
              class="form-control"
              placeholder="Platform"
              name="template"
              rules="required: template"
              :validateOnInput="true"
              v-model="send_mail_detail.template"
            >
              <option value="">Select Template</option>
              <option
                v-for="(data, index) in templateList"
                :key="index"
                :value="data.id"
                v-on:click="updateEmail(data.body)"
              >
                {{ data.name }}
              </option>
            </Field>
            <ErrorMessage name="template" class="text-danger" />
          </div>
          <div
            class="col-lg-12 col-sm-12"
            v-if="send_mail_detail.template == 'Custom'"
          >
            <div class="col-12 form-group">
              <label>Subject <span class="text-danger">*</span></label>

              <Field
                type="text"
                name="subject"
                class="form-control"
                placeholder="Subject"
                v-model="send_mail_detail.subject"
                rules="required: subject"
                :validateOnInput="true"
              />
              <ErrorMessage name="subject" class="text-danger" />
            </div>
            <div class="col-12 form-group">
              <label>Body <span class="text-danger">*</span></label>
              <!-- <Field
                type="text"
                name="body"
                rules="required: body"
                :validateOnInput="true"
                v-model="send_mail_detail.body"
                v-slot="{ field }"
              >
                <Editor
                  api-key="no-api-key"
                  v-model="send_mail_detail.body"
                  v-bind="field"
                  :init="{
                    plugins: 'lists link image table code help wordcount',
                    setup: attribute,
                    toolbar:
                      'undo redo | blocks | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | Attributes',
                  }"
                />
              </Field> -->
              <ErrorMessage name="body" class="text-danger" />
            </div>
          </div>
          <div
            class="table-responsive"
            v-if="
              send_mail_detail.template != 'Custom' ||
              !send_mail_detail.template
            "
          >
            <div class="dataTables_wrapper dt-bootstrap4">
              <table
                class="table table-striped table-bordered dataTable"
                style="width: 100%"
                role="grid"
                aria-describedby="example_info"
              >
                <thead>
                  <tr role="row">
                    <th>Executive Name</th>
                    <th>Executive Email</th>
                    <th>Mail Content</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in sendMaillist" :key="index">
                    <td>{{ data.executive_name }}</td>
                    <td>{{ data.executive_email_work }}</td>
                    <td
                      style="text-align: inherit"
                      v-html="data.mail_data"
                    ></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="modal-footer pb-0">
          <button
            class="fill-btn"
            id="send-mail-button"
            style="width: 20%; border-radius: 5px"
          >
            Send Mail
          </button>
          <button
            type="button"
            class="fil-btn"
            id="cancel-button"
            v-on:click="$refs.textModal.closeModal"
            style="width: 20%; border-radius: 5px"
          >
            Cancel
          </button>
        </div>
      </Form>
    </LargeTextModal>
  </div>
</template>
<script>
import CountryFlag from "vue-country-flag-next";
// import Editor from "@tinymce/tinymce-vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
import LargeTextModal from "@/components/LargeTextModal.vue";
// import TextModal from "@/components/TextModal.vue";
export default {
  name: "Favourite",
  components: {
    Form,
    Field,
    // Editor,
    ErrorMessage,
    CountryFlag,
    ErrorComponent,
    Pagination,
    LargeTextModal,
    // TextModal,
  },
  data() {
    return {
      loader: true,
      executive_profile_left: 0,
      total_executive_view: 0,
      total_company_view: 0,
      company_profile_left: 0,
      data: {},
      detail: {
        body: "",
      },
      send_mail_detail: {
        template: "",
      },
      list: [],
      templateList: [],
      favoriteCompanylist: [],
      searchObj: {
        offset: -1,
      },
    };
  },
  mounted() {
    this.getFavoriteExecutiveList(0);
    this.getList(0);
    this.getTemplateList();
  },
  methods: {
    attribute(editor) {
      editor.ui.registry.addMenuButton("Attributes", {
        text: "Attribute",
        fetch: function (callback) {
          var items = [];
          items.push(
            {
              type: "menuitem",
              text: "Name",
              value: "{name}",
              onSetup: function () {
                var $element = this;
                this.onAction = function () {
                  editor.insertContent("&nbsp;" + $element.data.value);
                };
              },
            },
            {
              type: "menuitem",
              text: "CompanyName",
              value: "{companyName}",
              onSetup: function () {
                var $element = this;
                this.onAction = function () {
                  editor.insertContent("&nbsp;" + $element.data.value);
                };
              },
            }
          );
          callback(items);
        },
      });
    },
    getTemplateList() {
      this.$api
        .getAPI({
          _action: "/mail-template-list",
        })
        .then((res) => {
          this.templateList = res;
          this.templateList.push({
            id: "Custom",
            name: "Custom",
          });
        })
        .catch(() => {});
    },
    showCustomMailTemplete() {
      this.detail.body = "";
      this.detail = {};
      this.$refs.mailTemplate.resetForm();
      this.$refs.largeTextModal.showModal();
    },
    updateEmail(mailHtml) {
      if (mailHtml) {
        for (var i in this.sendMaillist) {
          var mail_data = mailHtml.replaceAll(
            "{name}",
            this.sendMaillist[i].executive_name
          );
          if (
            this.sendMaillist[i]?.new_company &&
            this.sendMaillist[i].new_company.company_name
          ) {
            this.sendMaillist[i].mail_data = mail_data.replaceAll(
              "{companyName}",
              this.sendMaillist[i]?.new_company?.company_name
            );
          } else {
            this.sendMaillist[i].mail_data = mail_data.replaceAll(
              "{companyName}",
              "-"
            );
          }
        }
      }
    },
    openLinkurl() {
      var ids = [];
      for (var i in this.list) {
        if (this.list[i].isChecked) {
          if (this.list[i].isChecked) {
            ids.push(this.list[i]);
            // this.list[i].twitter_url = "https://google.com";
            if (this.list[i].linkedin_url) {
              this.url(this.list[i].linkedin_url);
            }
          }
        }
      }
      if (ids.length == 0) {
        this.$notify({
          type: "error",
          text: "Please click on the profile to select !",
        });
        return;
      }
    },
    url(url) {
      try {
        window.open(url);
      } catch {
        console.log('d')
      }
    },
    showSendMailTemplete() {
      this.send_mail_detail = {};
      this.$refs.new.resetForm();
      this.sendMaillist = [];
      var ids = [];
      for (var i in this.list) {
        if (this.list[i].isChecked) {
          ids.push(this.list[i]);
        }
      }
      if (ids.length == 0) {
        this.$notify({
          type: "error",
          text: "Please click on the profile to select !",
        });
        return;
      }
      this.sendMaillist = ids;
      this.getTemplateList();
      this.$refs.textModal.showModal();
    },
    sendExecutiveFullprofileMail() {
      var ids = [];
      for (var i in this.list) {
        if (this.list[i].isChecked) {
          ids.push(this.list[i].id);
          this.list[i].isChecked = false;
        }
      }
      if (ids.length == 0) {
        this.$notify({
          type: "error",
          text: "Please click on the profile to select !",
        });
        return;
      }
      var obj = {};
      obj.ids = ids;
      this.$api
        .postAPI({
          _action: "/create-executive-profile",
          _body: obj,
          _buttonId: "selectExecutive-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.getFavoriteExecutiveList(0);
        })
        .catch(() => {});
    },
    save() {
      this.$api
        .postAPI({
          _action: "/add-mail-template",
          _body: this.detail,
          _buttonId: "save-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.largeTextModal.closeModal();
        })
        .catch(() => {});
    },
    sendMail() {
      var obj = {};
      if (this.send_mail_detail.template == "Custom") {
        for (var i in this.sendMaillist) {
          var mail_data = this.send_mail_detail.body.replaceAll(
            "{name}",
            this.sendMaillist[i].executive_name
          );
          if (
            this.sendMaillist[i]?.new_company &&
            this.sendMaillist[i].new_company.company_name
          ) {
            this.sendMaillist[i].mail_data = mail_data.replaceAll(
              "{companyName}",
              this.sendMaillist[i]?.new_company?.company_name
            );
          } else {
            this.sendMaillist[i].mail_data = mail_data.replaceAll(
              "{companyName}",
              "-"
            );
          }
        }
      }
      if (this.sendMaillist.length > 0) {
        obj.sendEmail = this.sendMaillist;
        obj.template = this.send_mail_detail.template;
      }
      this.$api
        .postAPI({
          _action: "/send-mails-executive",
          _body: obj,
          _buttonId: "send-mail-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.textModal.closeModal();
        })
        .catch(() => {});
    },
    sendCompanyFullprofileMail() {
      var ids = [];
      for (var i in this.favoriteCompanylist) {
        if (this.favoriteCompanylist[i].isChecked) {
          ids.push(this.favoriteCompanylist[i].id);
          this.favoriteCompanylist[i].isChecked = false;
        }
      }
      if (ids.length == 0) {
        this.$notify({
          type: "error",
          text: "Please click on the profile to select !",
        });
        return;
      }
      var obj = {};
      obj.ids = ids;
      this.$api
        .postAPI({
          _action: "/create-company-profile",
          _body: obj,
          _buttonId: "favoriteCompanylist-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.getList(0);
        })
        .catch(() => {});
    },
    addToFavorite(data) {
      var obj = {};
      obj.company_id = data.id;
      this.$api
        .putAPI({
          _action: "/add/company/fav",
          _body: obj,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });

          this.getList(0);
          // this.$router.push("/company");
        })
        .catch(() => {});
    },
    addExecutiveToFavorite(data) {
      var obj = {};
      obj.executive_id = data.id;
      this.$api
        .putAPI({
          _action: "/add/executive/fav",
          _body: obj,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          // return (data.is_fav = data.is_fav == 1 ? 0 : 1);
          this.getFavoriteExecutiveList(0);
          // this.$router.push("/executive");
        })
        .catch(() => {});
    },
    pageChange(offset) {
      this.getFavoriteExecutiveList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.ExecutiveListPagination.itemsPerPage;
      this.getFavoriteExecutiveList(0);
    },
    pageChange2(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange2() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    selectCompany(index) {
      var count = 0;
      for (var k in this.favoriteCompanylist) {
        if (k == index && this.favoriteCompanylist[k].isChecked) {
          this.favoriteCompanylist[k].isChecked = false;
          return;
        }
        if (this.favoriteCompanylist[k].isChecked) {
          count = count + 1;
        }
      }
      if (count == this.company_profile_left) {
        this.$notify({
          type: "error",
          text:
            "you cannot request more then " +
            this.total_company_view +
            " company in a month",
        });
        return;
      }
      for (var i in this.favoriteCompanylist) {
        if (i == index) {
          this.favoriteCompanylist[i].isChecked =
            !this.favoriteCompanylist[i].isChecked;
        }
      }
    },
    selectExecutive(index) {
      var count = 0;
      for (var k in this.list) {
        if (k == index && this.list[k].isChecked) {
          this.list[k].isChecked = false;
          return;
        }
        if (this.list[k].isChecked) {
          count = count + 1;
        }
      }
      if (count == this.executive_profile_left) {
        this.$notify({
          type: "error",
          text:
            "you cannot request more then  " +
            this.total_executive_view +
            "  Executive in a month",
        });
        return;
      }
      for (var i in this.list) {
        if (i == index) {
          this.list[i].isChecked = !this.list[i].isChecked;
        }
      }
    },
    getFavoriteExecutiveList(page) {
      this.$refs.executiveErrorComponent.updateListLoader(true);
      this.list = [];
      this.searchObj.offset = page;
      // if (this.type == "PAST") {
      //   this.searchObj.prev_company_id = this.id;
      // } else if (this.type == "CURRENT") {
      //   this.searchObj.new_company_id = this.id;
      // }
      this.$api
        .getAPI({
          _action: "/favorite/executive/pagination/list",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.executive_profile_left = res.left_full_profile_request;
          this.total_executive_view = res.total_executive_view;
          for (var i in this.list) {
            this.list[i].isChecked = false;
          }
          this.offset = res.next_offset;
          if (page == 0) {
            if (this.$refs.ExecutiveListPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.ExecutiveListPagination.setTotalCount(res.count);
              } else {
                this.$refs.ExecutiveListPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.executiveErrorComponent) {
            this.$refs.executiveErrorComponent.setData(
              res,
              "No favorite  executive are available"
            );
            this.$refs.executiveErrorComponent.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.executiveErrorComponent) {
            this.$refs.executiveErrorComponent.setData(0, undefined, "");
            this.$refs.executiveErrorComponent.updateListLoader(false);
          }
        });
    },
    getList(page) {
      this.$refs.errorComponent.updateListLoader(true);
      this.favoriteCompanylist = [];
      this.searchObj.offset = page;

      this.$api
        .getAPI({
          _action: "/favorite/company/pagination/list",
          _body: this.searchObj,
        })
        .then((res) => {
          for (var i in res.list) {
            if (res.list[i].company_type) {
              res.list[i].company_type =
                res.list[i].company_type.charAt(0).toUpperCase() +
                res.list[i].company_type.substring(1).toLowerCase();
            }
          }
          this.loader = false;
          this.favoriteCompanylist = res.list;
          this.company_profile_left = res.left_full_profile_request;
          this.total_company_view = res.total_company_view;
          for (var j in this.favoriteCompanylist) {
            this.favoriteCompanylist[j].isChecked = false;
          }
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(
              res,
              "No favorite Company are available"
            );
            this.$refs.errorComponent.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
  },
};
</script>

<style scoped>
.footer-btn-div {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>
