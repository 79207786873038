import moment from "moment"

class HelperService {

    constructor() {
        this.accessLevel = new Map()
    }

    sorting(sortBy, id) {
        var orderBy = "ASC";
        var className = "sorting_asc";
        var temp = document.getElementById(id)
        if (temp) {
            if (temp.className.includes('sorting_asc')) {
                className = 'sorting_desc'
            }
            if (className == "sorting_asc") {
                temp.classList.add('sorting_asc')
                temp.classList.remove('sorting_desc')
                orderBy = "ASC";
            } else if (className == "sorting_desc") {
                temp.classList.add('sorting_desc')
                temp.classList.remove('sorting_asc')
                orderBy = "DESC";
            }
        }
        var obj = {
            order_by: orderBy,
            sort_by: sortBy
        }
        return obj;
    }

    getFormatedDate(d) {
        return moment(d).format("DD MMM, YYYY")
    }

    getFormatedDateAndTime(dt) {
        return moment.utc(dt).local().format("DD MMM YYYY, hh:mm A")
    }

    sendFormatedDate(d, type) {
        if (type == 'date') {
            return moment(d).format("YYYY-MM-DD")
        } else if (type == 'month_year') {
            return moment(d).format("YYYY-MM")
        } else if (type == 'year') {
            return moment(d).format("YYYY")
        } else {
            return moment(d).format("YYYY-MM-DD")
        }
    }

    allowOnlyNumericValue(e) {
        var numbers = /^[0-9]$/;
        if (!e.key.match(numbers) && e.keyCode != 8) {
            e.preventDefault();
            return false;
        }
    }

    getTitleCase(value) {
        if (!value) {
            return ""
        } else {
            var str = ''
            var arr = value.split('_')
            for (var i in arr) {
                if (i == 0) {
                    str = arr[i].charAt(0).toUpperCase() + arr[i].substring(1).toLowerCase();
                } else {
                    str += '' + arr[i].charAt(0).toUpperCase() + arr[i].substring(1).toLowerCase();
                }
            }
            return str
        }
    }
    abbreviateNumber(number) {
        var SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
        // what tier? (determines SI symbol)
        var tier = Math.log10(Math.abs(number)) / 3 | 0;

        // if zero, we don't need a suffix
        if (tier == 0) return number;

        // get suffix and determine scale
        var suffix = SI_SYMBOL[tier];
        var scale = Math.pow(10, tier * 3);

        // scale the number
        var scaled = number / scale;

        // format number and add suffix
        return scaled.toFixed(1) + suffix;
    }
    setAccessLevel(accessLevel) {
        this.accessLevel = accessLevel
    }
    getText(text, length) {
        if (text && length && text.length > length) {
            return text.substring(0, length) + '...';
        }

        return text;
    }
    isStringOnlyAlphabet(str) {
        var regExp = /[a-zA-Z]/g;
        var testString = str;

        if (regExp.test(testString)) {
            return 1
        } else {
            return 0
        }
    }
    getText2(text, length) {
        if (text && length && text.length > length) {
            return text.substring(0, length);
        }

        return text;
    }
    getAccessLevel(moduleName) {
        if (this.accessLevel.length > 0) {
            for (var i = 0; i < this.accessLevel.length; i++) {
                if (moduleName == this.accessLevel[i].name) {
                    return this.accessLevel[i]
                }
            }
        }
    }

    hasAccessLevel() {
        return this.accessLevel.length > 0
    }
    getUpperCase(value) {
        if (!value) {
            return ""
        } else {
            var str = value.toUpperCase()
            return str
        }
    }

    getFirstName(value) {
        if (!value) {
            return ""
        } else {
            var str = value.split(' ')
            return str[0];
        }
    }

    startsWithCapital(word) {
        if (word.charAt(1) === word.charAt(1).toUpperCase()) {
            return word
        } else {
            var splitStr = word.toLowerCase().split(' ');
            for (var i = 0; i < splitStr.length; i++) {
                splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
            }
            return splitStr.join(' ');
        }
    }
}

export default new HelperService({})