<template>
  <div v-show="isShowModal">
    <div class="spx-modal h-100 justify-content-center">
      <div
        class="spx-modal-body mx-auto"
        style="
          max-width: 1055px !important;
          width: 1055px !important;
          height: auto !important;
          overflow-y: scroll;
        "
      >
        <div>
          <slot></slot>
        </div>
      </div>
    </div>
    <div class="spx-modal-background"></div>
  </div>
</template>
<script>
export default {
  name: "DeleteModal",
  data() {
    return {
      isShowModal: false,
    };
  },
  mounted() {},
  methods: {
    showModal() {
      this.isShowModal = true;
    },
    closeModal() {
      this.isShowModal = false;
    },
  },
};
</script>
