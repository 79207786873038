<template>
  <div>
    <Header />
    <section class="empty60 aboutus-page">
      <div class="container h-100">
        <div class="row align-items-center h-100">
          <div class="col-md-9 col-sm-10 mx-auto">
            <h1 class="main-title">Empowering<br />Executive Intelligence</h1>
            <div class="sub-title text-center">
              Discover millions of senior executives, companies <br />and track
              moves globally
            </div>
            <!-- <div class="start-free-trial-btn" align="center">
              <a href="#">Start My free Trial</a>
            </div> -->
          </div>
        </div>
      </div>
    </section>
    <div class="container empty50">
      <div class="row">
        <div class="col-lg-10 col-sm-12 mx-auto">
          <h2 class="title-h2">About ExecLibrary</h2>
          <p class="para">
            Trusted By Sales & Executive Recruiting Teams at Leading Enterprises
          </p>
          <p class="para">
            World's largest searchable database of senior level executive
            profiles and moves. Explore and discover decision makers by company,
            industry, function, location and job titles.
          </p>
          <p class="para">
            ExecLibrary brings you the most updated information on potential
            executives and prospects. We track millions of information sources
            on the web to bring you the most recent news and updates.
          </p>
        </div>
      </div>
    </div>
    <div class="container empty50">
      <div class="row">
        <div class="col-lg-5 col-sm-6">
          <!-- <img src="/img/about.jpg" alt="" class="img-fluid" /> -->
        </div>
        <div class="col-lg-7 col-sm-6">
          <ul class="nav nav-tabs tabs-menu" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                data-toggle="tab"
                href="#tab1"
                role="tab"
                aria-expanded="true"
                >Executives</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                data-toggle="tab"
                href="#tab2"
                role="tab"
                aria-expanded="false"
                >Companies</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                data-toggle="tab"
                href="#tab3"
                role="tab"
                aria-expanded="false"
                >News</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                data-toggle="tab"
                href="#tab6"
                role="tab"
                aria-expanded="false"
                >Deals & Transactions</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                data-toggle="tab"
                href="#tab4"
                role="tab"
                aria-expanded="false"
                >Interesting Bites</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                data-toggle="tab"
                href="#tab5"
                role="tab"
                aria-expanded="false"
                >Commentary</a
              >
            </li>
          </ul>
        </div>
        <div class="tab-content">
          <div
            class="tab-pane active"
            id="tab1"
            role="tabpanel"
            aria-expanded="true"
          >
            <div class="row">
              <div class="col-lg-5 col-sm-6 mt-4">
                <img src="/img/executive.png" alt="" class="img-fluid" />
              </div>
              <div class="col-lg-7 col-sm-6">
                <h3 class="title-h3">Executives</h3>
                <p>
                  Execlibrary profiles millions of senior level executives along
                  with their biographies, social channels, contact information.
                  Users can create full profiles of executives using the "Create
                  Full Profile" feature on ExecLibrary.
                </p>
                <ul class="bulltes">
                  <li>
                    Searchable database of millions of senior executives and
                    decision makers
                  </li>
                  <li>
                    Seach by Company, Job Titles, Job Function, Seniority,
                    Geography and many more
                  </li>
                  <li>
                    Track Current and Past executives from your target companies
                  </li>
                  <li>
                    Access interesting news articles, videos and social media
                  </li>
                  <li>
                    Use the customized research feature to get profiles created
                    as per your needs supported by your dedicated account
                    executives
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="tab2" role="tabpanel" aria-expanded="false">
            <div class="row">
              <div class="col-lg-5 col-sm-6 mt-4">
                <img src="/img/companies.png" alt="" class="img-fluid" />
              </div>
              <div class="col-lg-7 col-sm-6">
                <h3 class="title-h3">Companies</h3>
                <p>
                  ExecLibrary profiles millions of companies, both public and
                  private with their descriptions, current management team, past
                  employees information captured from news moves and articles,
                  interesting news articles, social media channels etc.
                </p>
                <ul class="bulltes">
                  <li>Explore companies across sectors</li>
                  <li>Track target accounts and competitors</li>
                  <li>Request Company Profiles</li>
                  <li>
                    Request customized research for any company of your interest
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="tab3" role="tabpanel" aria-expanded="false">
            <div class="row">
              <div class="col-lg-5 col-sm-6 mt-4">
                <img src="/img/news.png" alt="" class="img-fluid" />
              </div>
              <div class="col-lg-7 col-sm-6">
                <h3 class="title-h3">News</h3>
                <p>
                  ExecLibrary uses state of the art technology to track millions
                  of executive moves from various sources on the web.
                </p>
                <ul class="bulltes">
                  <li>Searchable database of executive moves</li>
                  <li>
                    Track executive moves across your target sectors, companies
                    and geographies
                  </li>
                  <li>
                    Profile senior executives from the EL Executive Moves
                    section
                  </li>
                  <li>
                    Build your own news feed using the customized research
                    feature
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="tab6" role="tabpanel" aria-expanded="false">
            <div class="row">
              <div class="col-lg-5 col-sm-6 mt-4">
                <img
                  src="/img/deals_transaction.png"
                  alt=""
                  class="img-fluid"
                />
              </div>
              <div class="col-lg-7 col-sm-6">
                <h3 class="title-h3">Deals & Transactions</h3>
                <ul class="bulltes">
                  <li>
                    Searchable database of most recent funding activities and
                    financial transactions globally
                  </li>
                  <li>
                    Track executives and companies involved in the transactions
                  </li>
                  <li>
                    Transactions include Startup Capital Raising, M&A's, IPO's
                    and more..
                  </li>
                  <li>
                    Receive quick updates on transactions in your industry or
                    companies of interest
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="tab4" role="tabpanel" aria-expanded="false">
            <div class="row">
              <div class="col-lg-5 col-sm-6 mt-4">
                <img src="/img/interesting-bits.png" alt="" class="img-fluid" />
              </div>
              <div class="col-lg-7 col-sm-6">
                <h3 class="title-h3">Interesting Bites</h3>

                <ul class="bulltes">
                  <li>
                    Receive quick updates on you target companies or industries
                  </li>
                  <li>
                    Updates on recent developments, geographic expansion, M&A,
                    key financials etc.
                  </li>
                  <li>
                    Request customized research for any company or industry of
                    your interest
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="tab-pane" id="tab5" role="tabpanel" aria-expanded="false">
            <div class="row">
              <div class="col-lg-5 col-sm-6 mt-4">
                <img src="/img/commentaries.png" alt="" class="img-fluid" />
              </div>
              <div class="col-lg-7 col-sm-6">
                <h3 class="title-h3">Commentary</h3>
                <p>
                  Track comments made by senior industry leaders taking about:
                </p>
                <ul class="bulltes">
                  <li>
                    Receive quick updates on you target companies or industries
                  </li>
                  <li>
                    Updates on recent developments, geographic expansion, M&A,
                    key financials etc.
                  </li>
                  <li>
                    Request customized research for any company or industry of
                    your interest
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="gery-bg empty50">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-sm-12 text-center">
            <img src="/img/logos/logo1.png" alt="" class="mx-2" />
            <img src="/img/logos/logo2.png" alt="" class="mx-2" />
            <img src="/img/logos/logo3.png" alt="" class="mx-2" />
            <img src="/img/logos/logo4.png" alt="" class="mx-2" />
            <img src="/img/logos/logo5.png" alt="" class="mx-2" />
          </div>
        </div>
      </div>
    </div> -->

    <div class="container empty50">
      <div class="org-bg">
        <div class="row h-100">
          <div class="col-lg-8 col-sm-8 my-auto">
            <h3>Build your research</h3>
            <div class="sub-title">
              Get Started With Your Customized Research Today!
            </div>
          </div>
          <div class="col-lg-4 col-sm-4 my-auto txt-right">
            <a
              style="cursor: pointer"
              v-on:click="ShowCustomSearchModel()"
              class="customised-search-btn"
              >Customised Search</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="container empty50">
      <div class="row">
        <div class="col-lg-12 col-sm-12 mx-auto">
          <h2 class="title-h2">Our Pricing Plan</h2>
        </div>
      </div>
      <div class="row items py-4">
        <div class="col-lg-4 col-sm-4">
          <div class="pricing-plan">
            <div><img src="assets/img/Users.svg" alt="" /></div>
            <div class="mid-title">Executive Biographies</div>
            <div class="lg-title">$45</div>
            <div class="sm-title">
              <p>Request & Download</p>
              <p>500 Executive Bios</p>
            </div>
            <div class="lg-title">$499</div>
            <div class="sm-title">
              <p>Request & Download</p>
              <p>6500 Executive Bios</p>
            </div>
            <div>
              <a href="#">Select Plan</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-4">
          <div class="pricing-plan">
            <div><img src="assets/img/Users.svg" alt="" /></div>
            <div class="mid-title">Executive Moves</div>
            <div class="lg-title">$45</div>
            <div class="sm-title">
              <p>Approx - 100 Moves</p>
              <p>per working day</p>
            </div>
            <div class="lg-title">$499</div>
            <div class="sm-title">
              <p>Approx 25000 Moves</p>
              <p>In a Year</p>
            </div>
            <div>
              <a href="#">Select Plan</a>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-sm-4">
          <div class="pricing-plan">
            <div><img src="assets/img/Users.svg" alt="" /></div>
            <div class="mid-title">Mobile Application</div>
            <div class="lg-title">$39</div>
            <div class="sm-title">
              <p>Request Upto 100 Executive Profiles</p>
              <p>(Includes Contact Information**)</p>
            </div>
            <div class="lg-title">$399</div>
            <div class="sm-title">
              <p>Request Upto 2000 Executive Profiles</p>
              <p>(Includes Contact Information**)</p>
            </div>
            <div>
              <a href="#">Select Plan</a>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="container empty50">
      <div class="row">
        <div class="col-lg-12 col-sm-12 mx-auto">
          <h2 class="title-h2">Mobile App</h2>
        </div>
      </div>
    </div>
    <div class="container empty50" style="margin-bottom: 100px;">
      <div class="row">
        <div class="col-lg-6 col-sm-6 static-page">
          <p>
            ExecLibrary Mobile App offers on-demand executive information.
            Whether you are reading a news article or looking at a social
            profile of an executive and want to know more about them,
            ExecLibrary would be handy!
          </p>
          <!--  <div class="phone-bg">
        <video autoplay="" controls="" src="http://techslides.com/demos/sample-videos/small.mp4" width="100%" height="300"></video>
        </div> -->
          <!-- <img src="/img/vidoeb.png" class="img-fluid" alt="" /> -->
          <video controls>
            <source
              src="https://execlibraryapi.winayak.com/img/EXECLIBRARY.mp4"
              type="video/mp4"
              width="200"
              height="100"
            />
          </video>
        </div>
        <div class="col-lg-6 col-sm-6 mobile-app-slider">
            <!-- <div id="owl-demo" class="owl-carousel owl-theme my-4">
              <div class="item"><img src="img/slider/login.png" alt="" /></div>
              <div class="item"><img src="img/slider/user.png" alt="" /></div>
              <div class="item"><img src="img/slider/other.png" alt="" /></div>
              <div class="item"><img src="img/slider/executive.png" alt="" /></div>
              <div class="item"><img src="img/slider/companies.png" alt="" /></div>
              <div class="item"><img src="img/slider/news.png" alt="" /></div>
              <div class="item"><img src="img/slider/deals_transactions.png" alt="" /></div>
          </div> -->
          <div class="slider-screen-two">
          <div id="custCarousel" class="carousel slide" data-ride="carousel">
        <!-- slides -->
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="img/slider/login.png" alt="" />
          </div>

          <div class="carousel-item">
            <img src="img/slider/user.png" alt="" />
          </div>

          <div class="carousel-item">
            <img src="img/slider/other.png" alt="" />
          </div>

          <div class="carousel-item">
            <img src="img/slider/executive.png" alt="" />
          </div>
          <div class="carousel-item">
            <img src="img/slider/companies.png" alt="" />
          </div>
          <div class="carousel-item">
            <img src="img/slider/news.png" alt="" />
          </div>
          <div class="carousel-item">
            <img src="img/slider/deals_transactions.png" alt="" />
          </div>
        </div>

        <!-- Left right -->
        <a class="carousel-control-prev" href="#custCarousel" data-slide="prev">
          <span class="carousel-control-prev-icon"></span>
        </a>
        <a class="carousel-control-next" href="#custCarousel" data-slide="next">
          <span class="carousel-control-next-icon"></span>
        </a>

      </div>

      </div>
        </div>
      </div>
    </div>

    <div class="contact-bg empty50">
      <div class="container">
        <div class="row h-100">
          <div class="col-lg-6 col-sm-6 my-auto">
            <h3>Contact Us</h3>
            <p class="queries">
              Please fill out the form with your details and one of our account
              managers will reach out at the earliest to discuss and understand
              your needs.
            </p>
            <!-- <div class="pb-4">
              <img src="assets/img/clock.png" alt="" />&nbsp;
              <span style="color: #fff">Mon - Fri 09:00 AM - 09:00 PM</span>
            </div>
            <div class="pb-4">
              <img src="assets/img/location.png" alt="" />&nbsp;
              <span style="color: #fff"
                >591 Lakewood Rd<br />Four Oaks, North Carolina(NC), 27524</span
              >
            </div>
            <div class="pb-4">
              <img src="assets/img/cell.png" alt="" />&nbsp;
              <a href="tel:(123) 123-1234" style="color: #fff"
                >(123) 123-1234</a
              >
            </div> -->
            <!-- <div class="pb-4">
              <img src="img/messagew.png" alt="" />&nbsp;
              <a href="mailto:support@execlibrary.com" style="color: #fff"
                >support@execlibrary.com</a>
                <a href="mailto:myplan@execlibrary.com" style="color: #fff"
                >myplan@execlibrary.com</a>
            </div> -->
          </div>
          <div class="col-lg-6 col-sm-6">
            <div class="contact-form">
              <h4>Contact Us</h4>
              <Form @submit="save()">
                <div class="form-group">
                  <label>Full Name</label>
                  <Field
                    type="text"
                    name="Full_Name"
                    class="form-control"
                    placeholder="Full Name"
                    v-model="detail.name"
                  />
                </div>
                <div class="form-group">
                  <label>Email</label>
                  <Field
                    type="email"
                    name="name_email"
                    class="form-control"
                    placeholder="Email"
                    v-model="detail.email"
                    rules="required:email|email"
                    :validateOnInput="true"
                  />
                  <ErrorMessage name="name_email" class="text-danger" />
                </div>
                <div class="form-group">
                  <label>Subject</label>
                  <Field
                    type="text"
                    name="Subject"
                    class="form-control"
                    placeholder="Subject"
                    v-model="detail.subject"
                  />
                </div>
                <div class="form-group">
                  <label>Message</label>
                  <textarea
                    type="text"
                    class="form-control"
                    placeholder="Message"
                    v-model="detail.message"
                  >
                  </textarea>
                </div>
                <div>
                  <button id="save-button" value="Submit" class="submit-btn">
                    Submit
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <TextModal ref="textModal">
    <div
      class="modal-header pt-0 text-center"
      style="
        justify-content: center;
        display: block ruby;
        background-color: black;
        margin-top: -21px;
      "
    >
      <h5 class="modal-title">
        <a class="navbar-brand"
          ><img src="/img/logo.svg" alt="ExecLibrary" width="100" height="51"
        /></a>
      </h5>
      <button
        type="button"
        class="close"
        style="color: white !important"
        v-on:click="$refs.textModal.closeModal"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <Form ref="requestDemo" @submit="customSearchSave()">
      <div style="padding: 15px 30px; overflow-y: scroll; height: 480px">
        <div class="row">
          <p>
            ExecLibrary captures 50+ Executive, Company & News Data Points. You
            can request thousands of executive biographies, company
            descriptions, executive moves articles by company, job functions and
            industries and many more..
          </p>
          <p>
            Kindly fill the form with the required details and we will be in
            touch ASAP to discuss and offer a quote:
          </p>
        </div>
        <div class="row">
          <div class="col-lg-6 form-group">
            <label>First Name</label>
            <Field
              type="text"
              name="first_name"
              class="form-control"
              placeholder="First Name"
              v-model="requestDemoObj.first_name"
              rules="required:first name"
              :validateOnInput="true"
            />
            <ErrorMessage name="first_name" class="text-danger" />
          </div>
          <div class="col-lg-6 form-group">
            <label>Last Name</label>
            <Field
              type="text"
              name="last_name"
              class="form-control"
              placeholder="Last Name"
              v-model="requestDemoObj.last_name"
              rules="required:last name"
              :validateOnInput="true"
            />
            <ErrorMessage name="last_name" class="text-danger" />
          </div>

          <div class="col-lg-6 form-group">
            <label>Email</label>
            <Field
              type="text"
              name="email"
              class="form-control"
              placeholder="Email"
              v-model="requestDemoObj.email"
              rules="required:email|email"
              :validateOnInput="true"
            />
            <ErrorMessage name="email" class="text-danger" />
          </div>
          <!-- <div class="col-lg-6 form-group mb-3">
          <label>Contact Number</label>
          <Field
            type="text"
            name="contact"
            class="form-control"
            placeholder="Contact"
            v-on:keypress="$helperService.allowOnlyNumericValue($event)"
            v-model="requestDemoObj.contact"
            rules="required:contact"
            :validateOnInput="true"
          />
          <ErrorMessage name="contact" class="text-danger" />
        </div> -->

          <div class="col-lg-6 form-group">
            <label>Company Name</label>
            <Field
              type="text"
              name="company_name"
              class="form-control"
              placeholder="Company Name"
              v-model="requestDemoObj.company_name"
              rules="required:company name"
              :validateOnInput="true"
            />
            <ErrorMessage name="company_name" class="text-danger" />
          </div>
          <div class="col-lg-12 form-group">
            <label>Subject</label>
            <Field
              type="text"
              name="subject"
              class="form-control"
              placeholder="Subject"
              v-model="requestDemoObj.subject"
              rules="required: subject"
              :validateOnInput="true"
            />
            <ErrorMessage name="subject" class="text-danger" />
          </div>
          <div class="col-lg-12 form-group">
            <label>Note</label>
            <textarea
              type="textarea"
              name="Note"
              class="form-control"
              placeholder="Note"
              v-model="requestDemoObj.note"
            />
            <!-- <ErrorMessage name="last_name" class="text-danger" /> -->
          </div>
          <div class="col-lg-12 form-group">
            <label> File Upload</label>
            <div class="text-center mb-4">
              <div class="input-group mb-3">
                <div class="custom-file">
                  <input
                    type="file"
                    class="custom-file-input"
                    id="inputGroupFile04"
                    v-on:change="onChange($event)"
                  />
                  <label
                    class="custom-file-label"
                    style="text-align: left"
                    for="inputGroupFile04"
                    >{{ docFile ? docFile.name : "Choose File" }}</label
                  >
                  <!-- <input type="file" class="custom-file-input" id="inputGroupFile04" v-on:change="onChange($event)" />
                <label class="custom-file-label" for="inputGroupFile04">Choose file</label> -->
                </div>
              </div>
              <!-- <p>
              <a href="" v-on:click="downloadTemplate">Download Template </a>
              file and fill with the items information.
            </p> -->
              <!-- <div id="msg"></div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 modal-footer pb-0" style="justify-content: center">
        <button
          class="form-control btn-primary rounded submit px-3 mt-1"
          id="custom-button"
          style="width: 30%; border-radius: 5px"
        >
          Submit
        </button>
        <!-- <button
          type="button"
          class="fil-btn"
          id="cancel-button"
          v-on:click="$refs.textModal.closeModal"
          style="width: 20%; border-radius: 5px"
        >
          Cancel
        </button> -->
      </div>
    </Form>
  </TextModal>
  <TextModal ref="otpModalNew">
    <div
      class="modal-header pt-0 text-center"
      style="justify-content: center; display: block ruby"
    >
      <h5 class="modal-title">Verfiy You Email</h5>
      <button class="close" v-on:click="$refs.otpModalNew.closeModal">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="col-12 row">
      <div class="col-md-12 col-lg-12">
        <div class="login-wrap p-4 pt-4">
          <div class="posAbs mt-3">
            <!-- <router-link to="/forgot-password">
              <a style="color: #000"><i class="fa fa-arrow-left"></i></a>
            </router-link> -->
          </div>
          <div class="form-body">
            <div class="mt-3 col-md-12 col-sm-12 text-center">
              <b>Please Enter the OTP sent On</b>
              <div>
                <div class="pt-1 text-center" style="color: #de4a38">
                  <a>{{ this.requestDemoObj.email }}</a>
                </div>
              </div>
              <div class="text-center my-4">
                <img src="/img/ENTER_OTP.png" alt="" width="100" height="100" />
              </div>
              <Form @submit="verifyOtp()">
                <div class="text-center mb-4 digit-group">
                  <Field
                    id="codeBoxOld1"
                    style="color: black"
                    type="text"
                    name="otp1"
                    class="otp-control"
                    maxlength="1"
                    rules="required"
                    v-on:keyup="onKeyUpEvent(1, $event)"
                    v-on:focus="onFocusEvent(1)"
                  />
                  <Field
                    id="codeBoxOld2"
                    type="text"
                    style="color: black"
                    name="otp2"
                    maxlength="1"
                    rules="required"
                    v-on:keyup="onKeyUpEvent(2, $event)"
                    v-on:focus="onFocusEvent(2)"
                  />
                  <Field
                    id="codeBoxOld3"
                    style="color: black"
                    type="text"
                    name="otp3"
                    maxlength="1"
                    rules="required"
                    v-on:keyup="onKeyUpEvent(3, $event)"
                    v-on:focus="onFocusEvent(3)"
                  />
                  <Field
                    id="codeBoxOld4"
                    type="text"
                    style="color: black"
                    name="otp4"
                    class="otp-control"
                    maxlength="1"
                    rules="required:otp"
                    v-on:keyup="onKeyUpEvent(4, $event)"
                    v-on:focus="onFocusEvent(4)"
                  />
                </div>
                <div class="text-center">
                  <ErrorMessage name="otp4" class="text-danger" />
                </div>
                <div class="mt-2 col-md-6 col-sm-6 mx-auto">
                  <button class="submit-btn" id="verify-otp-button">
                    Submit
                  </button>
                </div>
                <div class="col-mg-12 col-sm-12 text-center">
                  <p class="mb-0 mt-3">
                    <a
                      style="cursor: pointer; color: #de4a38"
                      id="resend-button"
                      v-on:click="resend()"
                      >Resend OTP</a
                    >
                  </p>
                  <hr />
                  <!-- <p class="mb-0 mt-3">
                    <a href="#termsconditionsModal" data-toggle="modal"
                      ><input type="checkbox" class="" id="" /> Accept Terms and
                      Conditions
                    </a>
                  </p> -->
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </TextModal>
</template>

<script>
import Header from "@/components/Header";
import TextModal from "@/components/TextModal";
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "About-Us",
  components: {
    Header,
    Form,
    Field,
    TextModal,
    ErrorMessage,
  },
  data() {
    return {
      docFile: undefined,
      detail: {},
      requestDemoObj: {},
    };
  },
  mounted() {},
  methods: {
    verifyOtp() {
      var obj = {};
      obj.email = localStorage.getItem("email");
      obj.id = localStorage.getItem("id");
      obj.otp =
        document.getElementById("codeBoxOld1").value +
        "" +
        document.getElementById("codeBoxOld2").value +
        "" +
        document.getElementById("codeBoxOld3").value +
        "" +
        document.getElementById("codeBoxOld4").value;
      this.$api
        .putAPI({
          _action: "/verify-custom-search-email",
          _body: obj,
          _buttonId: "verify-otp-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          this.$refs.otpModalNew.closeModal();
        })
        .catch(() => {});
    },
    resend() {
      var obj = {};
      obj.email = localStorage.getItem("email");
      obj.id = localStorage.getItem("id");
      this.$api
        .postAPI({
          _action: "/resend-custom-search-otp",
          _body: obj,
          _buttonId: "resend-button",
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
        })
        .catch(() => {});
    },
    getCodeBoxElement(index) {
      return document.getElementById("codeBoxOld" + index);
    },
    onKeyUpEvent(index, event) {
      const eventCode = event.which || event.keyCode;
      if (this.getCodeBoxElement(index).value.length === 1) {
        if (index !== 4) {
          this.getCodeBoxElement(index + 1).focus();
        } else {
          this.getCodeBoxElement(index).blur();
        }
      }
      if (eventCode === 8 && index !== 1) {
        this.getCodeBoxElement(index - 1).focus();
      }
    },
    onFocusEvent(index) {
      for (var item = 1; item < index; item++) {
        const currentElement = this.getCodeBoxElement(item);
        if (!currentElement.value) {
          currentElement.focus();
          break;
        }
      }
    },
    onChange(e) {
      let files = e.target.files;
      // if (
      //   files[0].type.toLowerCase() != "application/pdf" &&
      //   files[0].type.toLowerCase() != "application/doc"
      // ) {
      //   alert("Invalid file formate, please use pdf file.");
      //   return;
      // }
      // const size = (files[0].size / 1024 / 1024).toFixed(2);
      // if (size > 10) {
      //   alert("File must be less then 10 MB");
      //   return;
      // }
      var tmppath = URL.createObjectURL(files[0]);
      this.docFile = files[0];
      document.getElementById("inputGroupFile04").src = tmppath;
      console.log(this.docFile);
    },
    ShowCustomSearchModel() {
      this.$refs.requestDemo.resetForm();
      this.requestDemoObj.note = "";
      this.$refs.textModal.showModal();
    },
    customSearchSave() {
      if (!this.docFile) {
        this.requestDemoObj.send_email = "YES";
      }
      this.$api
        .postAPI({
          _action: "/add-custom-search-data",
          _body: this.requestDemoObj,
          _buttonId: "custom-button",
        })
        .then((res) => {
          localStorage.setItem("email", this.requestDemoObj.email);
          localStorage.setItem("id", res.id);
          if (this.docFile && res.id) {
            this.uploadDocument(res.id);
          } else {
            this.$refs.textModal.closeModal();
            this.$refs.otpModalNew.showModal();
          }
        })
        .catch(() => {});
    },
    uploadDocument(id) {
      if (this.docFile && id) {
        this.$api
          .uploadImageAPI({
            _action: "/upload-custom-search-file",
            _key: "file",
            _file: this.docFile,
            _body: {
              id: id,
            },
          })
          .then(() => {
            // this.$notify({
            //   type: "success",
            //   text: res.message,
            // });
            this.$refs.textModal.closeModal();
            this.$refs.otpModalNew.showModal();
          });
      } else {
        this.$refs.textModal.closeModal();
      }
    },
    save() {
      this.$api
        .postAPI({
          _action: "/contact-us",
          _body: this.detail,
          _buttonId: "save-button",
        })
        .then(() => {
          this.$router.go(-1);
        })
        .catch(() => {});
    },
  },
};
</script>
