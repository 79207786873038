<template>
  <div>
    <section class="ftco-section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-6 text-center mb-5">
            <a href="#"><img src="/img/logo.png" alt="" /></a>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-7 col-lg-5">
            <div class="login-wrap p-4 pt-4">
              <!--<div class="icon d-flex align-items-center justify-content-center">
                <img src="assets/img/avatar-4.jpg" alt="" style="border-radius: 100px; width: 100px; height: 100px;" />
              </div> -->
              <h3 class="title-h3 text-center pt-0" style="font-size: 28px">
                Create an Account
              </h3>
              <Form @submit="save()" class="login-form">
                <div class="form-group mb-3">
                  <Field
                    type="text"
                    name="first_name"
                    class="form-control"
                    placeholder="First Name"
                    v-model="detail.first_name"
                    rules="required:first name"
                    :validateOnInput="true"
                  />
                  <ErrorMessage name="first_name" class="text-danger" />
                </div>
                <div class="form-group mb-3">
                  <Field
                    type="text"
                    name="last_name"
                    class="form-control"
                    placeholder="Last Name"
                    v-model="detail.last_name"
                    rules="required:last name"
                    :validateOnInput="true"
                  />
                  <ErrorMessage name="last_name" class="text-danger" />
                </div>
                <div class="form-group mb-3">
                  <Field
                    type="text"
                    name="email"
                    class="form-control"
                    placeholder="Email"
                    v-model="detail.email"
                    rules="required:email|email"
                    :validateOnInput="true"
                  />
                  <ErrorMessage name="email" class="text-danger" />
                </div>
                <div class="form-group mb-3">
                  <Field
                    type="text"
                    name="contact"
                    class="form-control"
                    placeholder="Contact"
                    v-model="detail.contact"
                    rules="required:contact"
                    :validateOnInput="true"
                  />
                  <ErrorMessage name="contact" class="text-danger" />
                </div>
                <div class="form-group mb-3">
                  <Field
                    type="password"
                    name="name_password"
                    class="form-control"
                    placeholder="New Password"
                    v-model="detail.password"
                    rules="required:password|minLength:6"
                    :validateOnInput="true"
                  />
                  <ErrorMessage name="name_password" class="text-danger" />
                </div>
                <div class="form-group mb-3">
                  <Field
                    type="password"
                    placeholder="Confirm Password"
                    name="name_ccpassword"
                    class="form-control"
                    rules="required:confirm password|confirmed:@name_password"
                    :validateOnInput="true"
                  />
                  <ErrorMessage name="name_ccpassword" class="text-danger" />
                </div>
                <div class="form-group mb-3">
                  <button
                    type="submit"
                    id="save-button"
                    class="form-control btn-primary rounded submit px-3"
                  >
                    Sign Up &nbsp;<i class="fa fa-sign-in"></i>
                  </button>
                </div>
                <div class="row">
                  <div class="col-lg-12 col-sm-12 col-12">
                    <label class="checkbox-wrap checkbox-primary">
                      <input type="checkbox" checked />
                      <span class="checkmark"></span>
                      I agreed to Terms & Conditions
                      <a
                        v-on:click="$router.push('/terms/conditions')"
                         style="color: #de4a38; cursor: pointer"
                        >Terms & Conditions</a
                      >
                    </label>
                  </div>
                  <div class="col-lg-12 col-sm-12 text-center">
                    Have already an account?
                    <a
                      v-on:click="$router.push('/login')"
                      style="color: #de4a38; cursor: pointer"
                      >Sign In</a
                    >
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  name: "Signup",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      detail: {},
    };
  },
  mounted() {},
  methods: {
    save() {
      this.$api
        .postAPI({
          _action: "/signup",
          _body: this.detail,
          _buttonId: "save-button",
        })
        .then((res) => {
          if (res && res.access_token) {
            localStorage.setItem("token", res.access_token);
            this.getLoginUserDetail();
          }
        })
        .catch(() => {});
    },
    getLoginUserDetail() {
      this.$api
        .getAPI({
          _action: "/me",
        })
        .then((res) => {
          if (res) {
            this.$router.push("/executive");
          }
        })
        .catch(() => {
          localStorage.removeItem("token");
        });
    },
  },
};
</script>